import Experiment from '@models/Experiment';
import Button from '@components/Button';
import { DownloadIcon } from '@heroicons/react/outline';
import useDownloadData from '@hooks/useDownloadData';
import Endpoints from '@services/Endpoints';
import { generateGeneSetFileName } from '@util/ExperimentUtil';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import useAnalysisGeneSets from '@hooks/useAnalysisGeneSets';

type Props = { experiment: Experiment; analysis?: ExperimentAnalysis | null };
const DownloadGeneSetsButton = ({ experiment, analysis }: Props) => {
    const experimentId = experiment.uuid;
    const analysisId = analysis?.uuid;
    const { startDownload } = useDownloadData({
        endpoint: (filename) =>
            Endpoints.lab.experiment.analysis.summaryDownload(
                {
                    experimentId,
                    analysisId: analysisId ?? '', // quick way to ensure the correct type here
                },
                { filename },
            ),
        baseFilename: generateGeneSetFileName({ experiment }),
    });

    const { tabularData, loading } = useAnalysisGeneSets({
        analysisId,
        experimentId,
    });

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon width={24} />}
            onClick={() => startDownload({ extension: '.csv' })}
            disabled={tabularData?.items?.length === 0 || loading}
            loading={loading}
        >
            Download
        </Button>
    );
};

export default DownloadGeneSetsButton;
