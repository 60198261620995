import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import React, { ReactNode, useMemo } from 'react';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { Item } from '@components/MoreMenuIconButton';
import { LoadingIndicator } from '@components/LoadingButton';
import { DownloadIcon } from '@heroicons/react/solid';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import DownloadDataMoreMenuItem from '@components/DownloadDataMoreMenuItem';
import Endpoints from '@services/Endpoints';
import { generateReportFileName } from '@util/ExperimentUtil';
import {
    ArrowsExpandIcon,
    CodeIcon,
    DocumentTextIcon,
    ExternalLinkIcon,
    MinusCircleIcon,
    BeakerIcon,
} from '@heroicons/react/outline';
import useClonePlot from '@hooks/useClonePlot';
import useBreakpoints from '@hooks/useBreakpoints';
import { appendQueryParams, QueryParam } from '@services/QueryParams';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { EditIcon } from '@components/icons/custom/EditIcon';
import { isServerImageDisplayType } from '@models/PlotDisplayType';
import { AnyExperimentData, PlotPipelinePlotData } from '@models/ExperimentData';

type Props = {
    plot: Plot;
    experiment: Experiment;
    setMethodsOpen: (open: boolean) => void;
    setResultsOpen: (open: boolean) => void;
    ready: boolean;
    hideEditButton?: boolean;
    disableDownloadLinks?: boolean;
    showExperimentLink?: boolean;
    plotData?: AnyExperimentData | null;
    setFullScreen?: (full: boolean) => void;
    publicKey?: string | null;
};
const useCanvasMenuItems = ({
    plot,
    plotData,
    experiment,
    setMethodsOpen,
    setResultsOpen,
    ready,
    hideEditButton,
    showExperimentLink,
    disableDownloadLinks,
    setFullScreen,
    publicKey,
}: Props) => {
    const plotId = plot?.uuid;
    const { handleChangeSelectedPlot, setCurrentPlotPanel, archivePlot, getPlotOverrides, setPlotToEmbed } =
        useExperimentDetailViewContext();
    const { clonePlot, cloning, cloneDisabled } = useClonePlot({ publicKey });
    const overrides = getPlotOverrides?.(plotId) ?? { hasOverrides: false };
    const { isSmAndDown } = useBreakpoints();
    const permissions = useExperimentPermissions(experiment, !!publicKey);

    const menuItems = useMemo<(Item | ReactNode)[]>(() => {
        const menuItems: (Item | ReactNode)[] = [];
        if (!hideEditButton) {
            menuItems.push({
                label: plot?.analysis?.uuid ? 'Edit' : 'Configure',
                onClick: () => {
                    handleChangeSelectedPlot?.(plot);
                    setCurrentPlotPanel?.(plot?.analysis?.uuid ? 'plot' : 'analysis');
                },
                icon: <EditIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (!hideEditButton && permissions.canEdit && !cloneDisabled) {
            menuItems.push({
                label: 'Clone',
                onClick: async () => {
                    await clonePlot();
                },
                icon: cloning ? <LoadingIndicator size={16} /> : <CopyIcon width={16} height={16} />,
                closeOnClick: false,
            });
        }

        // Plot download links
        if (ready && !disableDownloadLinks && !isSmAndDown) {
            if (plot?.display?.display_type !== 'image' && !isServerImageDisplayType(plot?.display?.display_type)) {
                menuItems.push({
                    label: 'Export',
                    href: appendQueryParams(
                        `/experiments/${experiment.pluto_id}/plots/${plotId}/export`,
                        overrides?.hasOverrides
                            ? {
                                  [QueryParam.ANALYSIS_ID]: overrides.analysis_id,
                                  [QueryParam.DISPLAY_ID]: overrides.display_id,
                              }
                            : undefined,
                    ),
                    icon: <DownloadIcon className="h-4 w-4" />,
                    newTab: true,
                    closeOnClick: true,
                });
            } else if ((plot?.analysis as ImageAnalysis)?.image_url) {
                const analysisId = overrides.analysis_id ?? plot?.analysis?.uuid;
                menuItems.push(
                    <DownloadDataMoreMenuItem
                        key={'download_image'}
                        endpoint={
                            !analysisId
                                ? undefined
                                : () => {
                                      return Endpoints.lab.experiment.analysis.imageAnalysisSignedUrl(
                                          {
                                              experimentId: experiment.uuid,
                                              analysisId,
                                          },
                                          { download: true },
                                      );
                                  }
                        }
                        url={analysisId ? undefined : (plot?.analysis as ImageAnalysis).image_url}
                        baseFilename={generateReportFileName({ experiment, suffix: 'plot' })}
                        extension=""
                        label="Download"
                    />,
                );
            } else if (isServerImageDisplayType(plot.display.display_type)) {
                // const analysisId = overrides.analysis_id ?? plot?.analysis?.uuid;
                const pdfs = (plotData as PlotPipelinePlotData | null)?.plots?.images?.pdf;
                const pngs = (plotData as PlotPipelinePlotData | null)?.plots?.images?.png;
                const pdfUrl = plot.display?.is_full_width ? pdfs?.wide : pdfs?.square ?? pdfs?.auto;
                const pngUrl = plot.display?.is_full_width ? pngs?.wide : pngs?.square ?? pngs?.auto;

                if (setFullScreen) {
                    menuItems.push({
                        label: 'Full-screen',
                        onClick: async () => {
                            await setFullScreen(true);
                        },
                        icon: <ArrowsExpandIcon width={16} />,
                        closeOnClick: false,
                    });
                }

                menuItems.push(
                    <DownloadDataMoreMenuItem
                        key={'download_pdf'}
                        url={pdfUrl}
                        baseFilename={generateReportFileName({ experiment, suffix: 'plot' })}
                        extension=".pdf"
                        label="Download .pdf"
                        newTab
                    />,
                    <DownloadDataMoreMenuItem
                        key={'download_png'}
                        url={pngUrl}
                        baseFilename={generateReportFileName({ experiment, suffix: 'plot' })}
                        extension=".png"
                        label="Download .png"
                        newTab
                    />,
                );
            }
        }

        if (showExperimentLink) {
            menuItems.push({
                label: 'Open original experiment',
                href: `/experiments/${experiment.pluto_id}?plot_id=${plot.uuid}`,
                closeOnClick: false,
                newTab: true,
                icon: <ExternalLinkIcon className="h-4 w-4" />,
            });
        }

        if (!hideEditButton) {
            menuItems.push({
                label: 'Embed',
                onClick: () => {
                    setPlotToEmbed?.(plot);
                },
                icon: <CodeIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (plot?.analysis) {
            menuItems.push({
                label: 'View methods',
                onClick: () => setMethodsOpen(true),
                icon: <DocumentTextIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (plot?.analysis_type !== 'image' && plot?.analysis_type !== 'text') {
            menuItems.push({
                label: 'View results',
                onClick: () => setResultsOpen(true),
                icon: <BeakerIcon className="h-4 w-4" />,
                closeOnClick: true,
            });
        }

        if (!hideEditButton && permissions.canEdit) {
            menuItems.push({
                label: 'Remove',
                onClick: () => {
                    archivePlot?.(plotId);
                },
                icon: <MinusCircleIcon className="h-4 w-4" />,
                closeOnClick: true,
                color: 'danger',
            });
        }

        return menuItems;
    }, [ready, plot, permissions, overrides, hideEditButton]);

    return { menuItems };
};

export default useCanvasMenuItems;
