import DefaultLayout, { DefaultLayoutProps } from '@layouts/DefaultLayout';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import DashboardNavigation from '@components/layout/DashboardNavigation';
import ExperimentNavigation from '@components/ExperimentNavigation';
import LoginForm from '@components/LoginForm';
import Card from '@components/Card';
import ProjectArchiveConfirmModal from '@components/projects/ProjectArchiveConfirmModal';
import useAuth from '@hooks/useAuth';
import LoadingMessage from '@components/LoadingMessage';
import ProjectSharingDialog from '@components/sharing/ProjectSharingDialog';
import { LabSpaceContextProvider } from '@contexts/LabSpaceContext';
import LabspaceExperimentSharingDialog from '@components/sharing/LabspaceExperimentSharingDialog';
import TrialBanner from '@components/trial/TrialBanner';
import { useTrialContext } from '@contexts/TrialContext';
import { useRouter } from 'next/router';
import { QueryParam } from '@services/QueryParams';
import useSWR from 'swr';
import Experiment from '@models/Experiment';
import Endpoints from '@services/Endpoints';
import { Project } from '@models/Project';
import { PaginationResponse } from '@services/EndpointUtil';
import { ExperimentDetailViewContextProvider } from '@contexts/ExperimentDetailViewContext';
import { ExperimentWorkflowContextProvider } from '@contexts/ExperimentWorkflowContext';
import { ExperimentAnnotationContextProvider } from '@contexts/ExperimentAnnotationContext';
import { ExperimentCanvasContextProvider } from '../contexts/ExperimentCanvasContext';
import { ReactFlowProvider } from 'reactflow';
import Banner from '@components/banner/Banner';

const DefaultAuthComponent = () => {
    return (
        <DefaultLayout maxWidth="lg">
            <Card>
                <h3 className="mb-4 text-center text-3xl font-semibold tracking-tight">
                    {"There's so much more to explore on Pluto!"}
                </h3>
                <p className="mb-8 text-center text-default">Log in to your account to view this page</p>
                <LoginForm />
            </Card>
        </DefaultLayout>
    );
};

export type ExperimentLayoutProps = PropsWithChildren<
    DefaultLayoutProps & { authRequired?: boolean; authComponent?: ReactNode; fixedViewport?: boolean }
>;
const ExperimentLayout = ({
    children,
    container = false,
    noMargin,
    authRequired = false,
    authComponent = <DefaultAuthComponent />,
    ...props
}: ExperimentLayoutProps) => {
    const router = useRouter();
    const { plutoId } = router.query as {
        plutoId: string | undefined;
        [QueryParam.STEP]: string | undefined;
    };
    const { isLoggedIn, authReady, user } = useAuth();
    const { trialExpired } = useTrialContext();
    const hasAccess = isLoggedIn || !authRequired;
    let $body: ReactNode = null;
    if (hasAccess) {
        $body = children;
    } else if (!authReady) {
        $body = <LoadingMessage />;
    } else if (authReady && !isLoggedIn) {
        $body = authComponent;
    }

    const { data: experiment } = useSWR<Experiment>(
        () => (plutoId && isLoggedIn ? Endpoints.lab.experiment.base(plutoId) : null),
        { revalidateOnFocus: true, refreshInterval: 10_000 },
    );
    const projectId = experiment?.project?.pluto_id ?? '';

    const { data: project } = useSWR<Project>(() => (projectId && user ? Endpoints.lab.project.base(projectId) : null));
    const { data: projectExperimentsResponse } = useSWR<PaginationResponse<Experiment>>(() =>
        projectId && project?.share_level === 'public'
            ? Endpoints.public.project.experiments({ projectId })
            : projectId && Endpoints.lab.project.experiments({ projectId }),
    );
    const projectExperiments =
        projectExperimentsResponse?.items.filter(
            (projExp) => projExp.pluto_id !== experiment?.pluto_id && !projExp.is_archived,
        ) ?? [];

    useEffect(() => {
        if (trialExpired && authReady && isLoggedIn) {
            router.push('https://pluto.bio/get-info');
        }
    }, [authReady, isLoggedIn, trialExpired]);

    if (trialExpired) {
        return null;
    }

    return (
        <DefaultLayout className="max-w-screen min-h-screen" container={container} {...props} noMargin={noMargin}>
            <ReactFlowProvider>
                <ExperimentCanvasContextProvider experiment={experiment}>
                    <ExperimentWorkflowContextProvider experiment={experiment}>
                        <ExperimentAnnotationContextProvider experiment={experiment}>
                            <ExperimentDetailViewContextProvider experiment={experiment}>
                                <LabSpaceContextProvider>
                                    <Banner />
                                    <TrialBanner innerClassName="" />
                                    <div className="relateive">
                                        <div className="flex">
                                            <div className="flex-none md:w-[60px]">
                                                <DashboardNavigation />
                                            </div>
                                            <div className="w-screen flex-1 sm:w-full sm:min-w-[calc(100vw-60px)]">
                                                <div>
                                                    <ExperimentNavigation
                                                        experiment={experiment as Experiment}
                                                        project={project as Project}
                                                        projectExperiments={projectExperiments}
                                                    />
                                                </div>
                                                <div>{$body}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <ProjectArchiveConfirmModal />
                                    <LabspaceExperimentSharingDialog />
                                    <ProjectSharingDialog />
                                </LabSpaceContextProvider>
                            </ExperimentDetailViewContextProvider>
                        </ExperimentAnnotationContextProvider>
                    </ExperimentWorkflowContextProvider>
                </ExperimentCanvasContextProvider>
            </ReactFlowProvider>
        </DefaultLayout>
    );
};

export default ExperimentLayout;
