import React, { useState } from 'react';
import LoadingMessage from '@components/LoadingMessage';
import cn from 'classnames';
import Experiment from '@models/Experiment';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { ErrorOutline } from '@mui/icons-material';
import { ApiError } from '@services/ApiError';
import MoreMenuIconButton, { MoreMenuItem } from '@components/MoreMenuIconButton';
import DownloadDataMoreMenuItem from '@components/DownloadDataMoreMenuItem';
import { generateReportFileName } from '@util/ExperimentUtil';
import { Dialog, DialogContent } from '@mui/material';
import PipelineRunsListView from '@components/experiments/PipelineRunsListView';
import { DocumentTextIcon, LightningBoltIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import useFileDownloader from '@hooks/useFileDownloader';
import Logger from '@util/Logger';
import BenchlingExportDialog from '@components/experiments/benchling/BenchlingExportDialog';
import BenchlingIcon from '@components/icons/custom/BenchlingIcon';
import PipelineMethodsView, {
    isPipelineMethodsSupported,
} from '@components/experiments/pipelineMethods/PipelineMethodsView';
import { FormControl, MenuItem, Select } from '@mui/material';
import AssayFileList from '@components/experiments/wizard/AssayFileList';
import useExperimentFiles from '@hooks/useExperimentFiles';
import useExperimentPermissions from '@/src/hooks/useExperimentPermissions';

const logger = Logger.make('PlutoQCReportView');

type ReportResponse = { url: string };
type Props = { experiment: Experiment };
type Tab = 'bam' | 'qc';

const PlutoQCReportView = ({ experiment }: Props) => {
    const [iframeLoading, setIframeLoading] = useState(false);
    const [pipelineRunsOpen, setPipelineRunsOpen] = useState(false);
    const [methodsOpen, setMethodsOpen] = useState(false);
    const [benchlingExportOpen, setBenchlingExportOpen] = useState(false);
    const { createFileFromUrl } = useFileDownloader();
    const { data, error } = useSWR<ReportResponse>(
        Endpoints.lab.experiment.qcReportUrl({ experimentId: experiment.uuid }),
        { revalidateOnMount: true, revalidateOnFocus: false },
    );
    const { features } = useExperimentPermissions(experiment);

    const loading = !data && !error;
    const iframeUrl = data?.url;
    const [tab, setTab] = useState<Tab>(experiment.qc_report_url ? 'qc' : 'bam');
    const { data: bamFilesData } = useExperimentFiles({ experiment, data_type: 'bam' });
    const bamFiles = bamFilesData?.bam?.items ?? [];

    const moreMenuItems: MoreMenuItem[] = [
        {
            label: 'View pipeline runs',
            onClick: () => {
                setPipelineRunsOpen(true);
                return;
            },
            closeOnClick: true,
            icon: <LightningBoltIcon className="h-4 w-4" />,
        },
    ];

    if (isPipelineMethodsSupported(experiment)) {
        moreMenuItems.push({
            label: 'View methods',
            onClick: () => {
                setMethodsOpen(true);
                return;
            },
            closeOnClick: true,
            icon: <DocumentTextIcon className="h-4 w-4" />,
        });
    }

    if (tab === 'qc') {
        moreMenuItems.push(
            <DownloadDataMoreMenuItem
                key={'download_html'}
                endpoint={(filename) =>
                    Endpoints.lab.experiment.qcReportDownloadUrl(
                        { experimentId: experiment.uuid },
                        { content_type: 'attachment', download: filename },
                    )
                }
                baseFilename={generateReportFileName({ experiment, suffix: 'qc' })}
                extension=".html"
            />,
        );
        if (features?.benchling_enabled) {
            moreMenuItems.push({
                label: 'Export to Benchling',
                onClick: () => {
                    setBenchlingExportOpen(true);
                },
                closeOnClick: true,
                icon: <BenchlingIcon width={18} height={18} />,
            });
        }
    }

    const makeFile = async (): Promise<File[] | null> => {
        if (!iframeUrl) {
            return null;
        }
        const file = await createFileFromUrl({
            url: iframeUrl,
            filename: `${experiment.pluto_id}_qc_report.html`,
            metadata: { type: 'text/html' },
        });
        logger.info('Created file', file);
        return [file];
    };

    return (
        <div className="flex h-[75vh] flex-col">
            <div className="mb-6">
                <p className="field-label mb-2">Pipeline output</p>
                <FormControl variant="outlined">
                    <Select
                        margin="dense"
                        value={tab}
                        onChange={(e) => setTab(e.target.value as Tab)}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}
                        renderValue={(selected) => {
                            return (
                                <div className="flex flex-row items-center justify-start">
                                    {selected === 'qc' ? 'QC report' : 'BAM files'}
                                </div>
                            );
                        }}
                    >
                        {iframeUrl && (
                            <MenuItem value={'qc'}>
                                <div className="flex flex-row items-center justify-start">QC report</div>
                            </MenuItem>
                        )}
                        {bamFiles.length > 0 && (
                            <MenuItem value={'bam'}>
                                <div className="flex flex-row items-center justify-start">BAM files</div>
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>

            {tab === 'qc' ? (
                <>
                    {(iframeLoading || loading) && <LoadingMessage message="Loading Pluto Report..." size={20} />}
                    {error && (
                        <div className="flex flex-col justify-center px-8 py-32 text-center">
                            <div className="mx-auto mb-4 rounded-full bg-error p-4 text-error">
                                <ErrorOutline height={24} width={24} />
                            </div>
                            <h2 className="text-xl font-semibold tracking-tight">Unable to load QC report</h2>
                            <p className="text-base">{ApiError.getMessage(error)}</p>
                        </div>
                    )}
                    {iframeUrl ? (
                        <>
                            <div className="mb-6 flex items-center justify-between">
                                <div className="flex-shrink-0">
                                    <p className="mb-3 text-lg font-semibold text-dark">QC report</p>
                                </div>
                                <div className="ml-auto flex-shrink-0">
                                    <MoreMenuIconButton outlined items={moreMenuItems} />
                                </div>
                            </div>
                            <iframe
                                src={iframeUrl}
                                width="100%"
                                height="100%"
                                onLoad={() => setIframeLoading(false)}
                                className={cn('min-h-[50vh] rounded-xl', { hidden: iframeLoading })}
                            />
                            <div className="flex justify-end">
                                <a
                                    className="inline-flex items-center pt-5 text-right"
                                    href={iframeUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <ExternalLinkIcon width={18} className="mr-1" />
                                    Open in new tab
                                </a>
                            </div>
                        </>
                    ) : (
                        !iframeLoading &&
                        !loading && <div className="text-center text-lg text-error">No QC report url was provided</div>
                    )}
                </>
            ) : (
                <div>
                    <AssayFileList
                        experiment={experiment}
                        files={bamFiles}
                        title="BAM files"
                        moreMenuItems={moreMenuItems}
                    />
                </div>
            )}

            <Dialog open={pipelineRunsOpen} onClose={() => setPipelineRunsOpen(false)} fullWidth maxWidth="sm">
                <DialogCloseButton onClose={() => setPipelineRunsOpen(false)} />
                <PlutoDialogTitle title="Pipeline runs" />
                <DialogContent style={{ paddingTop: 0 }}>
                    <div className="pt-4">
                        <PipelineRunsListView experiment={experiment} title={null} />
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={methodsOpen} onClose={() => setMethodsOpen(false)} maxWidth="sm" fullWidth>
                <DialogCloseButton onClose={() => setMethodsOpen(false)} />
                <PlutoDialogTitle title="Methods" />
                <DialogContent>
                    <PipelineMethodsView experiment={experiment} />
                </DialogContent>
            </Dialog>
            <BenchlingExportDialog
                makeFile={makeFile}
                open={benchlingExportOpen}
                setOpen={setBenchlingExportOpen}
                experiment={experiment}
            />
        </div>
    );
};
export default PlutoQCReportView;
