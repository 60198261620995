import { useState, useEffect } from 'react';
import useApi from '@hooks/useApi';
import useAuth from '@hooks/useAuth';
import { Evidence, InternalEvidenceType, NewEvidencePayload } from '@models/Evidence';
import { BiomarkerSetType } from '@models/Biomarker';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { capitalize } from '@mui/material';

type Props = { biomarkerId?: string; targetType?: BiomarkerSetType };

const useEvidence = ({ biomarkerId, targetType = 'biomarker' }: Props) => {
    const { authReady } = useAuth();
    const [evidence, setEvidence] = useState<Evidence[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState<number>(0);
    const api = useApi();
    const logger = Logger.make('EvidenceAPI');

    useEffect(() => {
        if (!authReady || !biomarkerId) return;

        fetchEvidence();
    }, [authReady, biomarkerId]);

    const fetchEvidence = async () => {
        setError('');
        setLoading(true);
        try {
            const newEvidence = await api.get<Evidence[]>(Endpoints.lab.evidence(), {
                object_type: targetType,
                object_uuid: biomarkerId,
            });

            const processedEvidence = newEvidence.map((item) => ({
                ...item,
                evidenceName: item.content_object?.name ?? item.content_object?.title ?? 'Unnamed Evidence',
                type: capitalize(item.content_object?.object_type ?? 'Evidence'), // Default to literature since repsonse data is missing that object_type
                status: item.status || 'None', // Default value if status is missing
            }));

            const sortedEvidence = processedEvidence.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
            setEvidence(sortedEvidence);
            setCount(newEvidence.length);
        } catch (error) {
            logger.error(error);
            setError('Failed to fetch evidence');
        } finally {
            setLoading(false);
        }
    };

    const postNewEvidence = async ({
        type,
        id,
        referenceObjectID,
        rationale,
        status,
        score,
        datasetID,
        setType,
    }: {
        type?: InternalEvidenceType;
        id?: string;
        referenceObjectID?: string;
        rationale?: string;
        status?: string;
        score?: number | null;
        datasetID?: string;
        setType?: BiomarkerSetType;
    }) => {
        const object_uuid: string | null = referenceObjectID ?? biomarkerId ?? null;
        if (!object_uuid) return;
        setError('');
        try {
            const payload: NewEvidencePayload = {
                object_type: setType ?? targetType,
                object_uuid,
                evidence_object_type: type,
                evidence_object_uuid: id,
                rationale,
                status,
                score,
            };
            if (!!datasetID) payload.literature_dataset_uuid = datasetID;
            await api.post<Evidence>(Endpoints.lab.evidence(), payload);
            if (!biomarkerId) return;
            await fetchEvidence();
        } catch (error) {
            logger.error(error);
            setError('Failed to add new evidence');
        }
    };

    const updateEvidence = async ({
        type,
        id,
        referenceObjectID,
        rationale,
        status,
        score,
        evidenceID,
    }: {
        type?: InternalEvidenceType;
        id?: string;
        referenceObjectID?: string;
        rationale?: string;
        status?: string;
        score?: number | null;
        evidenceID: string;
    }) => {
        const object_uuid: string | null = referenceObjectID ?? biomarkerId ?? null;
        if (!object_uuid || !type || !id) return;
        setError('');
        try {
            await api.put<Evidence>(Endpoints.lab.evidenceBase(evidenceID), {
                object_type: targetType,
                object_uuid,
                evidence_object_type: type,
                evidence_object_uuid: id,
                rationale,
                status,
                score,
            });
            if (!biomarkerId) return;
            await fetchEvidence();
        } catch (error) {
            logger.error(error);
            setError('Failed to update evidence');
        }
    };

    const archiveEvidence = async (evidenceId: string) => {
        setError('');
        try {
            await api.post(Endpoints.lab.archiveEvidence(evidenceId), {
                object_type: targetType,
                object_uuid: biomarkerId,
            });
            await fetchEvidence();
        } catch (error) {
            logger.error(error);
            setError('Failed to archive evidence');
        }
    };

    return {
        error,
        count,
        evidence,
        loading,
        postNewEvidence,
        archiveEvidence,
        setEvidence,
        updateEvidence,
    };
};

export default useEvidence;
