import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import GeneSet, { GeneSetField } from '@models/GeneSet';
import { ReactElement, useMemo, useState } from 'react';
import useAnalysisGeneSets from '@hooks/useAnalysisGeneSets';
import { blankToUndefined, isNotBlank } from '@util/StringUtil';
import TextInput from '@components/forms/TextInput';
import { IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SearchIcon } from '@heroicons/react/outline';
import LoadingMessage from '@components/LoadingMessage';
import DataTable from '@components/dataTable/DataTable';
import Logger from '@util/Logger';

const logger = Logger.make('SearchableGeneSetTable');

type HeaderName = keyof GeneSet;
type Props = {
    experiment: Experiment;
    plot: Plot;
    onSelected: (value: GeneSet) => void;
    hideSelectColumn?: boolean;
};
const SearchableGeneSetTable = ({ plot, experiment, onSelected, hideSelectColumn = false }: Props) => {
    const experimentId = experiment.uuid;
    const analysisId = plot.analysis?.uuid;
    const [searchInput, setSearchInput] = useState('');

    const {
        loading,
        setSearch,
        search: searchQuery,
        tabularData,
        sortBy,
        setSortBy,
        sortByDesc,
        setSortByDesc,
        appendValueIfNotPresent,
    } = useAnalysisGeneSets({
        analysisId,
        experimentId,
    });

    const headers = useMemo<string[]>(() => {
        return tabularData?.headers.filter((h) => h !== 'Gene_Set_Shortname') ?? [];
    }, [tabularData]);

    const handleSearchSubmit = () => {
        logger.info('submitting form value', searchInput);
        setSearch(blankToUndefined(searchInput));
    };

    const handleSearchCleared = () => {
        setSearchInput('');
        setSearch(undefined);
    };

    const handleRowSelected = (item: GeneSet) => {
        logger.info('handling row selected....', item);
        appendValueIfNotPresent(item);
        onSelected(item);
    };

    const handleSortChange = (header: GeneSetField, desc?: boolean) => {
        setSortBy(header);
        setSortByDesc(desc ?? false);
    };

    const headerRenderer = (name: HeaderName | string): string | ReactElement => {
        switch (name) {
            case 'Gene_Set_Display_Name':
                return 'Gene set';
            case 'Adj_P_Value':
                return (
                    <span className="whitespace-nowrap">
                        Adj <i>p</i>-value
                    </span>
                );
            default:
                return name as string;
        }
    };

    return (
        <div>
            <form
                className="pt-1"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchSubmit();
                }}
            >
                <TextInput
                    className="mx-auto max-w-lg"
                    disableFormik
                    name="search"
                    value={searchInput ?? ''}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search by name"
                    iconRight={
                        <div className="flex items-center">
                            {searchInput && (
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    size="small"
                                    onClick={() => handleSearchCleared()}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            )}
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                onClick={() => handleSearchSubmit()}
                                size="small"
                            >
                                <SearchIcon className="h-5 w-5 text-indigo-600" />
                            </IconButton>
                        </div>
                    }
                />
            </form>
            <div className="min-h-screen-50 space-y-8">
                {loading && (
                    <div>
                        <LoadingMessage immediate message="Loading..." />
                    </div>
                )}
                {tabularData && (
                    <div>
                        <DataTable
                            data={{
                                ...tabularData,
                                headers,
                            }}
                            headerRenderer={headerRenderer}
                            onRowSelected={hideSelectColumn ? undefined : handleRowSelected}
                            sortable
                            tableColor="bg-cyan-100 text-cyan-900"
                            sortBy={sortBy}
                            sortDesc={sortByDesc}
                            onSortedChange={handleSortChange}
                            manualSortBy
                        />
                        {tabularData?.items?.length === 0 && (
                            <div className="py-8 text-center">
                                No results found{' '}
                                {isNotBlank(searchQuery) && (
                                    <span>
                                        for <span className="font-semibold">&quot;{searchQuery}&quot;</span>
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchableGeneSetTable;
