import cn from 'classnames';
import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

const ProteinProteinInteractionAnalysisIcon = ({
    className = defaultTextLightClass,
    height = 32,
    width = 32,
}: IconProps) => {
    return (
        <svg
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <rect x="5" y="5" width="22" height="22" rx="2" stroke="#6F7FE7" strokeWidth="2" />
            <circle cx="20.5" cy="11.5" r="2.5" fill="#6F7FE7" />
            <circle cx="11.5" cy="16.5" r="2.5" fill="#6F7FE7" />
            <circle cx="20.5" cy="20.5" r="2.5" fill="#6F7FE7" />
            <line x1="11.2236" y1="16.5528" x2="19.2236" y2="20.5528" stroke="#6F7FE7" />
            <line x1="10.7969" y1="16.5431" x2="19.7969" y2="12.5431" stroke="#6F7FE7" />
            <line x1="20.5" y1="20" x2="20.5" y2="12" stroke="#6F7FE7" />
        </svg>
    );
};

export default ProteinProteinInteractionAnalysisIcon;
