import PlotDisplayType from '@models/PlotDisplayType';
import { AnalysisCategory } from '@models/analysis/AnalysisCategory';

export type AnalysisShortname =
    | 'assay_summary_cpm_normalized'
    | 'assay_summary'
    | 'clustering'
    | 'coverage'
    | 'differential_binding'
    | 'differential_expression_continuous'
    | 'differential_expression'
    | 'external'
    | 'functional_annotation'
    | 'gene_set_enrichment'
    | 'image'
    | 'longitudinal'
    | 'overlap'
    | 'peak'
    | 'principal_components'
    | 'prism'
    | 'protein_protein_interaction'
    | 'sample_correlation'
    | 'seurat_differential_expression'
    | 'seurat_over_representation'
    | 'spreadsheet'
    | 'survival'
    | 'text'
    | 'transcription_factor_enrichment'
    | 'tsne'
    | 'umap';

export const KnownAnalysisShortnames: AnalysisShortname[] = [
    'assay_summary_cpm_normalized',
    'assay_summary',
    'clustering',
    'coverage',
    'differential_binding',
    'differential_expression_continuous',
    'differential_expression',
    'external',
    'functional_annotation',
    'gene_set_enrichment',
    'image',
    'longitudinal',
    'overlap',
    'peak',
    'principal_components',
    'prism',
    'protein_protein_interaction',
    'sample_correlation',
    'seurat_differential_expression',
    'seurat_over_representation',
    'spreadsheet',
    'survival',
    'text',
    'transcription_factor_enrichment',
    'tsne',
    'umap',
];

// Note on 2022-11-22 'image' category was changed to 'content'
export type AnalysisCategoryShortname =
    | 'clinical_outcomes'
    | 'comparative'
    | 'content'
    | 'dimensionality_reduction'
    | 'external'
    | 'genome'
    | 'image'
    | 'spreadsheet'
    | 'overlap'
    | 'pathway'
    | 'prism'
    | 'summary';

const analysisCategoryMap: Record<AnalysisShortname, AnalysisCategoryShortname> = {
    assay_summary_cpm_normalized: 'summary',
    assay_summary: 'summary',
    clustering: 'summary',
    coverage: 'genome',
    differential_binding: 'comparative',
    differential_expression_continuous: 'comparative',
    differential_expression: 'comparative',
    external: 'external',
    functional_annotation: 'genome',
    gene_set_enrichment: 'pathway',
    image: 'content',
    longitudinal: 'summary',
    overlap: 'comparative',
    peak: 'genome',
    principal_components: 'dimensionality_reduction',
    prism: 'content',
    protein_protein_interaction: 'pathway',
    sample_correlation: 'comparative',
    seurat_differential_expression: 'comparative',
    seurat_over_representation: 'pathway',
    spreadsheet: 'content',
    survival: 'clinical_outcomes',
    text: 'content',
    transcription_factor_enrichment: 'pathway',
    tsne: 'dimensionality_reduction',
    umap: 'dimensionality_reduction',
};

/**
 * Get the Category for a given analysis_shortname
 * @param {AnalysisShortname} analysis_shortname
 * @returns {AnalysisCategoryShortname}
 */
export const getCategoryForAnalysis = (analysis_shortname: AnalysisShortname): AnalysisCategoryShortname => {
    return analysisCategoryMap[analysis_shortname];
};

export const summaryAnalysisTypes: AnalysisShortname[] = ['assay_summary', 'assay_summary_cpm_normalized'];

export const isSummaryAnalysisType = (analysisType: AnalysisShortname) => {
    return summaryAnalysisTypes.includes(analysisType);
};

export default interface AnalysisType {
    shortname: AnalysisShortname;
    display_name: string;
    description?: string | null;
    is_enabled: boolean;
    display_types: PlotDisplayType[];
    pipeline_version?: string | number;
    tooltip?: string | null;
    category: AnalysisCategory;
}
