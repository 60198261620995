import { ChangeEvent, useEffect, useState, useRef } from 'react';
import usePlutoAI from '@hooks/usePlutoAI';
import Plot from '@models/Plot';
import { Chat, Thread } from '@models/Thread';
import { Alert, MenuItem, Select, SelectChangeEvent, TextareaAutosize } from '@mui/material';
import Button from '@components/Button';
import ChatItem from '@components/experiments/plutoAI/ChatItem';
import { EditIcon } from '@components/icons/custom/EditIcon';
import { TrashIcon } from '@heroicons/react/outline';
import { ArrowRightIcon } from '@heroicons/react/solid';
import AnnotationSet from '@/src/models/Annotation';
import cn from 'classnames';
import { LoadingIndicator } from '../../LoadingButton';

type Props = {
    plot: Plot | AnnotationSet;
    objectType?: 'plot' | 'clusterannotationset';
    clusterNumber?: number;
};
const PlutoAIPlotView = ({ plot, objectType, clusterNumber }: Props) => {
    const chatWindow = useRef<null | HTMLDivElement>(null);
    const [newQuery, setNewQuery] = useState<string>('');
    const [newChat, setNewChat] = useState<string>('');
    const [editingTitle, setEditingTitle] = useState<boolean>(false);
    const [showNewThread, setShowNewThread] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [threadTitle, setThreadTitle] = useState<string>('');
    const [activeThread, setActiveThread] = useState<Thread | null>(null);
    const [activeMessages, setActiveMessages] = useState<Chat[]>([]);
    const {
        threads,
        fetchThreads,
        threadsLoading,
        postNewThread,
        postNewChatToThread,
        updateThread,
        deleteThread,
        plutoAIError,
        clearPlutoAIErrors,
        threadAwaitingResponse,
        setClusterNumber,
    } = usePlutoAI({
        object_type: objectType ?? 'plot',
        object_uuid: plot.uuid,
    });

    useEffect(() => {
        if (!clusterNumber) return;

        setActiveThread(null);
        setClusterNumber(clusterNumber);
    }, [clusterNumber]);

    useEffect(() => {
        fetchThreads();
    }, [plot]);

    useEffect(() => {
        if (!activeThread) return setActiveMessages([]);

        setActiveMessages(
            activeThread.messages.sort((a: Chat, b: Chat) => Date.parse(a.created_at) - Date.parse(b.created_at)),
        );
    }, [activeThread]);

    useEffect(() => {
        if (!threads || !threads.length) return setActiveThread(null);
        if (activeThread) {
            const newActiveThread = threads.find((thread: Thread) => thread.uuid === activeThread.uuid) ?? threads[0];
            setThreadTitle(newActiveThread.title ?? '');
            setActiveThread(newActiveThread);
            return scrollToBottom();
        }

        setActiveThread(threads[0]);
        setThreadTitle(threads[0].title ?? '');
    }, [threads]);

    const handleChangeQuery = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (plutoAIError) clearPlutoAIErrors();
        setNewQuery(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            return handleCreateNewThread();
        }
    };

    const handleChangeChat = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (plutoAIError) clearPlutoAIErrors();
        setNewChat(e.target.value);
    };

    const handleChatKeyDown = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            return handleCreateNewChat();
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (!chatWindow.current) return;
            chatWindow.current.scrollTop = chatWindow.current?.scrollHeight;
        }, 100);
    };

    const handleCreateNewChat = async () => {
        if (!activeThread) return;
        await postNewChatToThread({ thread: activeThread, content: newChat });
        setNewChat('');
    };

    const handleCreateNewThread = async () => {
        const newThread = await postNewThread({ content: newQuery });
        if (!newThread) return;
        setActiveThread(newThread);
        setShowNewThread(false);
        setNewQuery('');
    };

    const handleChangeActiveThread = (e: SelectChangeEvent) => {
        const newThread = threads.find((thread: Thread) => thread.uuid === e.target.value);
        if (!newThread) return fetchThreads();
        setActiveThread(newThread);
    };

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setThreadTitle(e.target.value);
    const handleUpdateTitle = async () => {
        if (!activeThread) return;
        await updateThread({ title: threadTitle, thread_uuid: activeThread.uuid });
        setEditingTitle(false);
    };

    const handleDeleteThread = async () => {
        if (!activeThread) return;
        await deleteThread({ thread_uuid: activeThread.uuid });
        setConfirmDelete(false);
    };

    const handleStartNewThread = () => {
        if (threadAwaitingResponse) return;
        setNewQuery('');
        setNewChat('');
        setShowNewThread(true);
    };

    const formatTitle = (title: string | null) => {
        if (!title || title.length < 30) return title;
        return title.slice(0, 45) + '...';
    };
    const handleBackToThreads = () => setShowNewThread(false);

    const ThinkingAnimation = () => {
        const [dotCount, setDotCount] = useState(1);
        useEffect(() => {
            const interval = setInterval(() => {
                setDotCount((prevDotCount) => (prevDotCount % 3) + 1);
            }, 800);
            return () => clearInterval(interval);
        }, []);
        const dots = '.'.repeat(dotCount);
        return <p className="mb-1 text-xs text-default">PlutoAI is thinking{dots}</p>;
    };

    const renderStartNewThread = () => (
        <>
            <div className="mb-4 flex w-full items-center justify-between">
                <h1 className="text-xl font-semibold tracking-tight">PlutoAI</h1>
                {!!threads && !!threads.length ? (
                    <div
                        className="flex h-full w-full items-center justify-end space-x-1 hover:opacity-70"
                        onClick={handleBackToThreads}
                    >
                        <p className="cursor-pointer text-xs font-semibold tracking-tight text-primary">
                            Back to conversations
                        </p>
                        <ArrowRightIcon height={10} width={10} className="cursor-pointer text-primary" />
                    </div>
                ) : null}
            </div>
            <div className="flex h-full w-full flex-col">
                <p className="text-md mb-4 font-semibold tracking-tight">
                    Ready to start a new conversation with PlutoAI?{' '}
                    <span className="text-sm font-normal">Simply enter your question below to get started.</span>
                </p>
                <TextareaAutosize
                    minRows={4}
                    maxRows={8}
                    className="no_margin mb-2 w-full rounded border-slate-300"
                    onChange={handleChangeQuery}
                    onKeyDown={handleKeyDown}
                    value={newQuery}
                    placeholder="Ask a question to PlutoAI..."
                />
                <Button
                    className="self-end !py-[2px]"
                    variant="contained"
                    color="primary"
                    onClick={handleCreateNewThread}
                    disabled={!newQuery}
                >
                    Ask question
                </Button>
            </div>
        </>
    );

    return (
        <>
            {plutoAIError ? (
                <div className="m-2 mb-0 rounded">
                    <Alert severity="error">{plutoAIError}</Alert>
                </div>
            ) : null}
            {threadsLoading && !activeThread ? (
                <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white opacity-50">
                    <LoadingIndicator size={48} />
                </div>
            ) : null}
            <div className="my-2 flex h-full w-full flex-col p-4 pb-2 pl-8">
                {!activeThread || showNewThread ? (
                    renderStartNewThread()
                ) : (
                    <>
                        <div className="flex items-center justify-between">
                            <h1 className="text-xl font-semibold tracking-tight">PlutoAI</h1>
                            <Select
                                disabled={!!threadAwaitingResponse}
                                onChange={handleChangeActiveThread}
                                className="h-8 w-60 rounded-sm bg-slate-100"
                                displayEmpty
                                renderValue={() => <p>Select conversation...</p>}
                                sx={{
                                    '& .MuiSelect-nativeInput': {
                                        bottom: 'unset',
                                    },
                                    '& .MuiSelect-select': {
                                        padding: 0,
                                        color: 'black',
                                        fontSize: 14,
                                        paddingLeft: 1,
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                    },
                                    '& .MuiMenu-list': {
                                        padding: 0,
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            marginTop: '2px',
                                            borderRadius: '0.125rem',
                                            '& .MuiList-root': {
                                                padding: 0,
                                            },
                                        },
                                    },
                                }}
                            >
                                {threads.map((thread: Thread) => (
                                    <MenuItem
                                        key={thread.uuid}
                                        value={thread.uuid}
                                        className="h-8 bg-slate-100 px-[8px] text-sm text-black"
                                    >
                                        {thread.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div
                            className={cn('mb-2 flex w-full items-center justify-end space-x-1', {
                                'cursor-pointer text-primary hover:opacity-70': !threadAwaitingResponse,
                                'cursor-auto text-slate-500': !!threadAwaitingResponse,
                            })}
                            onClick={handleStartNewThread}
                        >
                            <p className="text-xs font-semibold tracking-tight text-inherit">Start new conversation</p>
                            <ArrowRightIcon height={10} width={10} className="text-inherit" />
                        </div>
                        {editingTitle && (
                            <div className="flex flex-col space-y-1">
                                <input
                                    value={threadTitle}
                                    onChange={handleTitleChange}
                                    className="border-b outline-none"
                                />
                                <div className="flex items-center justify-end">
                                    <div
                                        className="cursor-pointer text-xs font-semibold tracking-tight text-primary hover:opacity-70"
                                        onClick={handleUpdateTitle}
                                    >
                                        <p>Save</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {confirmDelete && (
                            <div className="flex flex-col space-y-1">
                                <p className="text-default">Delete thread: {activeThread.title}?</p>
                                <div className="flex items-center justify-end space-x-2">
                                    <div
                                        className="cursor-pointer text-xs font-semibold tracking-tight text-primary hover:opacity-70"
                                        onClick={handleDeleteThread}
                                    >
                                        <p>Confirm</p>
                                    </div>
                                    <div
                                        className="cursor-pointer text-xs font-semibold tracking-tight text-error hover:opacity-70"
                                        onClick={() => setConfirmDelete(false)}
                                    >
                                        <p>Cancel</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!editingTitle && !confirmDelete && (
                            <div className="flex w-full items-center justify-between">
                                <p className="font-semibold text-default">{formatTitle(activeThread.title)}</p>
                                <div className="flex space-x-1">
                                    <div onClick={() => setEditingTitle(true)}>
                                        <EditIcon
                                            height={13}
                                            width={13}
                                            className="cursor-pointer text-primary hover:opacity-70"
                                        />
                                    </div>
                                    <div onClick={() => setConfirmDelete(true)}>
                                        <TrashIcon
                                            height={13}
                                            width={13}
                                            className="cursor-pointer text-error hover:opacity-70"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="my-4 flex flex-col overflow-auto pb-4" ref={chatWindow}>
                            {activeMessages.map((message) => (
                                <ChatItem key={message.uuid} message={message} threadId={activeThread.uuid} />
                            ))}
                        </div>
                        {threadAwaitingResponse && <ThinkingAnimation />}
                        <div className="mb-12 flex min-h-32 flex-col">
                            <TextareaAutosize
                                minRows={2}
                                maxRows={4}
                                className="no_margin mb-2 w-full rounded border-slate-300"
                                onChange={handleChangeChat}
                                onKeyDown={handleChatKeyDown}
                                value={newChat}
                                placeholder="Ask a question to PlutoAI..."
                                disabled={threadAwaitingResponse}
                            />
                            <Button
                                className="self-end pt-1"
                                variant="contained"
                                color="primary"
                                onClick={handleCreateNewChat}
                                disabled={!newChat || threadAwaitingResponse}
                                size="small"
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default PlutoAIPlotView;
