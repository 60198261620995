import Experiment from '@models/Experiment';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import Plot from '@models/Plot';
import { useCallback, useEffect, useState } from 'react';
import { PlotOverrideSummary, useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { AnyExperimentData } from '@models/ExperimentData';
import { hasAnyPipelineStatus } from '@components/plots/PlotUtil';
import { isDefined } from '@util/TypeGuards';
import { blankToUndefined } from '@util/StringUtil';
import Logger from '@util/Logger';
import useMatchMutate from '@hooks/useMatchMutate';

const logger = Logger.make('usePlot');

type Props = {
    experiment: Experiment;
    plot?: Plot | null | undefined;
    plotData?: AnyExperimentData;
    plotId: string | null | undefined;
    disablePlotData?: boolean;
    disablePolling?: boolean;
    revalidateOnMount?: boolean;
    searchTerm?: string | undefined | null;
    sortDataBy?: string | null;
    overrides?: PlotOverrideSummary | null;
    dataLimit?: number;
    publicKey?: string | null;
};
const usePlot = ({
    experiment,
    plotId,
    plot: plotParam,
    plotData: plotDataParam,
    disablePlotData = false,
    disablePolling = false,
    revalidateOnMount,
    searchTerm,
    sortDataBy,
    overrides: overridesParam,
    dataLimit,
    publicKey,
}: Props) => {
    const { getPlotOverrides } = useOptionalExperimentDetailViewContext();
    const [refreshInterval, setRefreshInterval] = useState(0);
    const { pathEqualsIgnoreQueryMutate } = useMatchMutate();
    const overrides = overridesParam ?? (plotId ? getPlotOverrides?.(plotId) : null);
    const {
        data: plot,
        error: plotError,
        isValidating: isPlotValidating,
        mutate: mutatePlot,
    } = useSWR(
        isDefined(plotId)
            ? Endpoints.lab.experiment.plot.base(
                  {
                      experimentId: experiment.uuid,
                      plotId,
                  },
                  {
                      display_id: overrides?.display_id ?? undefined,
                      analysis_id: overrides?.analysis_id ?? undefined,
                      key: publicKey ?? undefined,
                  },
              )
            : null,
        { fallbackData: plotParam, refreshInterval, revalidateOnMount },
    );
    const plotLoading = !plot && !plotError;

    const getPlotDataLimit = useCallback(() => {
        if (dataLimit) {
            return dataLimit;
        }
        if (plot?.display?.display_type === 'volcano_plot_v2') {
            return 150_000;
        }
        if (plot?.display?.display_type === 'dot_plot') {
            return 2_000;
        }
        if (plot?.display?.display_type === 'heatmap') {
            return 2_000;
        }

        return undefined;
    }, [dataLimit, plot?.display?.display_type]);

    /** Fetch plot data */
    const {
        data: plotData,
        error: plotDataError,
        isValidating: isPlotDataValidating,
    } = useSWR<AnyExperimentData>(
        () =>
            plot?.analysis && !disablePlotData && isDefined(plotId)
                ? Endpoints.lab.experiment.plot.data(
                      {
                          plotId: plotId,
                          experimentId: experiment.uuid,
                      },
                      {
                          analysis_id: overrides?.analysis_id ?? undefined,
                          display_id: overrides?.display_id ?? undefined,
                          search: blankToUndefined(searchTerm) ?? undefined,
                          sort_by: blankToUndefined(sortDataBy),
                          limit: getPlotDataLimit(),
                          key: publicKey ?? undefined,
                      },
                  )
                : null,
        { refreshInterval, revalidateOnMount, fallbackData: plotDataParam },
    );
    const plotDataLoading = !plotData && !plotDataError;

    useEffect(() => {
        if (!plot || disablePolling) {
            setRefreshInterval(0);
            return;
        }
        const hasAnyPending =
            hasAnyPipelineStatus(plot, 'in_progress') ||
            plotData?.pipeline_status === 'in_progress' ||
            plotData?.plot_status === 'in_progress';
        if (hasAnyPending) {
            setRefreshInterval(10_000);
        } else {
            setRefreshInterval(0);
        }
    }, [plot, disablePolling, plotData]);

    useEffect(() => {
        if (plotId) {
            logger.debug('plot display type changed to', plot?.display?.display_type, 'revalidating plot data');
            pathEqualsIgnoreQueryMutate(
                Endpoints.lab.experiment.plot.data({
                    plotId: plotId,
                    experimentId: experiment.uuid,
                }),
            );
        }
    }, [plot?.display?.display_type, plotId]);

    return {
        plot,
        plotData,
        plotDataError,
        plotError,
        isPlotDataValidating,
        isPlotValidating,
        plotLoading,
        plotDataLoading,
        overrides,
        mutatePlot,
    };
};

export default usePlot;
