import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { FormikFieldError } from '@components/forms/FieldError';
import React, { ReactChild, ReactNode } from 'react';
import { useField, useFormikContext } from 'formik';
import { isBlank, isNotBlank } from '@util/StringUtil';
import { DifferentialExpressionAnalysis } from '@models/analysis/DifferentialExpressionAnalysis';
import { isDefined } from '@util/TypeGuards';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { AnalysisTypeQueryParams } from '@services/QueryParams';
import { DifferentialExpressionPickerFieldTypes } from '@models/AnalysisParameters';

const NONE_VALUE = 'none';
const RenderValue = ({
    loading,
    comparisons,
    id,
}: {
    comparisons?: DifferentialExpressionAnalysis[];
    loading?: boolean;
    id: string | null;
}) => {
    const selectedValue = comparisons?.find((s) => s.uuid === id)?.name;

    if (!isBlank(selectedValue) && selectedValue !== NONE_VALUE) {
        return <span className="text-dark">{selectedValue}</span>;
    }

    return <span className="opacity-70">{loading ? 'Loading...' : 'Select a comparison...'}</span>;
};

type Props = {
    /**
     * The ID of the experiment for which to fetch the analysis
     */
    experimentId: string;
    /**
     * The name of the form field
     */
    name: string;
    label?: ReactNode;
    disabled?: boolean;
    tooltip?: { title: NonNullable<ReactNode>; href?: string | null; icon?: ReactChild | null } | null;
    analysisTypeFieldName?: string;
    filter?: AnalysisTypeQueryParams;
    selectSx?: Record<string, unknown>;
};
const DifferentialExpressionAnalysisPickerField = ({
    experimentId,
    name,
    label = 'Comparison',
    disabled,
    tooltip,
    selectSx,
    analysisTypeFieldName,
    filter = { analysis_types: DifferentialExpressionPickerFieldTypes },
}: Props) => {
    const { data: comparisons, error: comparisonsError } = useSWR<DifferentialExpressionAnalysis[]>(() =>
        Endpoints.lab.experiment.analyses(experimentId, filter),
    );
    const loading = !comparisons && !comparisonsError;
    const [helpers, { value, error, touched }] = useField<string>(name);
    const formContext = useFormikContext();
    const formValue = isBlank(value) ? NONE_VALUE : value;

    let $tooltip: ReactNode | null = null;
    if (isDefined(tooltip)) {
        const $icon = tooltip.icon ?? <QuestionMarkCircleIcon className="h-4 w-4" />;
        const $child = isNotBlank(tooltip.href) ? (
            <a href={tooltip.href} target="_blank" rel="nofollow noreferrer">
                {$icon}
            </a>
        ) : (
            $icon
        );
        $tooltip = (
            <Tooltip title={tooltip.title} placement="right" arrow>
                <span>{$child}</span>
            </Tooltip>
        );
    }

    return (
        <label className="form-field">
            <span className="field-label flex items-center space-x-2">
                <span>{label}</span>
                {$tooltip}
            </span>

            <FormControl variant="outlined" fullWidth error={!!error && touched} disabled={loading || disabled}>
                <Select
                    sx={selectSx}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    name={name}
                    value={loading ? 'loading' : formValue}
                    onChange={(e) => {
                        helpers.onChange(e);
                        if (analysisTypeFieldName) {
                            const analysisId = e.target.value as string;
                            const analysis = comparisons?.find((c) => c.uuid === analysisId);
                            formContext.setFieldValue(analysisTypeFieldName, analysis?.analysis_type ?? null);
                        }
                    }}
                    placeholder="Select a comparison"
                    renderValue={(id: string) => <RenderValue loading={loading} comparisons={comparisons} id={id} />}
                >
                    <MenuItem value={NONE_VALUE} disabled hidden className="!hidden">
                        Nothing selected
                    </MenuItem>
                    {loading && (
                        <MenuItem disabled value="loading">
                            Loading...
                        </MenuItem>
                    )}

                    {!loading &&
                        comparisons?.map((comparison) => (
                            <MenuItem key={comparison.uuid} value={comparison.uuid}>
                                <div>
                                    <span className="block ">{comparison.plot_title || comparison.name}</span>
                                </div>
                            </MenuItem>
                        ))}
                </Select>
                <FormikFieldError name={name} />
            </FormControl>
        </label>
    );
};

export default DifferentialExpressionAnalysisPickerField;
