import { AnalysisShortname } from '@/src/models/analysis/AnalysisType';
import DotPlotLegend from '../analysisCategories/comparative/plots/dot/DotPlotLegend';
import Plot from '@/src/models/Plot';
import { PipelineStatusPlotData } from '@/src/models/ExperimentData';

type Props = {
    plot: Plot;
    plotData: PipelineStatusPlotData;
    shortname: AnalysisShortname;
    showLegendBottom?: boolean;
    publicationMode?: boolean;
};
const CustomPlotLegendView = ({ plot, plotData, shortname, showLegendBottom, publicationMode }: Props) => {
    switch (shortname) {
        case 'seurat_over_representation':
        case 'sample_correlation':
            return (
                <DotPlotLegend
                    plot={plot}
                    plotData={plotData}
                    showLegendBottom={showLegendBottom}
                    publicationMode={publicationMode}
                />
            );
        default:
            return null;
    }
};

export default CustomPlotLegendView;
