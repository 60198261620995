import { SummaryAnalysisPlotData } from '@models/ExperimentData';
import PlotNoDataView from '@components/plots/PlotNoDataView';
import React from 'react';
import BarPlotView from '@components/analysisCategories/summary/plots/BarPlotView';
import BoxPlotView from '@components/analysisCategories/summary/plots/BoxPlotView';
import HeatmapView from '@components/analysisCategories/summary/plots/HeatmapView';
import { usePlotContext } from '@contexts/PlotContext';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import PlotLoadingView from '@components/plots/PlotLoadingView';
import LinePlotView from '@components/analysisCategories/summary/plots/LinePlotView';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null };

const SummaryPlotDisplayView = ({ customPlotStylingOptions }: Props) => {
    const { plot, plotData, experiment } = usePlotContext();
    const data = plotData as SummaryAnalysisPlotData | null | undefined;

    if (data?.pipeline_status === 'in_progress') {
        return <PlotLoadingView />;
    }

    if (!data || (data.count ?? 0) === 0) {
        return <PlotNoDataView />;
    }
    switch (plot.display.display_type) {
        case 'box_plot':
            return (
                <BoxPlotView plot={plot} experiment={experiment} customPlotStylingOptions={customPlotStylingOptions} />
            );
        case 'bar_plot':
            return (
                <BarPlotView plot={plot} experiment={experiment} customPlotStylingOptions={customPlotStylingOptions} />
            );
        case 'heatmap':
            return <HeatmapView plot={plot} experiment={experiment} />;
        case 'line_plot':
            return <LinePlotView customPlotStylingOptions={customPlotStylingOptions} />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default SummaryPlotDisplayView;
