import {
    ThemeablePlotChartDisplayOption,
    CustomizablePlotChartDisplayOption,
} from '@models/plotDisplayOption/BasePlotDisplayOption';
import { ThemeColor, ThemeStyle, VolcanoPlotOptions } from '@models/PlotConfigs';
import { DisplayFormValue } from '@models/PlotDisplayOption';

export default interface VolcanoPlotDisplayOption
    extends CustomizablePlotChartDisplayOption,
        ThemeablePlotChartDisplayOption,
        VolcanoPlotOptions {
    show_fold_change_lines: boolean;
    fold_change_fill_threshold_upper: number;
    fold_change_fill_threshold_lower: number;
    show_pval_line: boolean;
    pval_fill_threshold: number;
    selected_targets?: string[] | null;
    x_axis_start: number | null;
    x_axis_end: number | null;
    y_axis_start: number | null;
    y_axis_end: number | null;
}

export const DefaultVolcanoPlotDisplayOption: DisplayFormValue<VolcanoPlotDisplayOption> = {
    display_type: 'volcano_plot',
    plot_title: '',
    theme_color: ThemeColor.cool,
    theme_style: ThemeStyle.medium,
    show_pval_line: false,
    pval_fill_threshold: 0.01,
    show_fold_change_lines: false,
    fold_change_fill_threshold_lower: 0.8,
    fold_change_fill_threshold_upper: 1.2,
    x_axis_start: null,
    x_axis_end: null,
    y_axis_start: null,
    y_axis_end: null,
};
