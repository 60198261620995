import React, { useState, MouseEvent } from 'react';
import { Popover, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

interface CellLineCellProps {
    value: string;
}

interface CellLineData {
    Cellosaurus: {
        'cell-line-list': [
            {
                'name-list': Array<{ type: string; value: string }>;
                'species-list': Array<{ value: string }>;
                'disease-list': Array<{ value: string }>;
                'comment-list': Array<{ category: string; value: string }>;
                'accession-list': Array<{ type: string; value: string }>;
            },
        ];
    };
}

const CellLineCell: React.FC<CellLineCellProps> = ({ value }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [cellLineData, setCellLineData] = useState<CellLineData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleClick = async (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`/api/external/cellLine?cellLineName=${value}`);
            if (!response.ok) {
                throw new Error("Couldn't fetch data. Is the cell line name correct?");
            }
            const data = await response.json();
            setCellLineData(Array.isArray(data) ? data : [data]);
        } catch (error) {
            setError((error as Error).message);
        }
        setLoading(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <a href="#" aria-describedby={id} onClick={handleClick}>
                {value}
            </a>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    className: '!rounded-xl !border !border-gray-200 !bg-white !shadow-lg',
                }}
            >
                {loading ? (
                    <div className="p-5">
                        <CircularProgress size={20} style={{ marginRight: '10px' }} />
                        Loading from Cellosaurus
                    </div>
                ) : error ? (
                    <div>
                        <p>
                            <b>Error:</b> {error}
                        </p>
                    </div>
                ) : (
                    cellLineData.map((cellLine, index) => {
                        const {
                            'name-list': nameList,
                            'species-list': speciesList,
                            'disease-list': diseaseList,
                            'comment-list': commentList,
                            'accession-list': accessionList,
                        } = cellLine.Cellosaurus['cell-line-list'][0];

                        const primaryName =
                            nameList.find(({ type }) => type === 'identifier')?.value ?? 'Not available';
                        const species = speciesList[0]?.value ?? 'Not available';
                        const disease = diseaseList[0]?.value ?? 'Not available';
                        const group = commentList?.find(({ category }) => category === 'Group');
                        const groupValue = group ? group.value : 'Not available';
                        const accessionId = accessionList.find(({ type }) => type === 'primary')?.value;

                        return (
                            <div
                                className="max-h-[300px] min-h-[200px] w-[350px] space-y-4 overflow-auto p-4"
                                key={index}
                            >
                                <h3 className="flex items-center justify-between">
                                    <span className="text-lg font-semibold text-dark">{primaryName}</span>
                                    <span className="inline-flex items-center space-x-2">
                                        <IconButton
                                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                            size="small"
                                            color="primary"
                                            href={`https://www.cellosaurus.org/${accessionId}`}
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                        >
                                            <ExternalLinkIcon width={18} />
                                        </IconButton>
                                    </span>
                                </h3>
                                <span className="text-xs font-semibold">{groupValue}</span>

                                <div>
                                    <p className="font-semibold text-dark">Disease</p>
                                    <p className="text-dark">{disease}</p>
                                </div>
                                <div>
                                    <p className="font-semibold text-dark">Species of origin</p>
                                    <p className="text-dark">{species}</p>
                                </div>
                                {accessionId && (
                                    <div>
                                        <p className="font-semibold text-dark">Accession</p>
                                        <p className="text-s">
                                            <a
                                                href={`https://www.cellosaurus.org/${accessionId}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {accessionId}
                                            </a>
                                        </p>
                                    </div>
                                )}
                                <div>
                                    <p className="flex items-center space-x-1 text-xs">
                                        <span>Annotations by Cellosaurus</span>
                                        <IconButton
                                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                            size="small"
                                            color="primary"
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                            href="https://help.pluto.bio/en/articles/6458235-biological-entity-annotations"
                                        >
                                            <HelpCircleIcon width={14} />
                                        </IconButton>
                                    </p>
                                </div>
                            </div>
                        );
                    })
                )}
            </Popover>
        </div>
    );
};

CellLineCell.propTypes = {
    value: PropTypes.string.isRequired,
};

export default CellLineCell;
