import React, { useMemo, useState } from 'react';
import Plot from '@models/Plot';
import TextInputField from '@components/forms/TextInputField';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import LegendFieldGroup from '../groups/LegendFieldGroup';
import { OverlapAnalysis, OverlapLists } from '@/src/models/analysis/OverlapAnalysis';
import EditVennDiagramLegendModal from '@/src/components/plots/legendEditor/EditVennDiagramLegendModal';
import { EditIcon } from '@components/icons/custom/EditIcon';
import { useFormikContext } from 'formik';
import Button from '@/src/components/Button';
import { CustomLegendColorItem } from '../groups/ControlledCustomLegendColorField';
import { getPlotPalette } from '@/src/components/ColorPaletteUtil';
import { StringParameterOption } from '@/src/models/AnalysisParameters';
import CheckboxField from '@/src/components/forms/CheckboxField';
import { VennDiagramDisplayOptionFormValues } from '@/src/models/PlotDisplayOption';

export type HybridLegendItem = CustomLegendColorItem & StringParameterOption;
type Props = { plot: Plot };

const VennDiagramDisplayFields = ({ plot }: Props) => {
    const { values, setFieldValue } = useFormikContext<VennDiagramDisplayOptionFormValues>();
    const [openLegendModal, setOpenLegendModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<HybridLegendItem | null>(null);

    const plotPalette = getPlotPalette(values.theme_color);
    const colors = plotPalette.colors;

    const items = useMemo((): HybridLegendItem[] => {
        switch (plot?.analysis?.analysis_type) {
            case 'overlap':
                const analysis = plot.analysis as OverlapAnalysis;
                const overlapItems: HybridLegendItem[] = [];

                OverlapLists.forEach((listKey, i) => {
                    const list = analysis[listKey];
                    if (list) {
                        const display_name = `${list?.name ? list?.name : `List ${i + 1}`} (${i + 1})`;
                        const label = `${list?.name ? list?.name : `List ${i + 1}`}`;
                        overlapItems.push({
                            id: list.uuid,
                            label,
                            display_name,
                            themeColor: colors[i % colors.length]?.color,
                            labelName: '',
                        });
                    }
                });

                return overlapItems;
            default:
                return [];
        }
    }, [plot]);

    const setOptions = ({ groupId, options }: { groupId: number | string; options: any }) => {
        setFieldValue(`custom_options_json.${groupId}`, options);
    };
    const setLegend = ({ legend }: { legend: Record<string, string> | null }) => {
        setFieldValue('custom_legend_json', {
            ...values.custom_legend_json,
            ...legend,
        });
    };

    return (
        <>
            <div className="flex h-full flex-col">
                <div className="flex-grow">
                    <FullWidthToggleField />
                    <TextInputField name="plot_title" label="Title" placeholder={plot.analysis?.name ?? ''} />

                    <section className="flex flex-col gap-4">
                        <h4 className="text-dark text-lg font-semibold tracking-tight">Appearance</h4>
                        <div className="flex flex-col space-y-1">
                            <p className="tracking-tight font-semibold">Labels</p>
                            <CheckboxField
                                className="flex items-center"
                                offsetCheckbox={false}
                                name="show_labels"
                                label="Show primary labels (sets)"
                            />
                            <CheckboxField
                                className="flex items-center"
                                offsetCheckbox={false}
                                name="show_sublabels"
                                label="Show sub-labels (counts)"
                            />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <p className="tracking-tight font-semibold">List label</p>
                            <label className="block">
                                <input
                                    type="radio"
                                    className="cursor-pointer text-indigo-500"
                                    name="use_names_as_label"
                                    onChange={() => {
                                        setFieldValue('use_names_as_label', false);
                                    }}
                                    checked={!values.use_names_as_label}
                                />
                                <span className="ml-2">List number</span>
                            </label>
                            <label className="block">
                                <input
                                    type="radio"
                                    className="cursor-pointer text-indigo-500"
                                    name="use_names_as_label"
                                    onChange={() => {
                                        setFieldValue('use_names_as_label', true);
                                    }}
                                    checked={values.use_names_as_label}
                                />
                                <span className="ml-2">List name</span>
                            </label>
                        </div>
                    </section>
                </div>
                <LegendFieldGroup
                    customOptionField="circle_color"
                    items={items}
                    editComponent={(selectedItem) => {
                        return (
                            <Button
                                onClick={() => {
                                    setSelectedItem(selectedItem as HybridLegendItem);
                                    setOpenLegendModal(true);
                                }}
                            >
                                <EditIcon height={14} width={14} className="text-slate-500" />
                            </Button>
                        );
                    }}
                    ignoreDisplayOrder
                />
            </div>
            <EditVennDiagramLegendModal
                onSave={({ groupId, options, legend }) => {
                    setOptions({ groupId, options });
                    setLegend({ legend });
                }}
                selectedItem={selectedItem as HybridLegendItem}
                open={Boolean(openLegendModal)}
                onClose={() => setOpenLegendModal(false)}
            />
        </>
    );
};

export default VennDiagramDisplayFields;
