import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import React, { useCallback } from 'react';
import { ScrollableSidebarContent, ScrollableSidebarFooter } from '@components/experiments/ScrollableSidebarContent';
import { Formik, FormikHelpers } from 'formik';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import Endpoints from '@services/Endpoints';
import { useSWRConfig } from 'swr';
import useApi from '@hooks/useApi';
import AnalysisFormSubmitButton from '@components/experiments/analyses/AnalysisFormSubmitButton';
import AnalysisFormFields from '@components/experiments/analyses/AnalysisFormFields';
import AnalysisIcon from '@components/experiments/analyses/AnalysisIcon';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { ApiError } from '@services/ApiError';
import {
    getFormSetup,
    isImageAnalysisFormFields,
    isMultipartProteinProteinAnalysis,
    isSpreadsheetAnalysisFormFields,
    isPrismAnalysisFormFields,
    isOverlapAnalysis,
    isExternalAnalysisFormFields,
} from '@components/experiments/analyses/AnalysisFormUtil';
import { AnalysisShortname, getCategoryForAnalysis, isSummaryAnalysisType } from '@models/analysis/AnalysisType';
import Logger from '@util/Logger';
import { AssaySummaryAnalysisFormValues, AnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import useExperimentSettings from '@hooks/useExperimentSettings';
import AnalysisPipelineUpgradeBanner from '@components/experiments/analyses/AnalysisPipelineUpgradeBanner';
import { isBlank } from '@util/StringUtil';
import { deepEqual, getChangedValues } from '@util/ObjectUtil';
import StatisticalSymbolLabel from '@components/StatisticalSymbolLabel';
import AggregateFormErrorAlert from '@components/experiments/AggregateFormErrorAlert';
import useMatchMutate from '@hooks/useMatchMutate';
import FormikListener from '@components/forms/FormikListener';

const logger = Logger.make('ExperimentAnalysisForm');

type Props = {
    plot: Plot;
    experiment: Experiment;
    analysisParameters: AnalysisParameters | null;
};

const analysesWithCustomSubmit = ['overlap'];

/**
 * Render the form to create/edit an Experiment Analysis for a given plot.
 *
 * Note: the `key` on the Formik form ensures that when a different plot is selected that a new form is rendered,
 * rather than re-using the existing, mounted component. This solves an issue where different plots have different
 * fields present/absent, which could cause null pointer errors on an initial render.
 * Passing in a key ensures we have a fresh component whne the plot changes.
 * @param {AssaySummaryAnalysisParameters | DifferentialExpressionAnalysisParameters} analysisParameters
 * @param {Experiment} experiment
 * @param {Plot} plot
 * @return {JSX.Element}
 * @constructor
 */
const AnalysisForm = ({ analysisParameters, experiment, plot }: Props) => {
    const { mutate } = useSWRConfig();
    const { post, put, apiService } = useApi();
    const {
        onAnalysisSubmit,
        analysisFormSubmitDisabled,
        currentChanges,
        refreshExperiment,
        refreshPlotItems,
        setCurrentChanges,
        setNestedCurrentChanges,
        setPlotAnalysisOverride,
        updatePlotDisplayOrder,
        updatePreviewPlot,
    } = useExperimentDetailViewContext();
    const permissions = useExperimentPermissions(experiment);
    const { getAnalysisInfo } = useExperimentSettings(experiment);
    const mutators = useMatchMutate();
    /**
     * Get initial values for the analysis form
     * @type {{initialValues: AnalysisFormValues, schema: Yup.AnySchema} | null}
     */
    const setup = getFormSetup({ plot, experiment, analysisParameters });
    if (!setup) {
        logger.warn(`Can not generate a setup object for analysis_type "${plot.analysis_type}"`, {
            plot,
            analysisParameters,
        });
        return (
            <ScrollableSidebarContent className="px-8">
                This type of analysis is not yet supported.
            </ScrollableSidebarContent>
        );
    }

    const analysisType = plot.analysis?.analysis_type ?? plot?.analysis_type;

    const analysisInfo = getAnalysisInfo(analysisType, experiment.type.shortname);

    const getAnalysis = async (analysisType: AnalysisShortname, values: AnalysisFormValues) => {
        if (analysisType === 'image' && isImageAnalysisFormFields(values)) {
            logger.info('submitting image analysis');
            return await apiService.uploadImageAnalysis({ values, experiment, plot });
        }

        if (analysisType === 'prism' && isPrismAnalysisFormFields(values)) {
            logger.info('submitting prism analysis');
            return await apiService.uploadPrismAnalysis({ values, experiment, plot });
        }

        if (analysisType === 'external' && isExternalAnalysisFormFields(values)) {
            logger.info('submitting external analysis');
            return await apiService.uploadExternalAnalysis({ values, experiment, plot });
        }

        if (plot.analysis_type === 'spreadsheet' && isSpreadsheetAnalysisFormFields(values)) {
            logger.info('submitting spreadsheet analysis');
            return await apiService.uploadSpreadsheetAnalysis({ values, experiment, plot });
        }

        if (analysisType === 'protein_protein_interaction' && isMultipartProteinProteinAnalysis(values)) {
            logger.info('submitting protein-protein interaction analysis');
            return await apiService.uploadProteinProteinAnalysis({ values, experiment });
        }
        if (analysisType === 'overlap' && isOverlapAnalysis(values)) {
            return await apiService.putOverlapAnalysis({ values, experiment, plot });
        }

        const payload: FormData | (AnalysisFormValues & { analysis_type: AnalysisShortname | null }) = {
            analysis_type: analysisType,
            ...values,
        };
        if (isSummaryAnalysisType(analysisType)) {
            const summaryPayload = payload as AssaySummaryAnalysisFormValues;
            summaryPayload.group_display_order = [...summaryPayload.group_ids];
            summaryPayload.target_display_order = [...summaryPayload.targets];
        }
        return await post<ExperimentAnalysis>(Endpoints.lab.experiment.analyses(experiment.uuid), payload);
    };

    /**
     * Save the form values to the experiment analysis.
     * @param {AnalysisFormValues} values
     * @param {FormikHelpers<FormValues>} helpers
     * @return {Promise<void>}
     */
    const handleSubmit = async (values: AnalysisFormValues, helpers: FormikHelpers<AnalysisFormValues>) => {
        // ensure the uuid was removed if it was present
        delete values['uuid'];
        logger.debug('Submitting analysis values', values, plot.analysis_type);
        helpers.setStatus(null);
        try {
            const analysis: ExperimentAnalysis = await getAnalysis(plot.analysis_type, values);

            // TODO: update this flow to be more clear what is going on...too many conditionals
            if (permissions.canEdit || plot.status === 'preview') {
                const updatedPlot = await put<Plot>(
                    Endpoints.lab.experiment.plot.base({
                        experimentId: experiment.uuid,
                        plotId: plot.uuid,
                    }),
                    { analysis_id: analysis.uuid },
                );
                if (updatedPlot.status === 'preview') {
                    updatePreviewPlot(updatedPlot);
                }

                logger.debug('linking published plot display and analysis', {
                    plot,
                    linked_values: {
                        analysis_id: analysis.uuid,
                        display_id: plot.display?.uuid ?? null,
                    },
                });
                await post(
                    Endpoints.lab.experiment.plot.linkAnalysis({
                        experimentId: experiment.uuid,
                        plotId: plot.uuid,
                    }),
                    { analysis_id: analysis.uuid, display_id: plot.display?.uuid ?? null },
                );

                const newDisplayOrder = [plot.uuid, ...(experiment.plot_display_order ?? [])];
                await Promise.all([
                    mutate(
                        Endpoints.lab.experiment.plot.base({
                            experimentId: experiment.uuid,
                            plotId: updatedPlot.uuid,
                        }),
                    ),
                    mutators.startsWithMutate(
                        Endpoints.lab.experiment.plot.data({
                            experimentId: experiment.uuid,
                            plotId: plot.uuid,
                        }),
                    ),
                    updatePlotDisplayOrder(newDisplayOrder),
                    refreshPlotItems(),
                    refreshExperiment(),
                ]);
            } else {
                logger.info('[ExperimentAnalysisForm] Use can not edit experiment: setting analysis override');
                setPlotAnalysisOverride({ plotId: plot.uuid, analysisId: analysis.uuid });
                await Promise.all([
                    mutate(
                        Endpoints.lab.experiment.plot.data(
                            {
                                experimentId: experiment.uuid,
                                plotId: plot.uuid,
                            },
                            { analysis_id: analysis.uuid },
                        ),
                    ),
                    refreshPlotItems(),
                ]);
            }
        } catch (error) {
            logger.error(error);
            logger.error('ApiErrorMessage', ApiError.getMessage(error));
            helpers.setStatus({ error: ApiError.getMessage(error) });
        } finally {
            helpers.setSubmitting(false);
            setCurrentChanges(null);
            setNestedCurrentChanges(null);
        }
    };

    const handleOnChange = useCallback(
        (values) => {
            const isEqual = deepEqual(values, setup.initialValues);
            if (!isEqual) {
                const changedValues = getChangedValues(values, setup.initialValues);
                if (changedValues && !deepEqual(changedValues, currentChanges)) {
                    setCurrentChanges(changedValues);
                }
            } else if (currentChanges) {
                setTimeout(() => {
                    setCurrentChanges(null);
                }, 300);
            }
        },
        [currentChanges, setup.initialValues],
    );
    const getOnSubmitHandler = (analysisType: string) => {
        if (analysesWithCustomSubmit.includes(analysisType)) {
            const dryResult = onAnalysisSubmit(analysisType, true);
            if (!dryResult) {
                return undefined;
            }
            return () => onAnalysisSubmit(analysisType);
        }
        return undefined;
    };

    return (
        <Formik
            key={plot.uuid}
            initialValues={setup.initialValues}
            validationSchema={setup.schema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ status, errors, touched, values }) => {
                const errorMessages = Object.keys(errors)
                    .filter((name) => (name.includes('|') ? true : touched[name] && !isBlank(errors[name])))
                    .map((name) => errors[name]);
                return (
                    <>
                        <ScrollableSidebarContent data-cy="analysis-form" className="px-8">
                            {analysisInfo && (
                                <div>
                                    <AnalysisPipelineUpgradeBanner
                                        analysis={plot.analysis}
                                        className="mb-8"
                                        analysisInfo={analysisInfo}
                                    />
                                    <div className="mb-8">
                                        <span className="field-label">Analysis type</span>
                                        <div className="flex flex-row items-center">
                                            <AnalysisIcon
                                                shortname={analysisInfo.shortname}
                                                className={'mr-2 text-gray-400'}
                                                width={20}
                                                height={20}
                                            />
                                            <StatisticalSymbolLabel name={analysisInfo.display_name} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <AnalysisFormFields
                                plot={plot}
                                experiment={experiment}
                                analysisParameters={analysisParameters}
                            />
                        </ScrollableSidebarContent>
                        <ScrollableSidebarFooter>
                            {status && status.error && errorMessages.length === 0 && (
                                <p className="mb-4 break-words rounded-lg bg-error px-2 py-2 text-error">
                                    {status.error}
                                </p>
                            )}
                            <AggregateFormErrorAlert />
                            <AnalysisFormSubmitButton
                                text={
                                    plot.analysis?.category?.shortname === 'content' ||
                                    plot.analysis_type === 'external' ||
                                    getCategoryForAnalysis(plot.analysis_type) === 'content' ||
                                    plot.analysis?.analysis_form_type === 'analysis_input'
                                        ? 'Save'
                                        : 'Run analysis'
                                }
                                disabledWithoutSaving={analysisFormSubmitDisabled}
                                onSubmit={getOnSubmitHandler(plot.analysis_type)}
                            />
                        </ScrollableSidebarFooter>
                        <FormikListener values={values} callback={handleOnChange} />
                    </>
                );
            }}
        </Formik>
    );
};

export default AnalysisForm;
