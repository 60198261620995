import { createContext, ReactNode, useContext } from 'react';
import Experiment from '@models/Experiment';
import useCanvasNodes from '../hooks/useCanvasNodes';
import { Node } from 'reactflow';
import { StateSetter } from './ContextTypes';

export type ContextType = {
    canvasNodes: Node[];
    canvasNodesError: string | null;
    handleUpdateNode: (node: Node) => void;
    helperLineHorizontal: number | undefined;
    helperLineVertical: number | undefined;
    newNodeType: string | null;
    nodesLoading: boolean;
    onDragOver: (event: React.DragEvent) => void;
    onDrop: (event: React.DragEvent) => void;
    onNodeDrag: (_: React.MouseEvent, node: Node) => void;
    onNodeDragStop: (_: React.MouseEvent, node: Node) => void;
    onNodesChange: (changes: any) => void;
    postNewNode: (nodeData?: Node['data'], nodeType?: Node['type'], nodeStyle?: Node['style']) => void;
    selectedNode: Node | null;
    setCanvasNodes: StateSetter<Node[]>;
    setNewNodeType: StateSetter<string | null>;
    setSelectedNode: StateSetter<Node | null>;
};
const ExperimentCanvasContext = createContext<ContextType | null>(null);

export const useExperimentCanvasContext = () => {
    const context = useContext(ExperimentCanvasContext);
    if (!context) {
        throw new Error(
            'ExperimentCanvasContext has not been defined. Ensure you have wrapped your component in a context provider',
        );
    }
    return context;
};

ExperimentCanvasContext.displayName = 'ExperimentCanvasContext';

export const ExperimentCanvasContextProvider = ({
    children,
    experiment,
}: {
    children?: ReactNode;
    experiment?: Experiment | null;
}) => {
    const {
        canvasNodes,
        canvasNodesError,
        handleUpdateNode,
        helperLineHorizontal,
        helperLineVertical,
        newNodeType,
        nodesLoading,
        onDragOver,
        onDrop,
        onNodeDrag,
        onNodeDragStop,
        onNodesChange,
        postNewNode,
        selectedNode,
        setCanvasNodes,
        setNewNodeType,
        setSelectedNode,
    } = useCanvasNodes({ experiment_id: experiment?.uuid });

    return (
        <ExperimentCanvasContext.Provider
            value={{
                canvasNodes,
                canvasNodesError,
                handleUpdateNode,
                helperLineHorizontal,
                helperLineVertical,
                newNodeType,
                nodesLoading,
                onDragOver,
                onDrop,
                onNodeDrag,
                onNodeDragStop,
                onNodesChange,
                postNewNode,
                selectedNode,
                setCanvasNodes,
                setNewNodeType,
                setSelectedNode,
            }}
        >
            <>{children}</>
        </ExperimentCanvasContext.Provider>
    );
};
