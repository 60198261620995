import {
    Node,
    NodePositionChange,
    XYPosition,
    getNodePositionWithOrigin,
    rectToBox,
    NodeOrigin,
    Rect,
    boxToRect,
    Box,
    Position,
    MarkerType,
    Edge,
} from 'reactflow';

type GetHelperLinesResult = {
    horizontal?: number;
    vertical?: number;
    snapPosition: Partial<XYPosition>;
};

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
export function getHelperLines(change: NodePositionChange, nodes: Node[], distance = 5): GetHelperLinesResult {
    const defaultResult = {
        horizontal: undefined,
        vertical: undefined,
        snapPosition: { x: undefined, y: undefined },
    };
    const nodeA = nodes.find((node) => node.id === change.id);

    if (!nodeA || !change.position) {
        return defaultResult;
    }

    const nodeABounds = {
        left: change.position.x,
        right: change.position.x + (nodeA.width ?? 0),
        top: change.position.y,
        bottom: change.position.y + (nodeA.height ?? 0),
        width: nodeA.width ?? 0,
        height: nodeA.height ?? 0,
    };

    let horizontalDistance = distance;
    let verticalDistance = distance;

    return nodes
        .filter((node) => node.id !== nodeA.id)
        .reduce<GetHelperLinesResult>((result, nodeB) => {
            const nodeBBounds = {
                left: nodeB.position.x,
                right: nodeB.position.x + (nodeB.width ?? 0),
                top: nodeB.position.y,
                bottom: nodeB.position.y + (nodeB.height ?? 0),
                width: nodeB.width ?? 0,
                height: nodeB.height ?? 0,
            };

            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     A     |
            //  |___________|
            //  |
            //  |
            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     B     |
            //  |___________|
            const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

            if (distanceLeftLeft < verticalDistance) {
                result.snapPosition.x = nodeBBounds.left;
                result.vertical = nodeBBounds.left;
                verticalDistance = distanceLeftLeft;
            }

            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     A     |
            //  |___________|
            //              |
            //              |
            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     B     |
            //  |___________|
            const distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);

            if (distanceRightRight < verticalDistance) {
                result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
                result.vertical = nodeBBounds.right;
                verticalDistance = distanceRightRight;
            }

            //              |‾‾‾‾‾‾‾‾‾‾‾|
            //              |     A     |
            //              |___________|
            //              |
            //              |
            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     B     |
            //  |___________|
            const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

            if (distanceLeftRight < verticalDistance) {
                result.snapPosition.x = nodeBBounds.right;
                result.vertical = nodeBBounds.right;
                verticalDistance = distanceLeftRight;
            }

            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     A     |
            //  |___________|
            //              |
            //              |
            //              |‾‾‾‾‾‾‾‾‾‾‾|
            //              |     B     |
            //              |___________|
            const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

            if (distanceRightLeft < verticalDistance) {
                result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
                result.vertical = nodeBBounds.left;
                verticalDistance = distanceRightLeft;
            }

            //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
            //  |     A     |     |     B     |
            //  |___________|     |___________|
            const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

            if (distanceTopTop < horizontalDistance) {
                result.snapPosition.y = nodeBBounds.top;
                result.horizontal = nodeBBounds.top;
                horizontalDistance = distanceTopTop;
            }

            //  |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     A     |
            //  |___________|_________________
            //                    |           |
            //                    |     B     |
            //                    |___________|
            const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

            if (distanceBottomTop < horizontalDistance) {
                result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
                result.horizontal = nodeBBounds.top;
                horizontalDistance = distanceBottomTop;
            }

            //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
            //  |     A     |     |     B     |
            //  |___________|_____|___________|
            const distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);

            if (distanceBottomBottom < horizontalDistance) {
                result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
                result.horizontal = nodeBBounds.bottom;
                horizontalDistance = distanceBottomBottom;
            }

            //                    |‾‾‾‾‾‾‾‾‾‾‾|
            //                    |     B     |
            //                    |           |
            //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
            //  |     A     |
            //  |___________|
            const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

            if (distanceTopBottom < horizontalDistance) {
                result.snapPosition.y = nodeBBounds.bottom;
                result.horizontal = nodeBBounds.bottom;
                horizontalDistance = distanceTopBottom;
            }

            return result;
        }, defaultResult);
}

export const sortNodes = (a: Node, b: Node): number => {
    if (a.type === b.type) {
        return 0;
    }
    return a.type === 'group' && b.type !== 'group' ? -1 : 1;
};

export const getId = (prefix = 'node') => `${prefix}_${Math.random() * 10000}`;

export const getNodePositionInsideParent = (node: Partial<Node>, groupNode: Node) => {
    const position = node.position ?? { x: 0, y: 0 };
    const nodeWidth = node.width ?? 0;
    const nodeHeight = node.height ?? 0;
    const groupWidth = groupNode.width ?? 0;
    const groupHeight = groupNode.height ?? 0;

    if (position.x < groupNode.position.x) {
        position.x = 0;
    } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
        position.x = groupWidth - nodeWidth;
    } else {
        position.x = position.x - groupNode.position.x;
    }

    if (position.y < groupNode.position.y) {
        position.y = 0;
    } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
        position.y = groupHeight - nodeHeight;
    } else {
        position.y = position.y - groupNode.position.y;
    }

    return position;
};

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
    x: Math.min(box1.x, box2.x),
    y: Math.min(box1.y, box2.y),
    x2: Math.max(box1.x2, box2.x2),
    y2: Math.max(box1.y2, box2.y2),
});

export const getRelativeNodesBounds = (nodes: Node[], nodeOrigin: NodeOrigin = [0, 0]): Rect => {
    if (nodes.length === 0) {
        return { x: 0, y: 0, width: 0, height: 0 };
    }

    const box = nodes.reduce(
        (currBox, node) => {
            const { x, y } = getNodePositionWithOrigin(node, nodeOrigin);
            return getBoundsOfBoxes(
                currBox,
                rectToBox({
                    x,
                    y,
                    width: node.width || 0,
                    height: node.height || 0,
                }),
            );
        },
        { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity },
    );

    return boxToRect(box);
};

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
    // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
    const {
        width: intersectionNodeWidth,
        height: intersectionNodeHeight,
        positionAbsolute: intersectionNodePosition,
    } = intersectionNode;
    const targetPosition = targetNode.positionAbsolute;

    const w = intersectionNodeWidth / 2;
    const h = intersectionNodeHeight / 2;

    const x2 = intersectionNodePosition.x + w;
    const y2 = intersectionNodePosition.y + h;
    const x1 = targetPosition.x + targetNode.width / 2;
    const y1 = targetPosition.y + targetNode.height / 2;

    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;

    return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
    const n = { ...node.positionAbsolute, ...node };
    const nx = Math.round(n.x);
    const ny = Math.round(n.y);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);

    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + n.width - 1) {
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= n.y + n.height - 1) {
        return Position.Bottom;
    }

    return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);

    const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
    const targetPos = getEdgePosition(target, targetIntersectionPoint);

    return {
        sx: sourceIntersectionPoint.x,
        sy: sourceIntersectionPoint.y,
        tx: targetIntersectionPoint.x,
        ty: targetIntersectionPoint.y,
        sourcePos,
        targetPos,
    };
}

export function createNodesAndEdges() {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

    nodes.push({ id: 'target', data: { label: 'Target' }, position: center });

    for (let i = 0; i < 8; i++) {
        const degrees = i * (360 / 8);
        const radians = degrees * (Math.PI / 180);
        const x = 250 * Math.cos(radians) + center.x;
        const y = 250 * Math.sin(radians) + center.y;

        nodes.push({ id: `${i}`, data: { label: 'Source' }, position: { x, y } });

        edges.push({
            id: `edge-${i}`,
            target: 'target',
            source: `${i}`,
            type: 'floating',
            markerEnd: {
                type: MarkerType.Arrow,
            },
        });
    }

    return { nodes, edges };
}
