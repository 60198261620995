import React from 'react';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import Plot from '@models/Plot';
import { useFormikContext } from 'formik';
import FullWidthToggleField from '../fields/FullWidthToggleField';
import ThemeFieldGroup from '../groups/ThemeFieldGroup';
import NetworkGraphDisplayOption from '@/src/models/plotDisplayOption/NetworkGraphDisplayOption';
import { CircularProgress, Switch } from '@mui/material';
import LegendFieldGroup from '../groups/LegendFieldGroup';
import Button from '@/src/components/Button';
import { CSSTransition } from 'react-transition-group';

export const NetworkEdgeOptions = [
    {
        id: 'combined',
        display_name: 'Combined',
    },
    {
        id: 'gene_neighborhood',
        display_name: 'Gene neighborhood',
    },
    {
        id: 'gene_fusion',
        display_name: 'Gene fusion',
    },
    {
        id: 'phylogenetic_profile',
        display_name: 'Phylogenetic profile',
    },
    {
        id: 'coexpression',
        display_name: 'Coexpression',
    },
    {
        id: 'experimental',
        display_name: 'Experimental',
    },
    {
        id: 'database',
        display_name: 'Database',
    },
    {
        id: 'textmining',
        display_name: 'Textmining',
    },
];

type Props = { plot: Plot; handleSaveCurrentView: () => void; savingCurrentView: boolean; showSaveSuccess?: boolean };
const NetworkGraphDisplayFields = ({ plot, handleSaveCurrentView, savingCurrentView, showSaveSuccess }: Props) => {
    const { values, setFieldValue } = useFormikContext<NetworkGraphDisplayOption>();

    const loading = savingCurrentView;

    const onEdgeTypeSelected = (name: string) => {
        if (!values.edge_types.includes(name)) {
            setFieldValue('edge_types', [...values.edge_types, name]);
        } else {
            setFieldValue(
                'edge_types',
                values.edge_types.filter((t) => t !== name),
            );
        }
    };

    return (
        <div className="space-y-6">
            <div>
                <Button
                    onClick={() => handleSaveCurrentView()}
                    color="primary"
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress variant="indeterminate" color="primary" size={24} />
                    ) : (
                        'Save current view'
                    )}
                </Button>
                <CSSTransition in={showSaveSuccess} timeout={300} classNames="fade" unmountOnExit>
                    <p className="center flex flex-1 py-2 text-center font-semibold text-success">
                        Current view saved!
                    </p>
                </CSSTransition>
            </div>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <section>
                <h4 className="tet-dark mb-2 text-lg font-semibold tracking-tight">Network edge types</h4>
                <div className="flex flex-col space-y-4">
                    {NetworkEdgeOptions.map((edgeOption) => (
                        <label className="inline-flex cursor-pointer items-center pl-1" key={edgeOption.id}>
                            <input
                                type="checkbox"
                                className="rounded text-indigo-500"
                                checked={values.edge_types.includes(edgeOption.id) ?? false}
                                onChange={() => onEdgeTypeSelected(edgeOption.id)}
                            />
                            <span className="ml-2 text-dark">{edgeOption.display_name}</span>
                        </label>
                    ))}
                </div>
            </section>
            <section>
                <h4 className="tet-dark text-lg font-semibold tracking-tight">Network edge weights</h4>
                <div className="w-full">
                    <div className="flex w-full items-center justify-between space-x-2">
                        <p className="font-semibold tracking-tight">Use weighted edges</p>
                        <Switch
                            checked={values.use_weighted_edges}
                            onClick={() => setFieldValue('use_weighted_edges', !values.use_weighted_edges)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
            </section>
            <section>
                <h4 className="tet-dark text-lg font-semibold tracking-tight">Protein Labels</h4>
                <div className="w-full">
                    <div className="flex w-full items-center justify-between space-x-2">
                        <p className="font-semibold tracking-tight">Hide labels</p>
                        <Switch
                            checked={values.hide_labels}
                            onClick={() => setFieldValue('hide_labels', !values.hide_labels)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
            </section>
            {/* <MaxNodesToShowField /> */}

            <ThemeFieldGroup hideStyle />
            <LegendFieldGroup
                isSortable
                description="Click and drag the legend items to reorder."
                items={NetworkEdgeOptions ?? []}
                isEditable={false}
            />
        </div>
    );
};

export default NetworkGraphDisplayFields;
