export type PlotDisplayShortname =
    | 'bar_plot'
    | 'box_plot'
    | 'dot_plot'
    | 'enrichment_plot'
    | 'external'
    | 'heatmap'
    | 'html'
    | 'igv_plot'
    | 'image_heatmap'
    | 'image'
    | 'kaplan_meier_curve'
    | 'line_plot'
    | 'network_graph'
    | 'pie_chart'
    | 'prism_graphset'
    | 'profile_plot'
    | 'sample_scatter_plot'
    | 'score_bar_plot'
    | 'spreadsheet'
    | 'stacked_bar_plot'
    | 'text'
    | 'tornado_plot'
    | 'venn_diagram'
    | 'volcano_plot_v2'
    | 'volcano_plot';
export type VisualizationPlotDisplayShortname =
    | 'bar_plot'
    | 'clustering_analysis'
    | 'dot_plot'
    | 'enrichment_plot'
    | 'external_analysis'
    | 'heat_map'
    | 'igv_plot'
    | 'image'
    | 'kaplan-meier_curve'
    | 'line_plot'
    | 'pie_chart'
    | 'prism_graphset'
    | 'profile_plot'
    | 'scatter_plot'
    | 'spreadsheet'
    | 'stacked_bar_plot'
    | 'top_pathways_plot'
    | 'tornado_plot'
    | 'tss_plot'
    | 'venn_diagram'
    | 'volcano_plot';

export default interface PlotDisplayType {
    shortname: PlotDisplayShortname;
    display_name: string;
    is_enabled: boolean;
}

export type ServerImageDisplayType = Extract<PlotDisplayShortname, ['image_heatmap', 'tornado_plot', 'profile_plot']>;
export const serverImageDisplayTypes: PlotDisplayShortname[] = ['image_heatmap', 'tornado_plot', 'profile_plot'];

export function isServerImageDisplayType(
    display_type?: PlotDisplayShortname | null,
): display_type is ServerImageDisplayType {
    if (!display_type) {
        return false;
    }
    return serverImageDisplayTypes.includes(display_type);
}
