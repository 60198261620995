import { useState } from 'react';
import EditPlotView from '@components/experiments/EditPlotView';
import Experiment from '@models/Experiment';
import PlotCardView from '@components/plots/PlotCardView';
import Plot from '@models/Plot';
import { LightBulbIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import ExperimentDetailTabsView, { TabViewName } from '@components/experiments/ExperimentDetailTabsView';
import { ButtonGroupItem } from '@components/ButtonGroup';
import CommentsIcon from '@components/icons/custom/CommentsIcon';
import { ScrollableSidebarContainer } from '@components/experiments/ScrollableSidebarContent';
import PlotCommentsView from '@components/experiments/comments/PlotCommentsView';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import { EditIcon } from '@components/icons/custom/EditIcon';
import PlutoAIPlotView from '@components/experiments/plutoAI/PlutoAIPlotView';

export type Props = {
    experiment: Experiment;
    openOnComments?: boolean;
    openOnPlot?: boolean;
    plot: Plot;
};

const LEGEND_RIGHT_PLOT_TYPES = ['stacked_bar_plot', 'pie_chart', 'network_graph'];

const EditPlotModalView = ({ plot, experiment, openOnComments, openOnPlot }: Props) => {
    const [formExpanded, setFormExpanded] = useState(openOnComments || openOnPlot ? false : true);
    const [showPlutoAI, setShowPlutoAI] = useState(false);
    const [commentsExpanded, setCommentsExpanded] = useState(!!openOnComments);
    const toggleFormExpanded = () => setFormExpanded(!formExpanded);
    const toggleCommentsExpanded = () => setCommentsExpanded(!commentsExpanded);
    const { features } = useOrganizationPermissions();

    const handleChangeCommentsExpanded = () => {
        if (commentsExpanded && showPlutoAI) return setShowPlutoAI(false);
        if (formExpanded) toggleFormExpanded();
        setShowPlutoAI(false);
        toggleCommentsExpanded();
    };

    const handleChangeAIExpanded = () => {
        if (commentsExpanded && !showPlutoAI) return setShowPlutoAI(true);
        if (formExpanded) toggleFormExpanded();
        setShowPlutoAI(true);
        toggleCommentsExpanded();
    };

    const handleChangeFormExpanded = () => {
        if (!formExpanded && commentsExpanded) {
            toggleCommentsExpanded();
        }
        toggleFormExpanded();
    };

    const tabs: ButtonGroupItem<TabViewName>[] = [
        { value: 'sample', label: 'Samples' },
        { value: 'results_table', label: 'Results' },
        { value: 'methods', label: 'Methods ' },
    ];

    const commentsEnabled = !!features?.experiment_features.comments_enabled;
    const aiEnabled = !!features?.experiment_features.ai_assistant_enabled;

    return (
        <>
            <EditPlotView
                plotId={plot.uuid}
                experiment={experiment}
                className={cn('h-full w-[400px] border-r-2 border-r-gray-200 bg-white transition-all duration-700', {
                    '-translate-x-[370px]': !formExpanded,
                    'delay-0': formExpanded,
                })}
            />
            <div
                className={cn(
                    'absolute left-[385px] top-1/2 z-20 cursor-pointer rounded-full border-2 border-gray-200 bg-white p-1 transition-all duration-700',
                    {
                        '-translate-x-[370px]': !formExpanded,
                        'delay-0': formExpanded,
                    },
                )}
                onClick={handleChangeFormExpanded}
            >
                <EditIcon height={20} width={20} className="text-indigo-500" />
            </div>
            <div
                className={cn('flex-end absolute h-full !overflow-auto transition-all duration-700', {
                    'w-[calc(100%-60px)] translate-x-[30px]': !formExpanded && !commentsExpanded,
                    'w-[calc(100%-430px)] translate-x-[400px]': formExpanded,
                    'w-[calc(100%-430px)] translate-x-[30px]': commentsExpanded,
                    'w-[calc(100%-630px)] translate-x-[30px]': commentsExpanded && showPlutoAI,
                })}
            >
                <div className="items center flex h-[85%] justify-center p-8">
                    <div className="flex w-3/4 flex-row">
                        <PlotCardView
                            plot={plot}
                            experiment={experiment}
                            showHeader={false}
                            cursorClassName="auto"
                            hidePlotShellButtons
                            legendPosition={
                                LEGEND_RIGHT_PLOT_TYPES.includes(plot?.display?.display_type) ? 'right' : undefined
                            }
                        />
                    </div>
                </div>

                <div className="mb-4 flex w-full flex-col px-8">
                    <ExperimentDetailTabsView experiment={experiment} selectedPlot={plot} tabs={tabs} />
                </div>
            </div>
            {commentsEnabled || aiEnabled ? (
                <>
                    <ScrollableSidebarContainer
                        className={cn(
                            'absolute h-full w-[400px] self-end border-l-2 border-l-gray-200 bg-white transition-all duration-700',
                            {
                                'translate-x-[370px]': !commentsExpanded,
                                'delay-0': commentsExpanded,
                                'w-[600px]': commentsExpanded && showPlutoAI,
                            },
                        )}
                    >
                        {showPlutoAI ? <PlutoAIPlotView plot={plot} /> : <PlotCommentsView plot={plot} />}
                    </ScrollableSidebarContainer>
                    {commentsEnabled ? (
                        <div
                            className={cn(
                                'absolute right-[15px] top-[50%] z-20 cursor-pointer rounded-full border-2 border-gray-200 p-1 transition-all duration-700',
                                {
                                    '-translate-x-[370px]': commentsExpanded && !showPlutoAI,
                                    '-translate-x-[570px]': commentsExpanded && !!showPlutoAI,
                                    'delay-0': !commentsExpanded,
                                    'bg-indigo-500': !!showPlutoAI && commentsExpanded,
                                    'bg-slate-100': !showPlutoAI && commentsExpanded && aiEnabled,
                                    'bg-white': !commentsExpanded || !aiEnabled,
                                },
                            )}
                            onClick={handleChangeCommentsExpanded}
                        >
                            <CommentsIcon
                                height={20}
                                width={20}
                                className={cn('text-indigo-500', { 'text-white': !!showPlutoAI && commentsExpanded })}
                            />
                        </div>
                    ) : null}
                    {aiEnabled ? (
                        <div
                            className={cn(
                                'absolute right-[15px] top-[40%] z-20 cursor-pointer rounded-full border-2 border-gray-200 p-1 transition-all duration-700',
                                {
                                    '-translate-x-[370px]': commentsExpanded && !showPlutoAI,
                                    '-translate-x-[570px]': commentsExpanded && !!showPlutoAI,
                                    'delay-0': !commentsExpanded,
                                    'bg-indigo-500': !showPlutoAI && commentsExpanded,
                                    'bg-slate-100': !!showPlutoAI && commentsExpanded && commentsEnabled,
                                    'bg-white': !commentsExpanded || !commentsEnabled,
                                    'top-[50%]': !commentsEnabled,
                                },
                            )}
                            onClick={handleChangeAIExpanded}
                        >
                            <LightBulbIcon
                                height={20}
                                width={20}
                                className={cn('text-indigo-500', { 'text-white': !showPlutoAI && commentsExpanded })}
                            />
                        </div>
                    ) : null}
                </>
            ) : null}
        </>
    );
};

export default EditPlotModalView;
