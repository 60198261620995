import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { humanFileSize } from '@util/StringUtil';
import { getFullName } from '@models/User';
import { Tooltip } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Button from '@components/Button';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import { CrudPermissions } from '@models/Permission';
import Logger from '@util/Logger';
import FileTypeIcon from '@components/FileTypeIcon';
import { DownloadIcon } from '@heroicons/react/outline';
import PlutoFile from '@models/PlutoFile';
import useExperimentFiles from '@/src/hooks/useExperimentFiles';
import Experiment from '@models/Experiment';
import { TruncatedText } from './history/items/TruncatedText';

const logger = Logger.make('AttachmentItemView');

export type Props = { item: PlutoFile; experiment: Experiment } & CrudPermissions;

export const AttachmentItemView = ({ item, canDownload, canArchive, experiment }: Props) => {
    const [isArchived, setArchived] = useState(!!item.archived_at);
    const [showConfirm, setShowConfirm] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { fetcher } = useApi();
    const { refreshFiles, downloadFile } = useExperimentFiles({ experiment, data_type: 'attachment' });
    const experimentId = experiment.uuid;

    const handleArchive = async () => {
        if (!canArchive) {
            setError('You do not have permission to archive this attachment.');
            return;
        }
        if (!showConfirm) {
            setShowConfirm(true);
            return;
        }

        setError(null);
        const fileId = item.uuid;
        if (!experimentId) {
            setError('No experiment ID was provided, can not remove attachment.');
            return;
        }

        try {
            await fetcher(
                Endpoints.lab.experiment.fileArchive({
                    experimentId,
                    fileId,
                }),
                { method: 'POST' },
            );
            await refreshFiles();
            setArchived(true);
        } catch (error) {
            logger.error(error);
            setError('Unable to archive this attachment.');
        }
    };

    return (
        <li
            className={cn('flex items-center justify-between py-3 pl-3 pr-4 text-sm text-indigo-500', {
                'bg-error': showConfirm,
                'odd:bg-indigo-50': !showConfirm,
            })}
        >
            <div className="flex w-0 flex-1 items-start">
                <FileTypeIcon fileType={item.file_type} className="mt-1" />
                <div className="hyphenate ml-2 flex flex-1 flex-col">
                    <TruncatedText max={70} text={item.filename} className="text-md font-semibold" />
                    <div className="space-x-1">
                        <span className="text-sm">{new Date(item.created_at ?? '').toLocaleString()}</span>
                        <span>&bull;</span>
                        <span>{humanFileSize(item.file_size, { dp: 0 })}</span>
                    </div>
                </div>
            </div>
            {showConfirm && (
                <>
                    <span className="text-base text-dark">Delete Attachment?</span>
                    <div className="text-error">
                        <Button onClick={handleArchive} size="small" variant="text" color="inherit">
                            Delete
                        </Button>
                        <Button onClick={() => setShowConfirm(false)} variant="contained" color="primary" size="small">
                            Cancel
                        </Button>
                    </div>
                </>
            )}
            {!showConfirm && (
                <div className="ml-4 flex shrink-0 flex-col">
                    <div className="flex items-center justify-center space-x-4">
                        <span>{getFullName(item.created_by)}</span>
                        {canDownload ? (
                            <a
                                className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-800"
                                rel="noreferrer nofollow"
                                target="_blank"
                                onClick={() => downloadFile(item, experiment.uuid)}
                            >
                                <DownloadIcon className="h-5 w-5 shrink-0" />
                            </a>
                        ) : null}
                        {error && <span className="text-error">{error}</span>}
                        {!isArchived && canArchive && (
                            <span
                                className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-800"
                                onClick={handleArchive}
                            >
                                <Tooltip title="Delete" arrow placement="top">
                                    <DeleteOutlineRoundedIcon fontSize="small" color="error" />
                                </Tooltip>
                            </span>
                        )}
                    </div>
                </div>
            )}
        </li>
    );
};

export default AttachmentItemView;
