import { PlotDisplayShortname } from '@models/PlotDisplayType';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import React from 'react';
import VolcanoPlotMethods from '@components/experiments/methods/displays/VolcanoPlotMethods';
import BarPlotMethods from '@components/experiments/methods/displays/BarPlotMethods';
import BoxPlotMethods from '@components/experiments/methods/displays/BoxPlotMethods';
import PrismGraphsetMethods from '@/src/components/experiments/methods/displays/PrismGraphsetMethods';
import { isBlank } from '@util/StringUtil';
import SampleScatterPlotMethods from '@components/experiments/methods/displays/SampleScatterPlotMethods';
import EnrichmentPlotMethods from '@components/experiments/methods/displays/EnrichmentPlotMethods';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import SummaryHeatmapMethods from '@components/experiments/methods/displays/SummaryHeatmapMethods';
import KaplanMeierCurveMethods from '@components/experiments/methods/displays/KaplanMeierCurveMethods';
import ScoreBarPlotMethods from '@components/experiments/methods/displays/ScoreBarPlotMethods';
import IGVMethods from '@components/experiments/methods/displays/IGVMethods';
import ComparativeHeatmapMethods from '@components/experiments/methods/displays/ComparativeHeatmapMethods';
import LinePlotMethods from '@components/experiments/methods/displays/LinePlotMethods';
import TextPlotMethods from '@components/experiments/methods/displays/TextPlotMethods';
import ImageHeatmapMethods from '@components/experiments/methods/ImageHeatmapMethods';
import TornadoPlotMethods from '@components/experiments/methods/displays/TornadoPlotMethods';
import ProfilePlotMethods from '@components/experiments/methods/displays/ProfilePlotMethods';
import ExternalPlotMethods from '@components/experiments/methods/displays/ExternalPlotMethods';
import useAnalysisParameters from '@/src/hooks/useAnalysisParameters';
import DotPlotMethods from './displays/DotPlotMethods';
import PieChartMethods from './displays/PieChartMethods';
import StackedBarPlotMethods from './displays/StackedBarPlotMethods';
import NetworkGraphMethods from './displays/NetworkGraphMethods';
import SpreadsheetPlotMethods from './displays/SpreadsheetPlotMethods';
import VennDiagramMethods from './displays/VennDiagramMethods';

type Props = {
    plotType?: PlotDisplayShortname | null;
    plot: Plot;
    experiment: Experiment;
};

/**
 * Displays information on the methods used to generate a plot.
 * @param {"volcano_plot" | "box_plot" | "bar_plot" | "heatmap" | null} plotType
 * @param {Plot} plot
 * @param {Experiment} experiment
 * @return {JSX.Element}
 * @constructor
 */
export const MethodsPlotContent = ({ plot, experiment }: Props) => {
    const { analysisParameters } = useAnalysisParameters({ plot: plot, experiment });
    const plotType = plot?.display.display_type;

    switch (plotType) {
        case 'volcano_plot':
        case 'volcano_plot_v2':
            return <VolcanoPlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'box_plot':
            return <BoxPlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'bar_plot':
            return <BarPlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'dot_plot':
            return <DotPlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'image':
            const imageMethods = (plot.analysis as ImageAnalysis)?.methods;
            return (
                <div className="whitespace-pre-line">
                    {isBlank(imageMethods) ? 'No methods have been provided.' : imageMethods}
                </div>
            );
        case 'prism_graphset':
            return <PrismGraphsetMethods plot={plot} />;
        case 'external':
            return <ExternalPlotMethods plot={plot} />;
        case 'sample_scatter_plot':
            return (
                <SampleScatterPlotMethods
                    experiment={experiment}
                    plot={plot}
                    analysisParameters={analysisParameters || null}
                />
            );
        case 'enrichment_plot':
            return (
                <EnrichmentPlotMethods
                    plot={plot}
                    experiment={experiment}
                    analysisParameters={analysisParameters || null}
                />
            );
        case 'heatmap':
            switch (plot.analysis?.category.shortname) {
                case 'summary':
                    return <SummaryHeatmapMethods plot={plot} analysisParameters={analysisParameters || null} />;
                case 'comparative':
                    return <ComparativeHeatmapMethods plot={plot} analysisParameters={analysisParameters || null} />;
                default:
                    return <p>Looks like we&apos;re unable to find any methods right now. Please try again later.</p>;
            }

        case 'kaplan_meier_curve':
            return (
                <KaplanMeierCurveMethods
                    experiment={experiment}
                    plot={plot}
                    analysisParameters={analysisParameters || null}
                />
            );
        case 'score_bar_plot':
            return (
                <ScoreBarPlotMethods
                    experiment={experiment}
                    plot={plot}
                    analysisParameters={analysisParameters || null}
                />
            );
        case 'igv_plot':
            return <IGVMethods experiment={experiment} plot={plot} analysisParameters={analysisParameters || null} />;
        case 'line_plot':
            return <LinePlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'text':
            return <TextPlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'image_heatmap':
            return (
                <ImageHeatmapMethods
                    plot={plot}
                    experiment={experiment}
                    analysisParameters={analysisParameters || null}
                />
            );
        case 'tornado_plot':
            return <TornadoPlotMethods plot={plot} experiment={experiment} />;
        case 'profile_plot':
            return <ProfilePlotMethods plot={plot} experiment={experiment} />;
        case 'network_graph':
            return <NetworkGraphMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'pie_chart':
            return <PieChartMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'stacked_bar_plot':
            return <StackedBarPlotMethods plot={plot} analysisParameters={analysisParameters || null} />;
        case 'spreadsheet':
            return <SpreadsheetPlotMethods plot={plot} />;
        case 'venn_diagram':
            return <VennDiagramMethods plot={plot} analysisParameters={analysisParameters || null} />;
        default:
            return <p>Looks like we&apos;re unable to find any methods right now. Please try again later.</p>;
    }
};
