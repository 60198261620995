import Plot from '@models/Plot';
import { EnrichmentColorValue } from '@models/ExperimentData';
import { getEnrichmentColorValue } from '@components/analysisCategories/pathway/plots/EnrichmentPlotUtils';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import cn from 'classnames';
import { usePlotContext } from '@contexts/PlotContext';

const Dot = ({ colorValue }: { colorValue: EnrichmentColorValue }) => {
    const color = getEnrichmentColorValue(colorValue);
    return (
        <div>
            <span className="mt-1 block h-5 w-5 rounded-md" style={{ background: `${color}` }} />
        </div>
    );
};

type Props = { plot: Plot | null; className?: string };
const EnrichmentPlotLegend = ({ plot, className }: Props) => {
    const analysis = plot?.analysis as GeneSetEnrichmentAnalysis | null;
    const { publicationMode } = usePlotContext();
    const controlGene =
        analysis?.differential_analysis?.control_group_name || analysis?.differential_analysis?.group1_name;
    const experimentalGene =
        analysis?.differential_analysis?.experimental_group_name || analysis?.differential_analysis?.group2_name;

    if (!controlGene || !experimentalGene) {
        return null;
    }

    return (
        <div
            className={cn('mt-4 flex justify-between space-x-20 px-4', className, {
                'font-sans text-[14px] text-black': publicationMode,
            })}
        >
            <div className="text-md flex space-x-2">
                <Dot colorValue={8} />
                <span>Genes more highly expressed in {experimentalGene}</span>
            </div>
            <div className="text-md flex space-x-2 text-right">
                <span>Genes more highly expressed in {controlGene}</span>
                <Dot colorValue={1} />
            </div>
        </div>
    );
};

export default EnrichmentPlotLegend;
