import { useState } from 'react';
import EditPreprocessForm from '@/src/components/experiments/preprocesses/EditPreprocessForm';
import Experiment from '@models/Experiment';
import cn from 'classnames';
import PreprocessDetailTabsView from './PreprocessDetailTabsView';
import PreprocessPlotCardView from './PreprocessPlotCardView';
import { EditIcon } from '../../icons/custom/EditIcon';
import { ScrollableSidebarContainer } from '@components/experiments/ScrollableSidebarContent';
import CommentsIcon from '@components/icons/custom/CommentsIcon';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import useComments from '@hooks/useComments';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import PreprocessCommentsView from '../comments/PreprocessCommentsView';

export type Props = {
    experiment: Experiment;
};

const PreprocessFormModalView = ({ experiment }: Props) => {
    const [formExpanded, setFormExpanded] = useState(true);
    const [commentsExpanded, setCommentsExpanded] = useState(false);
    const toggleFormExpanded = () => setFormExpanded(!formExpanded);
    const toggleCommentsExpanded = () => setCommentsExpanded(!commentsExpanded);
    const { features } = useOrganizationPermissions();
    const { selectedPreprocess } = useExperimentWorkflowContext();
    const { commentCount } = useComments({
        object_type: selectedPreprocess?.preprocess_type.object_type,
        object_uuid: selectedPreprocess?.uuid,
    });

    const handleChangeCommentsExpanded = () => {
        if (!commentsExpanded && formExpanded) {
            toggleFormExpanded();
        }
        toggleCommentsExpanded();
    };

    const handleChangeFormExpanded = () => {
        if (!formExpanded && commentsExpanded) {
            toggleCommentsExpanded();
        }
        toggleFormExpanded();
    };

    return (
        <>
            <EditPreprocessForm
                className={cn('h-full w-[400px] border-r-2 border-r-gray-200 bg-white transition-all duration-700', {
                    '-translate-x-[370px]': !formExpanded,
                    'delay-0': formExpanded,
                })}
            />
            <div
                className={cn(
                    'absolute left-[385px] top-1/2 z-20 cursor-pointer rounded-full border-2 border-gray-200 bg-white p-1 transition-all duration-700',
                    {
                        '-translate-x-[370px]': !formExpanded,
                        'delay-0': formExpanded,
                    },
                )}
                onClick={handleChangeFormExpanded}
            >
                <EditIcon height={20} width={20} className="text-indigo-500" />
            </div>
            <div
                className={cn('absolute h-full !overflow-scroll transition-all duration-700', {
                    'w-[calc(100%-60px)] translate-x-[30px]': !formExpanded && !commentsExpanded,
                    'w-[calc(100%-430px)] translate-x-[400px]': formExpanded,
                    'w-[calc(100%-430px)] translate-x-[30px]': commentsExpanded,
                })}
            >
                <div className="items center flex h-[80%] justify-center p-8">
                    <div className="flex w-full flex-row">
                        <PreprocessPlotCardView />
                    </div>
                </div>
                <div className="mb-8 flex w-full flex-col px-8">
                    <PreprocessDetailTabsView experiment={experiment} />
                </div>
            </div>
            {!!features?.experiment_features.comments_enabled && selectedPreprocess?.uuid ? (
                <>
                    <ScrollableSidebarContainer
                        className={cn(
                            'absolute h-full w-[400px] self-end border-l-2 border-l-gray-200 bg-white transition duration-700',
                            {
                                'translate-x-[370px]': !commentsExpanded,
                                'delay-0': commentsExpanded,
                            },
                        )}
                    >
                        <PreprocessCommentsView preprocess={selectedPreprocess} />
                    </ScrollableSidebarContainer>
                    <div
                        className={cn(
                            'absolute right-[15px] top-1/2 z-20 cursor-pointer rounded-full border-2 border-gray-200 p-1 transition duration-700',
                            {
                                '-translate-x-[370px]': commentsExpanded,
                                'delay-0': !commentsExpanded,
                                'bg-indigo-500': !!commentCount,
                                'bg-white': !commentCount,
                            },
                        )}
                        onClick={handleChangeCommentsExpanded}
                    >
                        <CommentsIcon
                            height={20}
                            width={20}
                            className={cn('text-indigo-500', { 'text-white': !!commentCount })}
                        />
                    </div>
                </>
            ) : null}
        </>
    );
};

export default PreprocessFormModalView;
