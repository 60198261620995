import React, { ReactNode, ReactChild } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useField } from 'formik';
import { FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import FieldError from '@components/forms/FieldError';
import { blankToNull, isBlank, isNotBlank } from '@util/StringUtil';
import { isDefined } from '@util/TypeGuards';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

export type ChoiceItem = { label?: ReactNode; value: string | number };

type Props = {
    disabled?: boolean;
    items: ChoiceItem[];
    label: ReactNode;
    labelClassName?: string;
    loading?: boolean;
    name: string;
    onChange?: (event: SelectChangeEvent<any>, child?: ReactNode) => void;
    placeholder?: string;
    tooltip?: { title: NonNullable<ReactNode>; href?: string | null; icon?: ReactChild | null } | null;
    value?: string | number;
};
const SimpleSelectField = ({
    disabled,
    items,
    label,
    labelClassName,
    loading = false,
    name,
    onChange,
    placeholder,
    tooltip,
    value,
}: Props) => {
    const [inputProps, meta] = useField(name);
    const touched = meta.touched;
    const error = touched ? meta.error : undefined;

    const fieldValue = isBlank(placeholder)
        ? value ?? inputProps.value
        : blankToNull(value ?? inputProps.value) ?? '__none__';

    let $tooltip: ReactNode | null = null;
    if (isDefined(tooltip)) {
        const $icon = tooltip.icon ?? <QuestionMarkCircleIcon className="h-4 w-4" />;
        const $child = isNotBlank(tooltip.href) ? (
            <a href={tooltip.href} target="_blank" rel="nofollow noreferrer">
                {$icon}
            </a>
        ) : (
            $icon
        );
        $tooltip = (
            <Tooltip title={tooltip.title} placement="right" arrow>
                <span>{$child}</span>
            </Tooltip>
        );
    }

    return (
        <div className="form-field no-margin">
            <span className="field-label flex items-center space-x-2">
                <span className={labelClassName}>{label}</span>
                {$tooltip}
            </span>
            <FormControl variant="outlined" fullWidth error={!!error}>
                <Select
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    {...inputProps}
                    onChange={onChange ?? inputProps.onChange}
                    value={fieldValue}
                    renderValue={(value) => {
                        if (value === '__none__') {
                            return <span className="text-default text-opacity-50">{placeholder}</span>;
                        }
                        const selection = items.find((item) => `${item.value}` === `${value}`);
                        return selection?.label ?? selection?.value;
                    }}
                    disabled={disabled}
                >
                    {loading && (
                        <MenuItem disabled value="loading">
                            Loading...
                        </MenuItem>
                    )}
                    {!loading && placeholder && isBlank(inputProps.value) && (
                        <MenuItem value="__none__" disabled className="!hidden" hidden>
                            <span className="">{placeholder}</span>
                        </MenuItem>
                    )}
                    {!loading &&
                        items?.map((option) => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    <div className="flex flex-row items-center justify-start">
                                        {option.label ?? option.value}
                                    </div>
                                </MenuItem>
                            );
                        })}
                </Select>
                {error && <FieldError>{error}</FieldError>}
            </FormControl>
        </div>
    );
};

export default SimpleSelectField;
