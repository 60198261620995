import { OrganismID } from '@models/Organism';
import { blankToUndefined, geneQueryParam, isBlank, isNotBlank } from '@util/StringUtil';
import LoadingMessage from '@components/LoadingMessage';
import useSWR from 'swr';
import { IconButton, Tooltip } from '@mui/material';
import Link from 'next/link';
import { ExternalLinkIcon, XIcon } from '@heroicons/react/outline';
import TruncatedDescription from '@components/TruncatedDescription';
import { isDefined } from '@util/TypeGuards';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';
import { useEffect, useState } from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { BiomarkerSearchItem, BiomarkerSearchResponse } from '@models/Biomarker';
import Logger from '@util/Logger';

const fetcher = async (url) => (await fetch(url)).json();
const logger = Logger.make('PlutoErrorBoundary');

export const getEnsemblCardUrl = ({ gene, organism }: { gene: string; organism?: OrganismID }): string | null => {
    switch (organism) {
        case OrganismID.human:
            return `https://useast.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=${geneQueryParam(gene)}`;
        case OrganismID.mouse:
            return `https://useast.ensembl.org/Danio_rerio/Gene/Summary?db=core;g=${geneQueryParam(gene)}`;
        case OrganismID.rat:
            return `https://useast.ensembl.org/Rattus_norvegicus/Gene/Summary?db=core;g=${geneQueryParam(gene)}`;
        case OrganismID.fruitfly:
            return `https://useast.ensembl.org/Drosophila_melanogaster/Gene/Summary?db=core;g=${geneQueryParam(gene)}`;
        default:
            return null;
    }
};

export const getSpeciesName = (organism?: OrganismID) => {
    switch (organism) {
        case OrganismID.human:
            return 'human';
        case OrganismID.mouse:
            return 'mouse';
        case OrganismID.zebrafish:
            return 'zebrafish';
        case OrganismID.rat:
            return 'rat';
        case OrganismID.fruitfly:
            return 'fruitfly';
        default:
            return organism ?? '';
    }
};

const fieldsQueryValue = 'symbol%2Cname%2Ctype_of_gene%2Centrezgene%2Cgenomic_pos%2Csummary%2CHGNC%2CMIM%2Calias';

const InfoItem = ({
    label,
    value,
    maxChars,
    showBlank = false,
    href,
}: {
    label: string;
    value?: string;
    maxChars?: number;
    showBlank?: boolean;
    href?: string;
}) => {
    if (isBlank(value) && !showBlank) {
        return null;
    }
    return (
        <div>
            <p className="font-semibold text-dark">{label}</p>
            {isBlank(value) && <p className="text-sm text-gray-500/50">N/A</p>}
            {isNotBlank(value) && isDefined(maxChars) && (
                <TruncatedDescription description={value} characterLimit={maxChars} className="text-dark" />
            )}
            {!isDefined(maxChars) && isNotBlank(value) && (
                <p className="text-dark">
                    {href ? (
                        <Link href={href} passHref target="_blank" rel="noreferrer" legacyBehavior>
                            <p className="cursor-pointer text-primary hover:opacity-70">{value}</p>
                        </Link>
                    ) : (
                        value
                    )}
                </p>
            )}
        </div>
    );
};

export type GenomicInfo = {
    HGNC: string;
    MIM: string;
    _id: string;
    _score: number;
    alias: string[] | string;
    enterzgene: string;
    genomic_pos: { chr: string; end: number; ensemblegene: string; start: number; strand: number };
    name: string;
    summary: string;
    symbol: string;
    type_of_gene: string;
};

type Props = { gene: string; organism?: OrganismID; close?: () => void; hideNameAndLists?: boolean };
const GeneInfoView = ({ gene, organism, close, hideNameAndLists = false }: Props) => {
    const [biomarkerLists, setBiomarkerLists] = useState<BiomarkerSearchItem[]>([]);
    const [targetLists, setTargetLists] = useState<BiomarkerSearchItem[]>([]);
    const ensemblCardUrl = getEnsemblCardUrl({ gene, organism });
    const api = useApi();

    const { data: searchResults, error: searchError } = useSWR<{ hits: GenomicInfo[] }>(
        `https://mygene.info/v3/query?q=${gene}&species=${getSpeciesName(organism)}&size=1&fields=${fieldsQueryValue}`,
        {
            fetcher,
        },
    );

    useEffect(() => {
        const fetchBiomarkerInfo = async (gene: string) => {
            try {
                const biomarkerInfo = await api.get<BiomarkerSearchResponse>(
                    Endpoints.lab.biomarkerSearch({ name: gene, set_type: 'biomarker' }),
                );
                const targetInfo = await api.get<BiomarkerSearchResponse>(
                    Endpoints.lab.biomarkerSearch({ name: gene, set_type: 'target' }),
                );
                if (biomarkerInfo.count > 0) {
                    const uniqueLists = biomarkerInfo.items.filter(
                        (list, index, self) =>
                            index === self.findIndex((t) => t.biomarker_set_name === list.biomarker_set_name),
                    );
                    setBiomarkerLists(uniqueLists);
                }
                if (targetInfo.count > 0) {
                    const uniqueLists = targetInfo.items.filter(
                        (list, index, self) =>
                            index === self.findIndex((t) => t.biomarker_set_name === list.biomarker_set_name),
                    );
                    setTargetLists(uniqueLists);
                }
            } catch {
                logger.error(new Error('Failed to fetch biomarker lists'));
            }
        };

        // Reset state on open
        setBiomarkerLists([]);
        setTargetLists([]);

        // Fetch biomarker lists on target gene
        fetchBiomarkerInfo(gene);
    }, [gene]);

    const handleClose = () => {
        setBiomarkerLists([]);
        close?.();
    };

    const loading = !searchResults && !searchError;
    const geneInfo = searchResults?.hits?.[0];
    return (
        <div className="max-h-[300px] min-h-[200px] w-[350px] space-y-4 overflow-auto">
            {!hideNameAndLists ? (
                <div>
                    <h3 className="flex items-center justify-between ">
                        <span className="text-lg font-semibold text-dark">{gene}</span>
                        <span className="inline-flex items-center space-x-2">
                            {ensemblCardUrl && (
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    size="small"
                                    color="primary"
                                    href={ensemblCardUrl}
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <ExternalLinkIcon width={18} />
                                </IconButton>
                            )}
                            {close && (
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    onClick={handleClose}
                                    size="small"
                                    color="primary"
                                >
                                    <XIcon width={18} />
                                </IconButton>
                            )}
                        </span>
                    </h3>
                    {geneInfo?.type_of_gene && <p className="text-xs font-semibold">{geneInfo.type_of_gene}</p>}
                </div>
            ) : null}
            {loading ? (
                <div className="py-8">
                    <LoadingMessage message="Loading gene details..." immediate />
                </div>
            ) : (
                <div className="space-y-12">
                    {geneInfo ? (
                        <div className="space-y-4">
                            {!hideNameAndLists ? (
                                <>
                                    <InfoItem label="Gene name" value={geneInfo.name} />
                                    {biomarkerLists.length || targetLists.length ? (
                                        <div className="flex flex-col">
                                            <p className="font-semibold text-dark">Associated lists</p>

                                            {biomarkerLists.length ? (
                                                <>
                                                    <p className="font-semibold text-dark my-[1px]">{`Biomarker lists: (${biomarkerLists.length})`}</p>
                                                    {biomarkerLists.map((list) => (
                                                        <Link
                                                            className="flex items-center space-x-2"
                                                            href={`/biomarker_lists/${list.biomarker_set_uuid}/biomarkers/${list.uuid}`}
                                                            target="_blank"
                                                            passHref
                                                            key={list.uuid}
                                                        >
                                                            <p className="text-inherit">
                                                                {gene} - {list.biomarker_set_name}
                                                            </p>
                                                            <Tooltip title="Open in new tab" placement="right" arrow>
                                                                <ExternalLinkIcon className="h-4 w-4 text-inherit" />
                                                            </Tooltip>
                                                        </Link>
                                                    ))}
                                                </>
                                            ) : null}

                                            {targetLists.length ? (
                                                <>
                                                    <p className="font-semibold text-dark my-[1px]">{`Target lists: (${targetLists.length})`}</p>
                                                    {targetLists.map((list) => (
                                                        <Link
                                                            className="flex items-center space-x-2"
                                                            href={`/target_lists/${list.biomarker_set_uuid}/targets/${list.uuid}`}
                                                            target="_blank"
                                                            passHref
                                                            key={list.uuid}
                                                        >
                                                            <p className="text-inherit font-semibold tracking-tight">
                                                                {gene} - {list.biomarker_set_name}
                                                            </p>
                                                            <Tooltip title="Open in new tab" placement="right" arrow>
                                                                <ExternalLinkIcon className="h-4 w-4 text-inherit" />
                                                            </Tooltip>
                                                        </Link>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            <InfoItem
                                label="Genomic position"
                                value={
                                    isDefined(geneInfo.genomic_pos?.chr) &&
                                    isDefined(geneInfo?.genomic_pos.start) &&
                                    isDefined(geneInfo.genomic_pos?.end)
                                        ? `${blankToUndefined(geneInfo.genomic_pos.chr)}:${blankToUndefined(
                                              geneInfo.genomic_pos.start,
                                          )}-${blankToUndefined(geneInfo.genomic_pos.end)}`
                                        : undefined
                                }
                            />
                            <InfoItem
                                label="HGNC"
                                value={geneInfo.HGNC}
                                href={`https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/HGNC:${geneInfo.HGNC}`}
                            />
                            <InfoItem
                                label="Ensembl"
                                value={geneInfo.genomic_pos?.ensemblegene}
                                href={`http://uswest.ensembl.org/Homo_sapiens/Gene/Summary?g=${geneInfo.genomic_pos?.ensemblegene}`}
                            />
                            <InfoItem
                                label="Entrez"
                                value={geneInfo.enterzgene}
                                href={`https://www.ncbi.nlm.nih.gov/gene/${geneInfo.enterzgene}`}
                            />
                            <InfoItem
                                label="OMIM"
                                value={geneInfo.MIM}
                                href={`https://www.omim.org/entry/${geneInfo.MIM}`}
                            />
                            <InfoItem
                                label="Aliases"
                                value={Array.isArray(geneInfo.alias) ? geneInfo.alias?.join(', ') : geneInfo.alias}
                            />
                            <InfoItem label="Description" value={geneInfo.summary} maxChars={160} />
                        </div>
                    ) : (
                        <div>
                            <InfoItem
                                label="No data available"
                                value="We weren't able to retrieve annotations for this biological target"
                            />
                        </div>
                    )}
                    <div>
                        <p className="flex items-center space-x-1 text-xs">
                            <span>Annotations by MyGene.info</span>
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                size="small"
                                color="primary"
                                target="_blank"
                                rel="noreferrer nofollow"
                                href="https://help.pluto.bio/en/articles/6458235-biological-entity-annotations"
                            >
                                <HelpCircleIcon width={14} />
                            </IconButton>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeneInfoView;
