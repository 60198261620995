import {
    AXIS_LABEL_CLASSNAMES,
    AXIS_LABEL_PUBLICATION_CLASSNAMES,
    AXIS_TITLE_CLASSNAMES,
    AXIS_TITLE_PUBLICATION_CLASSNAMES,
    GENE_SET_INFO_CLASSNAMES,
} from '@models/PlotConfigs';
import { EnrichmentData } from '@models/ExperimentData';
import React, { useMemo } from 'react';
import { AXIS_PADDING_PERCENT, createPlotTooltip } from '@components/plots/PlotUtil';
import * as d3 from 'd3';
import Logger from '@util/Logger';
import {
    DataPoint,
    getEnrichmentColorValue,
    prepareEnrichmentPlotData,
} from '@components/analysisCategories/pathway/plots/EnrichmentPlotUtils';
import { blankToNull, formatSmallNumber } from '@util/StringUtil';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import EnrichmentPlotLegend from '@components/analysisCategories/pathway/plots/EnrichmentPlotLegend';
import { usePlotContext } from '@contexts/PlotContext';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import { getRankMethodInfo } from '@models/analysis/RankMethod';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const logger = Logger.make('EnrichmentPlot');

export type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null };

const y_axis_column = 'Running enrichment score';
const EnrichmentPlotView = ({ customPlotStylingOptions }: Props) => {
    const { plot } = usePlotContext();

    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: _svg, size, context, tooltipId }) => {
                logger.debug('rending Enrichment plot with size', size);

                const { plotData, plot, publicationMode } = context;
                const data = plotData as EnrichmentData | null;
                if (!data) {
                    return;
                }
                const options = plot.display;
                const analysis = plot.analysis as GeneSetEnrichmentAnalysis;
                const geneSet = analysis.gene_set ?? { Adj_P_Value: 0, NES: 0 };
                const pValue = geneSet.Adj_P_Value
                    ? formatSmallNumber({
                          value: geneSet.Adj_P_Value,
                          decimals: 5,
                          minThreshold: 0.0001,
                      })
                    : '--';
                const NES = geneSet.NES ? geneSet.NES.toFixed(2) : '--';

                /* ************************************
                 * Reset any existing plot
                 *************************************/
                _svg.selectAll('g').remove();
                const svg = _svg.append('g');

                /* ************************************
                 * Prepare data
                 *************************************/
                const preparedData = prepareEnrichmentPlotData(data);

                if (!preparedData) {
                    logger.warn('no prepared data found, removing chart.', { data, plot });
                    svg.selectAll('g').remove();
                    return;
                }

                const { lineItems, xStats, yStats, colorItems } = preparedData;
                const geneTickItems = lineItems.filter((s) => s.Gene_Tick);

                /* ************************************
                 * Calculate data ranges
                 *************************************/
                const height = size.height;
                const width = size.width;
                const barConfig = {
                    padding: {
                        bottom: 0,
                        top: 0,
                    },
                    width: 2,
                    height: 40,
                };

                const heatmapConfig = {
                    height: 14,
                };
                const margin = { top: 20, right: 20, bottom: 26, left: 90 };

                const yPadding = Math.abs(yStats.max - yStats.min) * AXIS_PADDING_PERCENT;
                const xPadding = 0;

                const yMin = yStats.min;
                const yMax = yStats.max + yPadding;

                const xMin = xStats.min - xPadding;
                const xMax = xStats.max + xPadding;
                const x_axis_label = `Genes ranked by ${getRankMethodInfo(analysis.rank_method).displayName}`;

                /* ************************************
                 * Create Y-Axis Scale
                 *************************************/
                const yScale = d3
                    .scaleLinear()
                    .domain([yMin, yMax])
                    .rangeRound([
                        height - margin.bottom - barConfig.padding.bottom - barConfig.padding.top - barConfig.height,
                        margin.top,
                    ]);

                const gridLineHeight = height - margin.bottom - margin.top;

                /* ************************************
                 * Draw Y-Axis
                 *************************************/
                svg.selectAll('.axis-label').remove();
                svg.selectAll('.y-axis').remove();
                const yAxisFormat = yMax > 10_000 || yMax < 0.0001 ? '.1e' : ',f';
                const yAxis = (g) =>
                    g
                        .call((g) => g.select('.domain').remove())
                        .attr('transform', `translate(${margin.left},0)`)
                        .attr(
                            'fill',
                            customPlotStylingOptions?.yaxis
                                ? customPlotStylingOptions?.yaxis.fontColor
                                : 'currentColor',
                        )
                        .style(
                            'font-size',
                            customPlotStylingOptions?.yaxis ? customPlotStylingOptions?.yaxis.fontSize : '18',
                        )
                        .style(
                            'font-family',
                            customPlotStylingOptions?.yaxis ? customPlotStylingOptions?.yaxis.fontFamily : 'Arial',
                        )
                        .attr(
                            'class',
                            `y-axis ${
                                publicationMode ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES
                            } z-20`,
                        )
                        .call(d3.axisLeft(yScale).ticks(8, yAxisFormat).tickSizeOuter(0));

                svg.append('g').call(yAxis);
                const yAxisWidth = svg.select<SVGGElement>('.y-axis')?.node()?.getBoundingClientRect().width ?? 70;
                margin.left = Math.round(yAxisWidth) + 34;

                /* ************************************
                 * Create X-Axis Scale
                 * (needs to be done after y_axis is added due to margins)
                 *************************************/
                const xScale = d3
                    .scaleLinear()
                    .domain([xMin, xMax])
                    .rangeRound([margin.left, width - margin.right]);

                /* ************************************
                 * X-Axis Gridlines
                 *************************************/
                svg.select('.grid-line').remove();
                svg.insert('g', '.y-axis')
                    .attr('class', 'grid-line text-gray-200')
                    .attr('transform', 'translate(0,' + (height - margin.bottom) + ')')
                    .call(
                        d3
                            .axisBottom(xScale)
                            .tickSize(-gridLineHeight)
                            .tickFormat(() => '')
                            .tickSizeOuter(0),
                    );

                svg.select('.grid-line .domain').remove();
                svg.select('.y-axis .domain').remove();
                svg.select<SVGGElement>('.y-axis').attr('transform', `translate(${margin.left},0)`);

                /* ************************************
                 * Y=0 line
                 *************************************/
                svg.append('line')
                    .attr('class', 'grid-line text-gray-200')
                    .attr('x1', margin.left)
                    .attr('x2', width - margin.right)
                    .attr('y1', yScale(0))
                    .attr('y2', yScale(0))
                    .attr('stroke', 'currentColor')
                    .attr('stroke-width', 1);

                /* ************************************
                 * Y-Axis domain line (left vertical line)
                 *************************************/
                svg.select('.y-domain').remove();
                svg.append('line')
                    .attr('class', 'y-domain text-dark')
                    .attr('y1', margin.top)
                    .attr('y2', height - margin.bottom)
                    .attr('x1', margin.left)
                    .attr('x2', margin.left)
                    .attr('stroke', 'currentColor')
                    .attr('stroke-width', 1);

                /* ************************************
                 * Y-Axis Title
                 *************************************/
                svg.append('text')
                    .attr(
                        'class',
                        `axis-label ${publicationMode ? AXIS_TITLE_PUBLICATION_CLASSNAMES : AXIS_TITLE_CLASSNAMES}`,
                    )
                    .attr('x', -height / 2)
                    .attr('y', margin.left - yAxisWidth - 18)
                    .attr(
                        'fill',
                        customPlotStylingOptions?.yaxis ? customPlotStylingOptions?.yaxis.fontColor : 'currentColor',
                    )
                    .style(
                        'font-size',
                        customPlotStylingOptions?.yaxis ? customPlotStylingOptions?.yaxis.fontSize : '18',
                    )
                    .style(
                        'font-family',
                        customPlotStylingOptions?.yaxis ? customPlotStylingOptions?.yaxis.fontFamily : 'Arial',
                    )
                    .attr('text-anchor', 'middle')
                    .attr('transform', 'rotate(-90)')
                    .text(`${y_axis_column}`);

                /* ************************************
                 * X-Axis Title
                 *************************************/
                svg.append('text')
                    .attr(
                        'class',
                        `axis-label ${publicationMode ? AXIS_TITLE_PUBLICATION_CLASSNAMES : AXIS_TITLE_CLASSNAMES}`,
                    )
                    .attr('x', width / 2)
                    .attr('y', height - 6)
                    .attr(
                        'fill',
                        customPlotStylingOptions?.xaxis ? customPlotStylingOptions?.xaxis.fontColor : 'currentColor',
                    )
                    .style(
                        'font-size',
                        customPlotStylingOptions?.xaxis ? customPlotStylingOptions?.xaxis.fontSize : '18',
                    )
                    .style(
                        'font-family',
                        customPlotStylingOptions?.xaxis ? customPlotStylingOptions?.xaxis.fontFamily : 'Arial',
                    )
                    .attr('text-anchor', 'middle')
                    .text(x_axis_label);

                /* ************************************
                 * P-value Label
                 *************************************/
                if (options.show_p_value) {
                    svg.append('text')
                        .attr('font-size', 'smaller')
                        .attr('class', `axis-label ${GENE_SET_INFO_CLASSNAMES}`)
                        .attr('y', 40)
                        .attr('x', width - 160)
                        .attr('fill', 'currentColor')
                        .text(`Adj p-val: ${pValue}`);
                }
                if (options.show_nes_value) {
                    svg.append('text')
                        .attr('font-size', 'smaller')
                        .attr('class', `axis-label ${GENE_SET_INFO_CLASSNAMES}`)
                        .attr('y', options.show_p_value ? 65 : 40)
                        .attr('x', width - 160)
                        .attr('fill', 'currentColor')
                        .text(`NES: ${NES}`);
                }

                /* ************************************
                 * Draw X-Axis (not needed)
                 *************************************/
                // const xAxis = (g) =>
                //     g
                //         .attr('transform', `translate(0,${height - margin.bottom})`)
                //         .attr('class', AXIS_LABEL_CLASSNAMES)
                //         // .attr('transform', 'translate(0,' + yScale(0) + ')')
                //         .call(d3.axisBottom(xScale).ticks(0).tickSizeOuter(0));
                //
                // // Append the x-axis
                // svg.append('g').call(xAxis);

                /* ************************************
                 * Draw Line Plot
                 *************************************/
                const line = d3
                    .line<DataPoint>()
                    .x((d) => xScale(d.x))
                    .y((d) => yScale(d.y))
                    .curve(d3.curveLinear);

                svg.selectAll('.plot-line').remove();

                const customColors = options.custom_color_json ?? {};
                const lineColor = customColors['enrichment_score_line'] ?? 'lime';
                svg.append('path')
                    .datum(lineItems)
                    .attr('d', line)
                    .attr('class', 'plot-line')
                    .attr('fill', 'none')
                    .attr('stroke', lineColor)
                    .attr('stroke-width', 2);

                /* ************************************
                 * Draw Vertical Bars
                 *************************************/
                svg.selectAll('.tick-bar').remove();
                svg.selectAll('.tick-bar')
                    .data(geneTickItems)
                    .enter()
                    .append('rect') // Uses the enter().append() method
                    .attr('class', 'tick-bar opacity-70') // Assign a class for styling
                    .attr('x', function (d) {
                        return xScale(d.x);
                    })
                    .attr('y', function () {
                        return (
                            height - margin.bottom - barConfig.padding.bottom + barConfig.padding.top - barConfig.height
                        );
                    })
                    .attr('width', barConfig.width)
                    .attr('height', barConfig.height);

                /* ************************************
                 * Draw heatmap
                 *************************************/

                svg.select('.heatmap').remove();
                svg.append('g')
                    .attr('class', 'heatmap opacity-80')
                    .attr('transform', `translate(${0},${height - heatmapConfig.height - margin.bottom})`)
                    .selectAll('rect')
                    .data(colorItems)
                    .enter()
                    .append('rect')
                    .attr('width', (d, i) => {
                        const previous = colorItems[i - 1] ?? null;
                        const isLast = i === colorItems.length - 1;
                        let w = xScale(d.x) - (previous ? xScale(previous.x) : 0);
                        if (isLast) {
                            w = xScale(xMax) - (previous ? xScale(previous.x) : 0);
                        }
                        return w;
                    })
                    .attr('height', heatmapConfig.height)
                    .attr('fill', (d) => getEnrichmentColorValue(d.colorValue))
                    .attr('x', (d, i) => {
                        const previous = colorItems[i - 1];
                        const x = previous ? xScale(previous.x) : xScale(xMin);

                        return x;
                    });

                const lineChartHeight = height - margin.bottom;
                const tooltipContainer = createPlotTooltip(tooltipId);
                const dotRadius = 4.5;
                const lineBisector = d3.bisector<DataPoint, number>((d) => d.x).left;
                const onMouseMove = (e: MouseEvent) => {
                    const [mouseX] = d3.pointer(e);
                    const x0 = xScale.invert(mouseX + margin.left);
                    const bisectedIndex = lineBisector(lineItems, x0, 1);
                    const d0 = lineItems[Math.max(bisectedIndex - 1, 0)];
                    const d1 = lineItems[Math.min(bisectedIndex, lineItems.length - 1)];
                    const dataToDisplay = x0 - d0.x > d1.x - x0 ? d1 : d0;

                    focus.attr(
                        'transform',
                        `translate(${xScale(dataToDisplay.x)}, ${yScale(dataToDisplay.y) + dotRadius})`,
                    );

                    let formattedYValue =
                        Math.abs(dataToDisplay.y) < 0.001 || Math.abs(dataToDisplay.y) > 10_000
                            ? dataToDisplay.y.toExponential(3)
                            : dataToDisplay.y.toPrecision(3);
                    if (dataToDisplay.y === 0) {
                        formattedYValue = '0';
                    }

                    tooltipContainer
                        .html(
                            `
                 <span class="font-semiboild text-md text-dark">${blankToNull(dataToDisplay.Gene) ?? ''}</span>
                 <span class="block text-sm text-gray-600"><span class="font-bold">Rank: </span><span>${
                     dataToDisplay.Rank
                 }</span></span>
                 <span class="block text-sm text-gray-600"><span class="font-bold">Running ES: </span><span>${formattedYValue}</span></span>
                `,
                        )
                        .style('left', `${e.pageX + 10}px`)
                        .style('top', `${e.pageY}px`);
                    focus.select('.x-hover-line').attr('y2', lineChartHeight - yScale(dataToDisplay.y) - dotRadius);
                    focus.select('.y-hover-line').attr('x2', width);
                };

                svg.select('.focus').remove();
                const focus = svg.append('g').attr('class', 'focus').style('display', 'none');
                focus
                    .append('line')
                    .attr('class', 'x-hover-line stroke-current text-[#171717]')
                    .attr('y1', 0)
                    .attr('y2', lineChartHeight);

                focus
                    .append('circle')
                    .attr('r', dotRadius)
                    .attr('stroke-width', 1)
                    .attr('stroke', '#171717')
                    .attr('transform', `translate(0, -${dotRadius})`)
                    .attr('class', 'fill-current text-[#171717] text-opacity-75');

                // focus.append('text').attr('class', 'hover-text').attr('x', 15).attr('dy', '.31em');

                svg.append('rect')
                    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
                    .attr('class', 'fill-transparent')
                    .attr('width', width - margin.left - margin.right)
                    .attr('height', height - margin.top - margin.bottom)
                    .on('mouseover', function () {
                        focus.style('display', null);
                        tooltipContainer.style('display', null);
                        tooltipContainer.style('opacity', 1);
                    })
                    .on('mouseout', function () {
                        focus.style('display', 'none');
                        tooltipContainer.style('display', 'none');
                    })
                    .on('mousemove', onMouseMove);
            },
        [customPlotStylingOptions],
    );

    return (
        <div className="flex h-full w-full flex-col">
            <div className="flex w-full flex-grow overflow-hidden">
                <DynamicPlotContainer drawChart={drawChart} />
            </div>
            <EnrichmentPlotLegend plot={plot} className="flex-shrink-0 flex-grow-0" />
        </div>
    );
};

export default EnrichmentPlotView;
