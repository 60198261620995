import Endpoints from '@services/Endpoints';
import Plot from '@models/Plot';
import { AnalysisProxyValues, PlotDisplayOption, PlotDisplayOptionFormValues } from '@models/PlotDisplayOption';
import Experiment from '@models/Experiment';
import { PlotResponse, useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { MutatorOptions, useSWRConfig } from 'swr';
import useApi from '@hooks/useApi';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { FormikHelpers } from 'formik';
import Logger from '@util/Logger';
import useMatchMutate from '@hooks/useMatchMutate';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { ApiError } from '@services/ApiError';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TimeoutValue } from '../util/ObjectUtil';

const logger = Logger.make('hooks/usePlotDisplayForm');

const successTimeoutDuration = 2_500;

type Timeout = TimeoutValue;

type Props = { plot: Plot; experiment: Experiment };
const usePlotDisplayForm = ({ plot, experiment }: Props) => {
    const api = useApi();
    const { mutate } = useSWRConfig();
    const {
        setPlotDisplayOptionOverride,
        getPlotOverrides,
        updatePreviewPlot,
        setCurrentChanges,
        refreshPlotItems,
        zoomTransform,
    } = useExperimentDetailViewContext();
    const { pathEqualsIgnoreQueryMutate } = useMatchMutate();
    const overrides = getPlotOverrides(plot.uuid);
    const displayIdOverride = overrides.display_id;
    const analysisIdOverride = overrides.analysis_id;
    const permissions = useExperimentPermissions(experiment);
    const [savingCurrentView, setSavingCurrentView] = useState(false);
    const [showSaveSuccess, setShowSaveSuccess] = useState(false);
    const successTimeoutRef = useRef<Timeout | null>(null);

    const clearSuccessTimeout = () => {
        if (successTimeoutRef.current) {
            logger.info('clearing success timeout...');
            clearTimeout(successTimeoutRef.current);
            setShowSaveSuccess(false);
        }
    };

    const handleSaveViewSuccess = useCallback(() => {
        setShowSaveSuccess(true);
        logger.debug('setting save view success');
        if (successTimeoutDuration) {
            logger.debug('save view success timeout is ', successTimeoutDuration);
            successTimeoutRef.current = setTimeout(() => {
                logger.debug('removing save view success');
                setShowSaveSuccess(false);
                successTimeoutRef.current = null;
            }, successTimeoutDuration);
        }
    }, [successTimeoutDuration]);

    const plotEndpoint = Endpoints.lab.experiment.plot.base({
        plotId: plot.uuid,
        experimentId: experiment.uuid,
    });

    const createDisplayEndpoint = Endpoints.lab.experiment.plot.displays({
        experimentId: experiment.uuid,
        plotId: plot.uuid,
    });

    const getUpdateDisplayEndpoint = (displayId: string) =>
        Endpoints.lab.experiment.plot.display({
            experimentId: experiment.uuid,
            displayId,
            plotId: plot.uuid,
        });

    const refetchPlotData = async () => {
        const params = {
            experimentId: experiment.uuid,
            plotId: plot.uuid,
        };
        await pathEqualsIgnoreQueryMutate(Endpoints.lab.experiment.plot.data(params));
    };

    const invalidatePlotData = async () => {
        await pathEqualsIgnoreQueryMutate(
            Endpoints.lab.experiment.plot.data({
                experimentId: experiment.uuid,
                plotId: plot.uuid,
            }),
            null,
            { revalidate: false },
        );
    };

    const mutatePlot = async (
        updatedPlot?: ((current: Plot | null | undefined) => Plot | null | undefined) | Plot | null,
        revalidate: boolean | MutatorOptions = false,
    ) => {
        const plotEndpoint = Endpoints.lab.experiment.plot.base(
            {
                experimentId: experiment.uuid,
                plotId: plot.uuid,
            },
            {
                display_id: displayIdOverride ?? undefined,
                analysis_id: analysisIdOverride ?? undefined,
            },
        );

        let returnValue: Plot | null | undefined;

        // 1. update the plot singular plot
        if (updatedPlot) {
            returnValue = await mutate<Plot | null>(plotEndpoint, updatedPlot, revalidate);
        } else {
            returnValue = await mutate<Plot | null>(plotEndpoint);
        }

        await refreshPlotItems();
        await refetchPlotData();
        return returnValue;
    };

    const updatePlotAnalysis = async ({
        experimentId,
        analysisId,
        legend,
        analysis_values,
    }: { experimentId: string; analysisId: string } & AnalysisProxyValues) => {
        logger.debug('updating plot analysis via display form');
        const updatedAnalysis = await api.put<ExperimentAnalysis>(
            Endpoints.lab.experiment.analysis.base({
                experimentId,
                analysisId,
            }),
            { group_display_order: legend?.group_display_order, ...analysis_values },
        );

        await mutatePlot((currentPlot) => {
            if (!currentPlot) {
                return currentPlot;
            }

            return {
                ...currentPlot,
                analysis: updatedAnalysis,
            };
        }, false);
    };

    /**
     * Split out the DisplayOption form payload from the analysis props
     * @param {PlotDisplayOptionFormValues} values
     * @returns {{payload: Omit<BarPlotDisplayOption | BoxPlotDisplayOption | VolcanoPlotDisplayOption | LinePlotDisplayOption | ImageDisplayOption | EnrichmentPlotDisplayOption | SampleScatterPlotDisplayOption | IGVPlotDisplayOption | KaplanMeierCurveDisplayOption | ScoreBarPlotDisplayOption, "uuid" | "updated_at" | "created_at" | "experiment_id"> & PlotLegendDisplayFormValues & {analysis_values?: {gene_set?: string | null}}, legend: PlotLegendFields | undefined, analysis_values: {gene_set?: string | null} | undefined}}
     */
    const processFormValues = (values: PlotDisplayOptionFormValues) => {
        const { legend, analysis_values, ...displayValues } = values;
        const payload: PlotDisplayOptionFormValues = {
            ...displayValues,
        };
        return { payload, legend, analysis_values };
    };

    const mutateDisplayOnPlot = async (updatedDisplay: PlotDisplayOption) => {
        await mutate(
            Endpoints.lab.experiment.plotsV2({ experimentId: experiment.uuid }),
            (current: PlotResponse | null) => {
                const plots = current?.items ?? [];
                const existing = plots?.find((p) => p.uuid === plot.uuid);

                if (existing) {
                    existing.display = updatedDisplay;
                }
                return plots ? { items: plots, count: current?.count ?? 0 } : null;
            },
        );
    };

    const updatePublishedPlotDisplayOption = async (values: PlotDisplayOptionFormValues) => {
        const publishedDisplayId = plot.display?.uuid ?? null;
        const { payload } = processFormValues(values);
        const analysis = plot.analysis;
        // Update existing DisplayOption when the existing ID is the same and the display_type is unchanged
        if (publishedDisplayId && values.display_type === plot.display.display_type) {
            const updatedDisplay = await api.put<PlotDisplayOption>(
                getUpdateDisplayEndpoint(publishedDisplayId),
                payload,
            );
            if (analysis && updatedDisplay) {
                await api.post(
                    Endpoints.lab.experiment.plot.linkAnalysis({
                        experimentId: experiment.uuid,
                        plotId: plot.uuid,
                    }),
                    { analysis_id: analysis.uuid, display_id: updatedDisplay.uuid },
                );
            }
            await mutatePlot(
                (current) =>
                    current
                        ? {
                              ...current,
                              display: updatedDisplay,
                          }
                        : null,
                true,
            );

            await mutateDisplayOnPlot(updatedDisplay);
        }
        // Else, create a new DisplayOption and associate it with the plot
        else {
            const createdDisplay = await api.post<PlotDisplayOption>(createDisplayEndpoint, payload);
            await mutateDisplayOnPlot(createdDisplay);
            // set the published display_id on the plot
            const updatedPlot = await api.put<Plot>(plotEndpoint, {
                display_id: createdDisplay.uuid,
                analysis_id: plot.analysis?.uuid,
            });
            if (analysis && createdDisplay) {
                logger.debug('linking published plot display and analysis', {
                    plot,
                    linked_values: {
                        analysis_id: analysis.uuid,
                        display_id: createdDisplay.uuid,
                    },
                });
                await api.post(
                    Endpoints.lab.experiment.plot.linkAnalysis({
                        experimentId: experiment.uuid,
                        plotId: plot.uuid,
                    }),
                    { analysis_id: analysis.uuid, display_id: createdDisplay.uuid },
                );
            }

            await mutatePlot(updatedPlot, false);
        }
    };

    /**
     * When a user does not have edit access, just create or update existing overrides and don't update the published display_id on the plot
     * @param {PlotDisplayOptionFormValues} values
     * @returns {Promise<void>}
     */
    const updatePlotDisplayOverrides = async (values: PlotDisplayOptionFormValues) => {
        logger.debug('[updatePlotDisplayOverrides] updating plot display overrides....', { values });
        const { payload } = processFormValues(values);
        // Only update the plot if it's a preview plot with a known override id and the display type hasn't changed
        if (displayIdOverride && values.display_type === plot.display.display_type) {
            plot.display = await api.put<PlotDisplayOption>(getUpdateDisplayEndpoint(displayIdOverride), payload);
        } else {
            plot.display = await api.post<PlotDisplayOption>(createDisplayEndpoint, payload);
        }
        const analysis = plot.analysis;
        const previewDisplay = plot.display;
        if (previewDisplay) {
            setPlotDisplayOptionOverride({ plotId: plot.uuid, displayOptionId: previewDisplay.uuid });
            if (analysis) {
                logger.debug('linking plot display and analysis', {
                    plot,
                    linked_values: {
                        analysis_id: analysis.uuid,
                        display_id: previewDisplay.uuid,
                    },
                });
                await api.post(
                    Endpoints.lab.experiment.plot.linkAnalysis({
                        experimentId: experiment.uuid,
                        plotId: plot.uuid,
                    }),
                    { analysis_id: analysis.uuid, display_id: previewDisplay.uuid },
                );
            }
        }

        logger.debug('[updatePlotDisplayOverrides] mutating plot...');
        await mutatePlot((current) => {
            const updatedPlot = { ...(current ?? plot), display: previewDisplay };
            if (plot.status === 'preview') {
                updatePreviewPlot(updatedPlot);
            }

            logger.debug('[updatePlotDisplayOverrides] finished updating plot display overrides', updatedPlot);
            return updatedPlot;
        });
    };

    const handleFormSubmit = async (
        values: PlotDisplayOptionFormValues,
        helpers: FormikHelpers<PlotDisplayOptionFormValues>,
    ) => {
        try {
            helpers.setStatus(null);
            const plotId = plot.uuid;
            const analysisId = getPlotOverrides(plotId)?.analysis_id ?? plot.analysis?.uuid;
            const experimentId = experiment.uuid;
            const { legend, analysis_values } = processFormValues(values);

            if (values.display_type !== plot.display?.display_type) {
                await invalidatePlotData();
            }

            if (permissions.canEdit) {
                await updatePublishedPlotDisplayOption(values);
            } else {
                await updatePlotDisplayOverrides(values);
            }

            if ((legend || analysis_values) && analysisId) {
                await updatePlotAnalysis({ experimentId, analysisId, legend, analysis_values });
            }
        } catch (error) {
            logger.error(error);
            helpers.setStatus({ error: ApiError.getMessage(error) });
        } finally {
            helpers.setSubmitting(false);
            setCurrentChanges(null);
        }
    };

    const handleSaveCurrentView = async () => {
        setSavingCurrentView(true);
        try {
            if (permissions.canEdit && zoomTransform) {
                const publishedDisplayId = plot.display?.uuid ?? null;
                const analysis = plot.analysis;
                const payload = {
                    display_type: plot.display?.display_type,
                    custom_options_json: {
                        zoomTransform,
                    },
                };
                // Update existing DisplayOption when the existing ID is the same and the display_type is unchanged
                if (publishedDisplayId) {
                    const updatedDisplay = await api.put<PlotDisplayOption>(
                        getUpdateDisplayEndpoint(publishedDisplayId),
                        payload,
                    );
                    if (analysis && updatedDisplay) {
                        await api.post(
                            Endpoints.lab.experiment.plot.linkAnalysis({
                                experimentId: experiment.uuid,
                                plotId: plot.uuid,
                            }),
                            { analysis_id: analysis.uuid, display_id: updatedDisplay.uuid },
                        );
                    }
                    await mutatePlot(
                        (current) =>
                            current
                                ? {
                                      ...current,
                                      display: updatedDisplay,
                                  }
                                : null,
                        true,
                    );

                    await mutateDisplayOnPlot(updatedDisplay);

                    handleSaveViewSuccess();
                }
            }
            setSavingCurrentView(false);
        } catch (error) {
            logger.error(error);
            setSavingCurrentView(false);
        }
    };

    useEffect(() => {
        return () => {
            clearSuccessTimeout();
        };
    }, []);

    return { handleFormSubmit, handleSaveCurrentView, savingCurrentView, showSaveSuccess };
};

export default usePlotDisplayForm;
