import { Dialog, DialogContent } from '@mui/material';
import cn from 'classnames';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import Experiment from '@models/Experiment';
import EditPlotModalView from '@components/experiments/EditPlotModalView';
import useExperimentSettings from '@hooks/useExperimentSettings';
import AnalysisIcon from '@components/experiments/analyses/AnalysisIcon';
import { defaultTextLightClass } from '@components/icons/CustomIcons';
import PreprocessFormModalView from '@components/experiments/preprocesses/PreprocessFormModalView';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import AnnotationFormModalView from './experiments/annotations/AnnotationFormModalView';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import DataTableModalView from './experiments/DataTableModalView';
import AnalysisCategoryNavigation from './experiments/analysisModal/AnalysisCategoryNavigation';
import { useExperimentCanvasContext } from '@contexts/ExperimentCanvasContext';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';

type Props = {
    experiment: Experiment | null | undefined;
    onClose: () => void;
    open: boolean;
    openOnComments?: boolean;
    openOnPlot?: boolean;
};

const ExperimentModal = ({ onClose, open, experiment, openOnComments = false, openOnPlot = false }: Props) => {
    const { selectedPlot } = useExperimentDetailViewContext();
    const { selectedNode } = useExperimentCanvasContext();
    const { getAnalysisInfo } = useExperimentSettings(experiment);
    const analysis = selectedPlot?.analysis_type ? getAnalysisInfo(selectedPlot.analysis_type) : '';
    const { selectedPreprocess } = useExperimentWorkflowContext();
    const { selectedAnnotationSet } = useExperimentAnnotationContext();

    const getTitle = () => {
        if (selectedPreprocess) {
            return selectedPreprocess.preprocess_type.display_name;
        }
        if (selectedAnnotationSet) {
            return 'Edit cluster annotations';
        }
        if (analysis) {
            return `${analysis.display_name} analysis`;
        }
        if (selectedNode?.data?.selectType) {
            return selectedNode.data.selectedPlot?.analysis?.name;
        }
        return 'Select an analysis to begin';
    };

    const renderModalContent = () => {
        if (selectedPreprocess) {
            return <PreprocessFormModalView experiment={experiment as Experiment} />;
        }
        if (selectedPlot) {
            return (
                <EditPlotModalView
                    experiment={experiment as Experiment}
                    plot={selectedPlot}
                    openOnComments={openOnComments}
                    openOnPlot={openOnPlot}
                />
            );
        }
        if (selectedAnnotationSet) {
            return <AnnotationFormModalView experiment={experiment as Experiment} />;
        }
        if (selectedNode?.data?.selectType) {
            return <DataTableModalView experiment={experiment as Experiment} selectedDataNode={selectedNode.data} />;
        }
        return <AnalysisCategoryNavigation experiment={experiment as Experiment} />;
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="xl"
            PaperProps={{
                style: {
                    minHeight: 'calc(100% - 64px)',
                    maxHeight: 'calc(100% - 64px)',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100%',
                },
            }}
        >
            <DialogCloseButton onClose={onClose} tooltext="Close" />
            <PlutoDialogTitle
                title={getTitle()}
                iconStart={
                    analysis && (
                        <AnalysisIcon
                            shortname={analysis.shortname}
                            width={24}
                            height={24}
                            className={cn({
                                'text-gray-500': !analysis.is_enabled,
                                [defaultTextLightClass]: analysis.is_enabled,
                            })}
                        />
                    )
                }
            />
            <DialogContent
                className={cn('flex flex-col !overflow-hidden', {
                    'relative border-t-2 border-t-gray-200 !p-0':
                        selectedPlot || selectedPreprocess || selectedAnnotationSet || selectedNode,
                })}
            >
                <PlutoErrorBoundary>{renderModalContent()}</PlutoErrorBoundary>
            </DialogContent>
        </Dialog>
    );
};
export default ExperimentModal;
