import { ApiError } from '@services/ApiError';
import { deepEqual, getChangedValues } from '@util/ObjectUtil';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import { getFormSetup } from '@components/experiments/annotations/AnnotationFormUtil';
import { isBlank, isWhite } from '@util/StringUtil';
import { ScrollableSidebarContent, ScrollableSidebarFooter } from '@components/experiments/ScrollableSidebarContent';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import AggregateFormErrorAlert from '@components/experiments/AggregateFormErrorAlert';
import AnalysisFormSubmitButton from '@components/experiments/analyses/AnalysisFormSubmitButton';
import FormikListener from '@components/forms/FormikListener';
import LoadingMessage from '@components/LoadingMessage';
import Logger from '@util/Logger';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AnnotationFormValues } from './AnnotationFormTypes';
import { DESCRIPTION_CHARACTER_LIMIT } from '@models/Experiment';
import TextAreaField from '@components/forms/TextAreaField';
import { Accordion, AccordionDetails, AccordionSummary, Button, createTheme } from '@mui/material';
import { LegendSVG } from '@components/plots/PlotLegendView';
import cn from 'classnames';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import TextInputField from '@components/forms/TextInputField';
import CustomLegendColorField, { CustomLegendColorItem } from '../plotDisplay/groups/CustomLegendColorField';
import ConfirmSaveAnnotationDialog from './ConfirmSaveAnnotationDialog';
import { CSSTransition } from 'react-transition-group';
import { Annotation } from '@models/Annotation';
import { useDebounce } from 'react-use';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import { createOpenAI } from '@ai-sdk/openai';
import { generateText } from 'ai';
import { getConfig } from '@util/config';

const theme = createTheme();

const styles = {
    accordion: {
        boxShadow: 'none',
        borderRadius: '10px !important',
        '&.Mui-expanded': {
            margin: 0,
            border: `2px solid ${theme.palette.primary.main}`,
        },
        '&:not(expanded)': {
            border: `0.5px solid ${theme.palette.primary.light}`,
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    accordionSummary: {
        padding: theme.spacing(0, 3),
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 6),
        },
        '&.Mui-expanded': {
            height: 0,
            minHeight: '0 !important',
            overflow: 'hidden',
            margin: 0,
            transition: 'min-height 0.3s',
        },
    },
    accordionDetails: {
        padding: theme.spacing(3),
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(3, 6),
        },
    },
};

const Config = getConfig();

const openai = createOpenAI({
    apiKey: Config.openAI.openAIAnnotationKey,
});

const logger = Logger.make('ExperimentPreprocessForm');
export const fetchCellTypes = async (clusterNumber: number, plotData: any): Promise<string> => {
    try {
        const plotDataSnippet = {
            key: {
                G: 'Gene_Symbol',
                LFC: 'Log2_Fold_Change',
                AdjP: 'Adj_P_Value',
            },
            data: {
                hdrs: ['G', 'LFC', 'AdjP'],
                items: plotData.items
                    .sort((a, b) => b.Log2_Fold_Change - a.Log2_Fold_Change) // Sort by Log2 Fold Change
                    .slice(0, 50) // Take the top 50 items
                    .map((item) => ({
                        G: item.Gene_Symbol,
                        LFC: parseFloat(item.Log2_Fold_Change.toFixed(2)),
                        AdjP: item.Adj_P_Value,
                    })),
            },
        };
        const { text } = await generateText({
            model: openai('gpt-4-turbo'),
            system: 'You are a bot designed to annotate single cell cluster and give users potential cell types for the cluster based on genes passed in.  The response should only be pure json output of the cell type and the a confidence number.  Ideally output three possible cell types, but never output only one suggestion',
            prompt: `Given the following plot data, what are the possible cell types for this cluster? Plot Data: ${JSON.stringify(plotDataSnippet)}`,
            maxRetries: 3,
        });
        return text;
    } catch (error) {
        return 'Could not fetch cell types.';
    }
};

/**
 * Render the form to create/edit an Experiment Analysis for a given annotation.
 *
 * Note: the `key` on the Formik form ensures that when a different annotation is selected that a new form is rendered,
 * rather than re-using the existing, mounted component. This solves an issue where different plots have different
 * fields present/absent, which could cause null pointer errors on an initial render.
 * Passing in a key ensures we have a fresh component when the annotation changes.
 * @return {JSX.Element}
 * @constructor
 */
const AnnotationForm = ({ openAISidebar }: { openAISidebar: (arg: number) => void }) => {
    const { setCurrentChanges, currentChanges } = useExperimentDetailViewContext();
    const { features } = useOrganizationPermissions();
    const {
        selectedAnnotation,
        selectedAnnotationSet: annotationSet,
        setSelectedAnnotation,
        updateAnnotationSet,
        updateAnnotationSetError,
        updateAnnotationSetLoading,
        editAnnotationFormRef: formRef,
        plotData,
    } = useExperimentAnnotationContext();

    const [expanded, setExpanded] = useState<string | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState<boolean>(false);
    const [cellTypes, setCellTypes] = useState<{ [key: string]: any }>({});
    const [processingCluster, setProcessingCluster] = useState<string | null>(null); // New state to track the processing cluster
    const [loadingAIAnnotations, setLoadingAIAnnotations] = useState<{ [key: string]: boolean }>({}); // State to track loading state for AI annotations

    const getAiResponse = async () => {
        if (selectedAnnotation) {
            setProcessingCluster(selectedAnnotation.uuid); // Set the currently processing cluster
            setLoadingAIAnnotations((prev) => ({ ...prev, [selectedAnnotation.uuid]: true })); // Set loading state for the current cluster
            const cellTypesResponse = await fetchCellTypes(selectedAnnotation.number, plotData);
            try {
                let parsedResponse;

                // Check if the response contains a ```json block
                const jsonMatch = cellTypesResponse.match(/```json\s*([\s\S]*?)\s*```/);

                if (jsonMatch) {
                    parsedResponse = JSON.parse(jsonMatch[1].trim());
                } else {
                    // Attempt to parse the response directly
                    parsedResponse = JSON.parse(cellTypesResponse);
                }

                // Check if the parsed response has the expected structure
                if (parsedResponse.cell_types) {
                    setCellTypes((prev) => ({ ...prev, [selectedAnnotation.uuid]: parsedResponse.cell_types }));
                } else {
                    throw new Error('Unexpected response format');
                }
            } catch (error) {
                setCellTypes((prev) => ({ ...prev, [selectedAnnotation.uuid]: 'Error parsing response' }));
            } finally {
                setLoadingAIAnnotations((prev) => ({ ...prev, [selectedAnnotation.uuid]: false })); // Clear loading state for the current cluster
                setProcessingCluster(null); // Clear the processing cluster
            }
        }
    };

    useEffect(() => {
        if (
            features?.experiment_features?.ai_assistant_enabled &&
            expanded &&
            plotData &&
            selectedAnnotation &&
            !cellTypes[selectedAnnotation?.uuid]
        ) {
            getAiResponse();
        }
    }, [features?.experiment_features?.ai_assistant_enabled, expanded, plotData, selectedAnnotation, cellTypes]);

    useDebounce(
        () => {
            if (formRef.current?.values && selectedAnnotation) {
                const updatedAnnotation = formRef.current?.values?.annotations.find(
                    (anno) => anno.uuid === selectedAnnotation?.uuid,
                );
                if (updatedAnnotation) {
                    setSelectedAnnotation((prev: Annotation) => ({
                        ...prev,
                        display_name: updatedAnnotation.display_name as string,
                    }));
                }
            }
        },
        500,
        [formRef.current?.values?.annotations],
    );

    const handleAccordionOpen = (annotation: Annotation) => async (_: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? annotation.uuid : null);
        setTimeout(() => {
            setSelectedAnnotation(annotation);
        }, 300);
    };

    /**
     * Get initial values for the annotation form
     * @type {{initialValues: AnnotationFormValues, schema: Yup.AnySchema} | null}
     */
    const setup = getFormSetup({ annotationSet });
    if (!setup) {
        logger.warn(`Can not generate a setup object for annotation set "${annotationSet?.display_name}"`, {
            annotationSet,
        });
        return <ScrollableSidebarContent>This annotation set is not yet supported.</ScrollableSidebarContent>;
    }

    /**
     * Save the form values to the experiment annotation.
     * @param {AnnotationFormValues} values
     * @param {FormikHelpers<FormValues>} helpers
     * @return {Promise<void>}
     */
    const handleSubmit = async (
        values: AnnotationFormValues,
        helpers: FormikHelpers<AnnotationFormValues>,
    ): Promise<void> => {
        logger.debug('Submitting annotation set values', values, annotationSet?.display_name);
        helpers.setStatus(null);
        try {
            const payload: AnnotationFormValues = values;
            const updatedAnnotationSet = await updateAnnotationSet(payload);

            if (updatedAnnotationSet && !updateAnnotationSetLoading && !updateAnnotationSetError) {
                setShowSuccessMessage(true);
                if (selectedAnnotation) {
                    const updatedSelectedAnnotation = updatedAnnotationSet.clusters.find(
                        (cluster) => cluster.uuid === selectedAnnotation.uuid,
                    );
                    setSelectedAnnotation(updatedSelectedAnnotation as Annotation);
                }
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);
            }
        } catch (error) {
            logger.error(error);
            logger.error('ApiErrorMessage', ApiError.getMessage(error));
            helpers.setStatus({ error: ApiError.getMessage(error) });
        } finally {
            helpers.setSubmitting(false);
            setCurrentChanges(null);
        }
    };

    const handleOnChange = useCallback(
        (values) => {
            const isEqual = deepEqual(values, setup.initialValues);
            if (!isEqual) {
                const changedValues = getChangedValues(values, setup.initialValues);
                if (changedValues && !deepEqual(changedValues, currentChanges)) {
                    setCurrentChanges(changedValues);
                }
            } else if (currentChanges) {
                setTimeout(() => {
                    setCurrentChanges(null);
                }, 300);
            }
        },
        [currentChanges, setup.initialValues],
    );

    if (!annotationSet) {
        return (
            <div>
                <LoadingMessage message="Loading..." />
            </div>
        );
    }

    const submitDisabled = updateAnnotationSetLoading;
    const aiEnabled = !!features?.experiment_features.ai_assistant_enabled;

    return (
        <>
            <Formik
                initialValues={setup.initialValues as FormikValues}
                validationSchema={setup.schema}
                onSubmit={handleSubmit}
                enableReinitialize
                key={annotationSet?.uuid}
                innerRef={formRef}
            >
                {({ status, errors, touched, values }) => {
                    const errorMessages = Object.keys(errors)
                        .filter((name) => touched[name] && !isBlank(errors[name]))
                        .map((name) => errors[name]);

                    return (
                        <>
                            <div className="px-8">
                                <p className="mb-4 mt-8">
                                    <span className="mr-1 font-semibold">Annotation set</span>{' '}
                                    {annotationSet?.display_name}
                                </p>
                                <div>
                                    <h4 className="flex flex-row text-lg tracking-tight text-default">
                                        <span className="mr-1 font-semibold text-dark">Clusters</span> (
                                        {annotationSet?.clusters?.length ?? 0})
                                    </h4>
                                    <p className="text-gray-400">
                                        Click on a cluster to edit its display characteristics and view marker genes.{' '}
                                        <a href="" target="_blank" rel="noreferrer">
                                            Learn more
                                        </a>
                                    </p>
                                </div>
                                <div className="mb-2 mt-4 flex justify-end">
                                    <Button
                                        color="primary"
                                        variant="text"
                                        onClick={() => {
                                            setExpanded(null);
                                            setTimeout(() => {
                                                setSelectedAnnotation(null);
                                            }, 300);
                                        }}
                                    >
                                        View All Clusters
                                    </Button>
                                </div>
                            </div>
                            <ScrollableSidebarContent data-cy="annotationSet-form" className="px-8">
                                <FieldArray name="annotations">
                                    {({ replace }) => (
                                        <>
                                            {values.annotations.map((annotation, index) => {
                                                const colorItems: CustomLegendColorItem[] = [
                                                    {
                                                        id: annotation.display_name ?? '',
                                                        label: 'Color',
                                                        themeColor: annotation.color ?? '',
                                                    },
                                                ];
                                                return (
                                                    <div className="mb-4" key={annotation.uuid}>
                                                        <Accordion
                                                            sx={styles.accordion}
                                                            expanded={expanded === annotation.uuid}
                                                            onChange={handleAccordionOpen(annotation)}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={null}
                                                                sx={styles.accordionSummary}
                                                            >
                                                                <div className="flex flex-1 items-center justify-between">
                                                                    <span className="text-md font-semibold tracking-tight">
                                                                        {annotation.display_name ||
                                                                            `Cluster ${annotation.number}`}
                                                                    </span>
                                                                    <LegendSVG
                                                                        radius={4}
                                                                        width={24}
                                                                        style={{
                                                                            fill: annotation.color,
                                                                            fillOpacity: 1,
                                                                            stroke: isWhite(annotation.color)
                                                                                ? 'rgb(209, 213, 219)'
                                                                                : annotation.color,
                                                                            strokeOpacity: 1,
                                                                        }}
                                                                        className={cn(
                                                                            'shrink-0 transition-all duration-500',
                                                                        )}
                                                                    />
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={styles.accordionDetails}>
                                                                <div className="flex flex-1 flex-col">
                                                                    <TextInputField
                                                                        label="Label & color"
                                                                        subLabel={`(Cluster ${annotation.number})`}
                                                                        name="display_name"
                                                                        value={annotation.display_name}
                                                                        onChange={(
                                                                            e: ChangeEvent<HTMLInputElement>,
                                                                        ) => {
                                                                            replace(index, {
                                                                                ...annotation,
                                                                                display_name: e.target.value,
                                                                            });
                                                                        }}
                                                                        componentRight={
                                                                            <div className="ml-3 mr-1">
                                                                                <CustomLegendColorField
                                                                                    hideLabel
                                                                                    items={colorItems}
                                                                                    bottomOffsetClassname="-top-24 mb-8"
                                                                                    leftOffsetClassName="-translate-x-60"
                                                                                    onChange={(color: string) => {
                                                                                        replace(index, {
                                                                                            ...annotation,
                                                                                            color,
                                                                                        });
                                                                                        setSelectedAnnotation({
                                                                                            ...annotation,
                                                                                            color,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    />
                                                                    <TextAreaField
                                                                        name="description"
                                                                        label="Description"
                                                                        subLabel="(optional)"
                                                                        maxLength={DESCRIPTION_CHARACTER_LIMIT}
                                                                        minRows={3}
                                                                        maxRows={6}
                                                                        placeholder="Add some notes about your rationale for the label you chose for this cluster..."
                                                                        value={annotation.description}
                                                                        inputClassName="!text-sm"
                                                                        className="no-margin"
                                                                        onChange={(
                                                                            e: ChangeEvent<HTMLTextAreaElement>,
                                                                        ) => {
                                                                            replace(index, {
                                                                                ...annotation,
                                                                                description: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {aiEnabled ? (
                                                                        <div>
                                                                            {loadingAIAnnotations[annotation.uuid] ? (
                                                                                <div className="mt-2">
                                                                                    Loading AI Annotations...
                                                                                </div>
                                                                            ) : (
                                                                                cellTypes[annotation.uuid] && (
                                                                                    <div>
                                                                                        <div className="mb-2 mt-2">
                                                                                            <h5 className="font-semibold">
                                                                                                Possible Cell Types:
                                                                                            </h5>
                                                                                            {typeof cellTypes[
                                                                                                annotation.uuid
                                                                                            ] === 'string' ? (
                                                                                                <pre>
                                                                                                    {
                                                                                                        cellTypes[
                                                                                                            annotation
                                                                                                                .uuid
                                                                                                        ]
                                                                                                    }
                                                                                                </pre>
                                                                                            ) : (
                                                                                                <ul>
                                                                                                    {Object.keys(
                                                                                                        cellTypes[
                                                                                                            annotation
                                                                                                                .uuid
                                                                                                        ],
                                                                                                    ).map(
                                                                                                        (
                                                                                                            key: string,
                                                                                                        ) => (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    key
                                                                                                                }
                                                                                                                className="mt-1"
                                                                                                            >
                                                                                                                {
                                                                                                                    cellTypes[
                                                                                                                        annotation
                                                                                                                            .uuid
                                                                                                                    ][
                                                                                                                        key
                                                                                                                    ]
                                                                                                                        .type
                                                                                                                }
                                                                                                            </li>
                                                                                                        ),
                                                                                                    )}
                                                                                                </ul>
                                                                                            )}
                                                                                        </div>
                                                                                        <button
                                                                                            className="text-left text-xs"
                                                                                            onClick={() =>
                                                                                                openAISidebar(
                                                                                                    annotation.number,
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                processingCluster ===
                                                                                                annotation.uuid
                                                                                            } // Disable button if this cluster is processing
                                                                                        >
                                                                                            Continue AI chat about this
                                                                                            cluster
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </FieldArray>
                            </ScrollableSidebarContent>

                            <ScrollableSidebarFooter className="flex items-center justify-end">
                                {status && status.error && errorMessages.length === 0 && (
                                    <p className="mb-4 break-words rounded-lg bg-error px-2 py-2 text-error">
                                        {status.error}
                                    </p>
                                )}
                                <AggregateFormErrorAlert />

                                <CSSTransition timeout={300} classNames="fade" in={showSuccessMessage} unmountOnExit>
                                    <p className="mr-3 font-semibold text-success">Changes saved!</p>
                                </CSSTransition>
                                <AnalysisFormSubmitButton
                                    text="Save all changes"
                                    submittingText="Saving..."
                                    variant="contained"
                                    disabled={submitDisabled}
                                    fullWidth={false}
                                    onSubmit={() => setConfirmSubmitOpen(true)}
                                />
                            </ScrollableSidebarFooter>
                            <FormikListener values={values} callback={handleOnChange} />
                        </>
                    );
                }}
            </Formik>
            <ConfirmSaveAnnotationDialog
                open={confirmSubmitOpen}
                onConfirm={() => {
                    setConfirmSubmitOpen(false);
                    if (formRef.current) {
                        formRef.current.handleSubmit();
                    }
                }}
                onCancel={() => setConfirmSubmitOpen(false)}
            />
        </>
    );
};

export default AnnotationForm;
