import React from 'react';
import { AssayDataUnits } from '@models/Experiment';
import RangeFieldGroup from '@components/experiments/plotDisplay/groups/RangeFieldGroup';

type Props = { noMargin?: boolean } & Pick<AssayDataUnits, 'units' | 'units_display_name'>;
const ZRangeFieldGroup = ({ ...props }: Props) => {
    return (
        <div className="form-field">
            <RangeFieldGroup
                colorEndName="max_color"
                colorStartName="min_color"
                endColor="#3442ab"
                endLabel="Maximum"
                endName="z_max"
                endPlaceholder="Auto"
                startColor="#ffffff"
                startLabel="Minimum"
                startName="z_min"
                startPlaceholder="Auto"
                startHint={<span>Minimum heatmap intensity and color</span>}
                endHint={<span>Maximum heatmap intensity and color</span>}
                {...props}
            />
        </div>
    );
};

export default ZRangeFieldGroup;
