import Experiment from '@models/Experiment';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import ResumableUploadFileItem from '@components/fileUpload/ResumableUploadFileItem';
import Button from '@components/Button';
import cn from 'classnames';
import { DocumentAddIcon, ExclamationIcon } from '@heroicons/react/outline';
import { getFileIdentifier } from '@util/FileUtil';
import { Flipped, Flipper } from 'react-flip-toolkit';
import useFileHandler from '@hooks/useFileHandler';
import { ProgressEventInfo } from '@services/ApiService';
import { ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';
import BaseSpaceModalButton from '@components/baseSpace/BaseSpaceModalButton';
import { BaseSpaceFile } from '@models/baseSpace/BaseSpaceFile';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import useExperimentCache from '@hooks/useExperimentCache';
import useImportSessions from '@hooks/useImportSessions';
import ImportSessionItem from '@components/fileUpload/ImportSessionItem';
import useAuth from '@hooks/useAuth';
import { isDefined } from '@util/TypeGuards';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import { FileDataType } from '@models/PlutoFile';
import ResumableFilePending from './ResumableFilePending';
import UploadSession from '@models/UploadSession';
import { CheckCircleIcon } from '@heroicons/react/outline';

const logger = Logger.make('ResumableFileUploader');

export const defaultFileTypes: Accept = {
    'application/x-gzip': ['.gz', '.fq.gz', '.fastq.gz'],
    'image/*': ['.gif', '.jpeg', '.jpg', '.png', '.tiff', '.svg', '.webp'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
    'application/pdf': ['.pdf'],
    'text/plain': ['.csv', '.txt'],
    'text/csv': ['.csv'],
    'text/html': ['.html', '.htm'],
    'application/msword': ['.doc', '.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.pptx'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/octet-stream': [
        '.7z',
        '.ai',
        '.bai',
        '.bam',
        '.bed',
        '.bedgraph',
        '.bedGraph',
        '.bigwig',
        '.bigWig',
        '.broadPeak',
        '.bw',
        '.cdf',
        '.cel',
        '.crai',
        '.cram',
        '.dbf',
        '.eps',
        '.fa',
        '.faa',
        '.fai',
        '.fasta',
        '.ffn',
        '.fits',
        '.flagstat',
        '.fna',
        '.frn',
        '.geotiff',
        '.gff',
        '.gpr',
        '.gtf',
        '.gtiff',
        '.h5',
        '.hdf',
        '.hic',
        '.idxstats',
        '.json',
        '.kml',
        '.kmz',
        '.log',
        '.mat',
        '.md',
        '.mol',
        '.mp3',
        '.mp4',
        '.mzXML',
        '.narrowPeak',
        '.nc',
        '.pdb',
        '.pl',
        '.prism',
        '.py',
        '.pzfx',
        '.pzm',
        '.pzt',
        '.R',
        '.rar',
        '.rda',
        '.RData',
        '.rtf',
        '.sam',
        '.sh',
        '.shp',
        '.shx',
        '.sizes',
        '.stats',
        '.tar',
        '.tbi',
        '.vcf',
        '.xml',
        '.zip',
    ],
};

export type TotalProgressInfo = { numFiles: number; totalBytes: number; loadedBytes: number; percentLoaded: number };

type Props = {
    acceptFileTypes?: Accept;
    chooseFileText?: string;
    dataType: FileDataType;
    dynamicHeight?: boolean;
    experiment: Experiment;
    handleUploadProgrammatically?: (onDrop: (accepted: File[], rejected: FileRejection[]) => void) => void;
    hasExistingFiles?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    maxFiles?: number;
    onDoneUploading?: () => void;
    onFilesChanged?: (files: File[] | null) => void;
    onProgress?: (progress: TotalProgressInfo) => void;
    onUploadComplete?: (session) => void;
    showBaseSpace?: boolean;
    showSuccess?: boolean;
    uploadDisabled?: boolean;
    uploadHeader?: string;
    uploadSubheader?: string;
};
const ResumableFileUploader = forwardRef(
    (
        {
            acceptFileTypes = defaultFileTypes,
            chooseFileText = 'Choose file(s)',
            dataType,
            dynamicHeight = false,
            experiment,
            handleUploadProgrammatically,
            hasExistingFiles = false,
            maxFiles = 1000,
            onDoneUploading,
            onFilesChanged,
            onProgress,
            onUploadComplete,
            showBaseSpace = false,
            showSuccess = false,
            uploadDisabled = false,
            uploadHeader = 'Upload files',
            uploadSubheader = 'Please keep this window open while uploading your file(s)',
        }: Props,
        ref,
    ) => {
        const api = useApi();
        const { user } = useAuth();
        const { refreshExperiment } = useExperimentCache(experiment);
        const { files, fileErrors, hasFiles, removeFile, onDrop, clearErrors, setFileErrors } = useFileHandler({
            onFilesChanged,
        });
        const { importSessions } = useImportSessions({ experiment });
        const { features } = useOrganizationPermissions();
        const hasImportSessions = (importSessions ?? []).length > 0;
        const fileProgressMap = useRef<Record<string, ProgressEventInfo>>({});
        const is_staff = user?.is_staff || false;

        const getAcceptableFileTypes = (isStaff: boolean) => {
            const staffFileTypes: Accept = {
                'text/html': ['.html'],
            };

            return isStaff ? { ...acceptFileTypes, ...staffFileTypes } : acceptFileTypes;
        };

        const handleUploadComplete = (file: File, session: UploadSession) => {
            removeFile(file);
            onUploadComplete?.(session);
        };

        const handleFileRemoved = async (file: File) => {
            removeFile(file);
            handleFileProgress(file, null);
            clearErrors();
        };

        const onUploadError = (file: File, error) => {
            setFileErrors((prev) => {
                const newErrors = [
                    {
                        fileName: file.name,
                        node: (
                            <div key={file.name}>
                                <p className="font-semibold">{error.toString()}</p>
                            </div>
                        ) as ReactNode,
                    },
                ];
                if (prev) {
                    newErrors.unshift(...prev);
                }
                const ids = newErrors.map(({ fileName }) => fileName);
                const uniq = newErrors.filter(({ fileName }, index) => !ids.includes(fileName, index + 1));
                return uniq;
            });
        };

        const handleFileProgress = (file: File, progress: ProgressEventInfo | null) => {
            const fileId = getFileIdentifier({ file, experimentId: experiment.uuid });
            if (!progress) {
                delete fileProgressMap.current[fileId];
            } else {
                fileProgressMap.current[fileId] = progress;
            }
            const progressItems = Object.values(fileProgressMap.current);
            const totalProgress = progressItems.reduce<TotalProgressInfo>(
                (total, progress) => {
                    const updated = {
                        ...total,
                        totalBytes: total.totalBytes + progress.total,
                        loadedBytes: total.loadedBytes + progress.loaded,
                    };
                    updated.percentLoaded = updated.loadedBytes / Math.max(updated.totalBytes, 1);
                    return updated;
                },
                {
                    loadedBytes: 0,
                    percentLoaded: 0,
                    totalBytes: 0,
                    numFiles: files?.length ?? 0,
                },
            );
            onProgress?.(totalProgress);
        };

        const handleBaseSpaceFiles = async (files: BaseSpaceFile[]) => {
            if (!files.length) {
                logger.info('no files were selected from basespace');
                return;
            }
            logger.info(`${files.length} selected from basespace!`, files);
            const endpoint = Endpoints.lab.experiment.basespace.import({ experimentId: experiment.uuid });
            await api.post(endpoint, {
                file_ids: files.map((f) => f.uuid),
            });

            await refreshExperiment();
        };

        const fileValidator = (file: File) => {
            if (dataType !== 'fastq' || 'analysis_input') return null;
            if (/\s/g.test(file.name)) {
                return {
                    code: 'name-has-spaces',
                    message: `File name cannot include spaces`,
                };
            }

            return null;
        };

        const renderFileItem = (file: File, index: number) => (
            <div>
                {index === 0 ? (
                    <ResumableUploadFileItem
                        file={file}
                        onDelete={() => handleFileRemoved(file)}
                        experiment={experiment}
                        data_type={dataType}
                        onProgress={(progress) => handleFileProgress(file, progress)}
                        onUploadComplete={(session: UploadSession) => handleUploadComplete(file, session)}
                        onUploadError={onUploadError}
                    />
                ) : (
                    <ResumableFilePending
                        file={file}
                        onDelete={() => handleFileRemoved(file)}
                        experiment={experiment}
                        data_type={dataType}
                    />
                )}
            </div>
        );

        const dropzone = useDropzone({
            onDrop,
            noClick: true,
            useFsAccessApi: false,
            accept: getAcceptableFileTypes(is_staff),
            validator: fileValidator,
            maxFiles,
            disabled: showSuccess,
        });

        const isDragActive = dropzone.isDragActive;

        useImperativeHandle(ref, () => () => {
            handleUploadProgrammatically?.(onDrop);
        });

        return (
            <div
                {...dropzone.getRootProps()}
                className={cn(
                    'max-h-screen-75 w-full cursor-default overflow-auto rounded-lg border-2 border-indigo-600 p-4',
                    {
                        'border-solid bg-yellow-50': isDragActive,
                        'border-dashed': !isDragActive,
                        'border-dashed !border-gray-200': showSuccess,
                    },
                )}
            >
                <input {...dropzone.getInputProps()} />
                <div className="space-y-4">
                    <div
                        className={cn('flex items-center justify-center', {
                            'min-h-[200px]': !dynamicHeight,
                        })}
                    >
                        {!isDragActive && (
                            <div className="space-y-4 text-center">
                                <div>
                                    {showSuccess && (
                                        <div className="m-auto inline-block rounded-full bg-success/75 p-2">
                                            <CheckCircleIcon width={24} className="text-success" />
                                        </div>
                                    )}
                                    <p className="text-lg font-semibold text-dark">
                                        {isDragActive ? 'Drop files here' : uploadHeader}
                                    </p>
                                    <p className={cn({ invisible: isDragActive })}>
                                        {experiment.fastqs_queued
                                            ? 'Your files are being imported in the background. It is safe to leave this\xa0page.'
                                            : uploadSubheader}
                                    </p>
                                </div>
                                {!showSuccess && (
                                    <div className="flex flex-col items-center">
                                        <div className="flex flex-col items-center space-y-2">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                disabled={uploadDisabled}
                                                onClick={() => {
                                                    dropzone.open();
                                                }}
                                            >
                                                {chooseFileText}
                                            </Button>
                                            {showBaseSpace &&
                                            user?.organization?.basespace_access_token &&
                                            !!features?.experiment_features.basespace_enabled ? (
                                                <BaseSpaceModalButton
                                                    fullWidth
                                                    onSelected={(files) => {
                                                        handleBaseSpaceFiles(files);
                                                    }}
                                                    experiment={experiment}
                                                    disabled={uploadDisabled}
                                                />
                                            ) : null}
                                            {hasFiles ||
                                                (hasExistingFiles && (
                                                    <Button
                                                        onClick={() => onDoneUploading?.()}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        I&apos;m done uploading files
                                                    </Button>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {isDragActive && (
                            <div>
                                <div className="flex justify-center">
                                    <DocumentAddIcon width={32} className="text-dark" />
                                </div>
                                <p className="text-lg font-semibold text-dark">Drop files here</p>
                            </div>
                        )}
                    </div>

                    {fileErrors && (
                        <div>
                            <div className="flex justify-end">
                                <Button onClick={() => clearErrors()} color="primary">
                                    Clear errors
                                </Button>
                            </div>
                            <div className="flex flex-col space-y-4">
                                {fileErrors.map(({ node }, i) => (
                                    <div
                                        key={i}
                                        className="flex space-x-2 rounded-lg border-error bg-error p-4 text-error"
                                    >
                                        <div className="pt-1">
                                            <ExclamationIcon width={18} />
                                        </div>
                                        {node}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {(hasFiles || hasExistingFiles || hasImportSessions) && (
                        <Flipper
                            flipKey={(files ?? [])
                                .map((f) => getFileIdentifier({ file: f, experimentId: experiment.uuid }))
                                .concat(importSessions?.map((s) => s.uuid) ?? [])
                                .join('')}
                        >
                            <p className="mb-2 font-semibold tracking-tight">{files?.length ?? 0} files remaining</p>
                            <div className="flex max-h-[25vh] flex-col space-y-2 overflow-auto">
                                {files?.map((file: File, index) => (
                                    <Flipped
                                        key={getFileIdentifier({ file, experimentId: experiment.uuid })}
                                        flipId={getFileIdentifier({ file, experimentId: experiment.uuid })}
                                    >
                                        {renderFileItem(file, index)}
                                    </Flipped>
                                ))}
                                {importSessions
                                    ?.filter((session) => isDefined(session.basespace_file))
                                    .map((session) => (
                                        <Flipped key={session.uuid} flipId={session.uuid}>
                                            <div>
                                                <ImportSessionItem session={session} />
                                            </div>
                                        </Flipped>
                                    ))}
                            </div>
                        </Flipper>
                    )}
                </div>
            </div>
        );
    },
);

export default ResumableFileUploader;
