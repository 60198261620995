import Experiment, {
    DESCRIPTION_CHARACTER_LIMIT,
    EditExperimentParams,
    LINK_NAME_CHARACTER_LIMIT,
} from '@models/Experiment';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import TextInputField from '@components/forms/TextInputField';
import TextAreaField from '@components/forms/TextAreaField';
import { blankToNull, isBlank, isNotBlank } from '@util/StringUtil';
import Button from '@components/Button';
import ProjectSelect from '@components/dashboard/ProjectSelect';
import { Project } from '@models/Project';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { DialogActions, DialogContent, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import useLabPermissions from '@hooks/useLabPermissions';
import EditExperimentReadOnlyFields from '@components/experiments/EditExperimentReadOnlyFields';
import Logger from '@util/Logger';
import useMatchMutate from '@hooks/useMatchMutate';
import ResumableFileUploader from '@components/fileUpload/ResumableFileUploader';
import { TrashIcon } from '@heroicons/react/outline';
import useAuth from '@hooks/useAuth';
import { RoleLevel } from '@models/Permission';

const logger = Logger.make('EditExperimentForm');
const schema: Yup.SchemaOf<EditExperimentParams> = Yup.object({
    description: Yup.string().nullable().max(DESCRIPTION_CHARACTER_LIMIT, 'Character limit reached'),
    description_block: Yup.string().nullable().max(DESCRIPTION_CHARACTER_LIMIT, 'Character limit reached'),
    project_id: Yup.string().required('Please choose a project'),
    name: Yup.string().required('Please enter an experiment name'),
    external_url: Yup.string().url('Please enter a valid url. Be sure to include http(s)://').nullable(),
    report_url: Yup.string().nullable(),
    uploaded_insights_report_id: Yup.string().nullable(),
    external_url_display_name: Yup.string()
        .nullable()
        .when('external_url', {
            is: (url) => !isBlank(url),
            then: Yup.string()
                .required('Display text is required when adding a link')
                .nullable()
                .max(LINK_NAME_CHARACTER_LIMIT, 'Character limit reached'),
            otherwise: Yup.string().nullable(),
        }),
});

type Props = {
    experiment: Experiment;
    actions?: ReactNode;
    submitText?: string;
    onSaved?: (experiment: Experiment) => void;
};
const EditExperimentDialogForm = ({ experiment, actions, submitText = 'Save', onSaved }: Props) => {
    const { put } = useApi();
    const { user } = useAuth();
    const { canCreateProject } = useLabPermissions();
    const mutations = useMatchMutate();
    const [showUploader, setShowUploader] = useState(true);
    const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);
    const allowInsightsReport = user?.organization_role?.toLowerCase() === RoleLevel.editor ?? false;

    const revalidateLabExperiments = async (experiment: Experiment) => {
        await mutations.pathEqualsIgnoreQueryMutate(Endpoints.lab.experiments());
        if (experiment?.uuid) {
            await mutations.pathEqualsIgnoreQueryMutate(Endpoints.lab.experiment.base(experiment.uuid));
        }
    };

    const handleSubmit = async (values: EditExperimentParams, actions: FormikHelpers<EditExperimentParams>) => {
        try {
            const processedValues: EditExperimentParams = {
                description: blankToNull(values.description),
                description_block: blankToNull(values.description_block),
                external_url: blankToNull(values.external_url),
                external_url_display_name: blankToNull(values.external_url_display_name),
                name: values.name,
                project_id: blankToNull(values.project_id),
                report_url: blankToNull(values.report_url),
                uploaded_insights_report_id: uploadedFileId,
            };

            const url = Endpoints.lab.experiment.base(experiment.uuid);
            const updated = await put<Experiment>(url, {
                ...processedValues,
                organism: experiment.organism.shortname,
                type: experiment.type.shortname,
                target_type: experiment.target_type?.shortname,
            });
            await revalidateLabExperiments(experiment);
            actions.setSubmitting(false);
            onSaved?.(updated);
        } catch (error) {
            logger.error(error);
            actions.setSubmitting(false);
        }
    };
    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
                description: experiment.description ?? '',
                description_block: experiment.description_block ?? '',
                external_url_display_name: experiment.external_url_display_name ?? '',
                external_url: experiment.external_url ?? '',
                project_id: experiment.project?.uuid,
                name: experiment.name,
                report_url: experiment.report_url ?? '',
            }}
        >
            {({ values, errors, touched, setFieldValue, isSubmitting, handleChange, submitForm }) => (
                <>
                    <DialogContent>
                        <Form>
                            <ProjectSelect
                                name="project_id"
                                label="Project"
                                value={values.project_id ?? ''}
                                error={errors.project_id && touched.project_id && errors.project_id}
                                disablePermissionDenied={true}
                                hidePermissionDenied={true}
                                showAddProject={canCreateProject}
                                onChange={(projectId) => setFieldValue('project_id', projectId)}
                                onProjectCreated={(project: Project) => {
                                    setFieldValue('project_id', project.uuid);
                                }}
                            />
                            <TextInputField name="name" label="Name" />
                            <TextAreaField
                                name="description"
                                label="Summary"
                                maxLength={DESCRIPTION_CHARACTER_LIMIT}
                                maxRows={6}
                            />
                            {allowInsightsReport && (
                                <div className="form-field">
                                    <div className="field-label">Insights report</div>
                                    <div>
                                        {showUploader ? (
                                            <ResumableFileUploader
                                                uploadHeader="Upload insights report here"
                                                uploadSubheader="Upload an HTML or PDF file to show an insights report for this experiment."
                                                experiment={experiment}
                                                onUploadComplete={(uploadedFileData) => {
                                                    const fileName = uploadedFileData?.file.filename;
                                                    if (fileName) {
                                                        setFieldValue('report_url', fileName);
                                                        setUploadedFileId(uploadedFileData.file.uuid);
                                                        setShowUploader(false);
                                                    }
                                                }}
                                                onProgress={(progress) => {
                                                    if (!progress || progress.percentLoaded === 1) {
                                                        document.title = 'Upload Complete';
                                                        return;
                                                    }

                                                    const percent = (progress.percentLoaded * 100).toFixed(2);
                                                    document.title = `[${percent}%] Uploading File`;
                                                }}
                                                dataType="insights_report"
                                                acceptFileTypes={{
                                                    'text/html': ['.html'],
                                                    'application/pdf': ['.pdf'],
                                                }}
                                                maxFiles={1}
                                            />
                                        ) : (
                                            <div>
                                                <p>Insights report uploaded successfully.</p>
                                                <Button
                                                    onClick={() => {
                                                        setFieldValue('report_url', null);
                                                        setUploadedFileId(null);
                                                        setShowUploader(true);
                                                    }}
                                                    className="mt-2"
                                                    startIcon={<TrashIcon className="h-5 w-5" />}
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="small"
                                                >
                                                    Remove file
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <TextInputField
                                name="external_url"
                                placeholder="https://dropbox.com/home/My_Experiment"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    const updatedValue = e.target.value;
                                    if (isBlank(updatedValue)) {
                                        setFieldValue('external_url_display_name', '');
                                    }
                                }}
                                label={
                                    <>
                                        Add link
                                        <Tooltip
                                            title="Add a link to external data source, published paper, etc."
                                            placement="top"
                                            arrow
                                        >
                                            <InfoOutlined
                                                fontSize="small"
                                                className="-mt-1 ml-1 cursor-pointer text-gray-400"
                                            />
                                        </Tooltip>
                                    </>
                                }
                            />
                            {isNotBlank(values.external_url) && (
                                <TextInputField
                                    name="external_url_display_name"
                                    label="Link display text"
                                    placeholder="View experiment on Dropbox"
                                />
                            )}
                            <EditExperimentReadOnlyFields experiment={experiment} className="pb-8" />
                        </Form>
                    </DialogContent>

                    <DialogActions>
                        <div className="flex w-full justify-between bg-white">
                            {actions}
                            <Button
                                type={'submit'}
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={() => submitForm()}
                            >
                                {isSubmitting ? 'Saving...' : submitText}
                            </Button>
                        </div>
                    </DialogActions>
                </>
            )}
        </Formik>
    );
};

export default EditExperimentDialogForm;
