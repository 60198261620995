import { useState, useEffect } from 'react';
import useApi from '@hooks/useApi';
import useAuth from '@hooks/useAuth';
import { Thread, PollResponse, DescriptionThread, ThreadObjectType } from '@models/Thread';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';

type Props = {
    object_type: ThreadObjectType | 'biomarker';
    object_uuid: string;
};
const usePlutoAI = ({ object_type, object_uuid }: Props) => {
    const { authReady, user } = useAuth();
    const [stepLength, setStepLength] = useState<number>(0);
    const [description, setDescription] = useState<string | null>(null);
    const [threads, setThreads] = useState<Thread[]>([]);
    const [threadAwaitingResponse, setThreadAwaitingResponse] = useState<boolean>(false);
    const [plutoAIError, setPlutoAIError] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<string>('');
    const [threadsLoading, setThreadsLoading] = useState<boolean>(false);
    const [descriptionLoading, setDescriptionLoading] = useState<boolean>(false);
    const [clusterNumber, setClusterNumber] = useState<number>();
    const api = useApi();
    const logger = Logger.make('PlutoAI');

    useEffect(() => {
        if (!!descriptionError) {
            setTimeout(() => {
                setDescriptionError('');
            }, 5000);
        }
    }, [descriptionError]);

    useEffect(() => {
        if (!clusterNumber) return;

        postNewThread({
            content: `Help me identify potential cell types for cluster ${clusterNumber}?`,
        });
    }, [clusterNumber]);

    useEffect(() => {
        if (!authReady || object_type === 'biomarker') return;

        fetchThreads();
    }, [object_uuid]);

    useEffect(() => {
        if (!stepLength) return;

        fetchThreads();
    }, [stepLength]);

    const generateExperimentDescription = async () => {
        setDescriptionLoading(true);
        if (object_type !== 'experiment')
            return setDescriptionError('PlutoAI expected object_type to be experiment, got: ' + object_type);
        setDescriptionError('');
        try {
            const newDescriptionPoll = await api.post<DescriptionThread>(Endpoints.lab.expDescription(), {
                object_type,
                object_uuid,
            });
            pollAIDescription(newDescriptionPoll.thread_uuid, newDescriptionPoll.uuid);
        } catch {
            setDescriptionError('Failed to generate new experiment description');
            setDescriptionLoading(false);
        }
    };

    const pollAIDescription = async (threadId: string, pollId: string) => {
        try {
            // Ask for ai response
            const pollResponse = await api.get<PollResponse>(Endpoints.lab.threadRuns(threadId, pollId));
            // If response is still queued, wait two seconds and query again
            if (pollResponse.status === 'queued' || pollResponse.status === 'in_progress')
                return setTimeout(() => pollAIDescription(threadId, pollId), 5000);
            if (pollResponse?.status === 'failed') {
                setDescriptionLoading(false);
                setThreadAwaitingResponse(false);
                return setDescriptionError('Failed to communicate with PlutoAI');
            }
            await fetchThreads();
            if (!!threads.length && !!threads[0].messages.length) {
                setDescription(threads[0].messages[0].content);
                setDescriptionError('');
                setThreadAwaitingResponse(false);
                setDescriptionLoading(false);
                return;
            }
            setThreadAwaitingResponse(false);
            setDescriptionLoading(false);
            setDescriptionError('Failed to generate new experiment description');
        } catch {
            setThreadAwaitingResponse(false);
            setDescriptionLoading(false);
            setDescriptionError('Failed to generate new experiment description');
        }
    };

    const pollPlutoAI = async (threadId: string, runId: string) => {
        try {
            // Ask for ai response
            const pollResponse = await api.get<PollResponse>(Endpoints.lab.threadRuns(threadId, runId));
            // If response is still queued, wait two seconds and query again
            if (pollResponse.status === 'queued' || pollResponse.status === 'in_progress') {
                const pollStep = await api.get<PollResponse[]>(Endpoints.lab.threadSteps(threadId, runId));
                setStepLength(pollStep?.length ?? 0);
                return setTimeout(() => pollPlutoAI(threadId, runId), 5000);
            }
            if (pollResponse?.status === 'failed') return setPlutoAIError('Failed to communicate with PlutoAI');
            await api.get<PollResponse[]>(Endpoints.lab.threadSteps(threadId, runId));
            await fetchThreads();
            setPlutoAIError('');
            setThreadAwaitingResponse(false);
        } catch (error) {
            throw new Error(error);
        }
    };

    const fetchThreads = async () => {
        setThreadsLoading(true);
        try {
            const newThreads = await api.get<Thread[]>(Endpoints.lab.threads(), {
                object_type,
                object_uuid,
            });
            // Filter out archived threads, sort by most recent descending
            const sortedthreads = newThreads
                .filter((thread: Thread) => !thread.is_archived)
                .sort((a: Thread, b: Thread) => Date.parse(b.created_at) - Date.parse(a.created_at));
            setThreads(sortedthreads);
            setPlutoAIError('');
        } catch (error) {
            logger.error(error);
            setPlutoAIError('Failed to fetch conversations with PlutoAI');
        } finally {
            setThreadsLoading(false);
        }
    };

    const fetchAIResponse = async (thread_uuid: string) => {
        setThreadAwaitingResponse(true);
        setStepLength(0);
        try {
            // Create new run while awaiting AI response
            const newResponsePoll = await api.post<PollResponse>(Endpoints.lab.threadRuns(thread_uuid));
            // Start polling that response
            pollPlutoAI(thread_uuid, newResponsePoll.uuid);
        } catch (error) {
            logger.error(error);
            setPlutoAIError('Failed to communicate with PlutoAI');
        }
    };

    const postNewThread = async ({ content }: { content: string }) => {
        if (!user || !user.uuid) return;

        setThreadsLoading(true);
        const newThreadTitle = content.length < 31 ? content : content.substring(0, 30) + '...';
        try {
            const newThread = await api.post<Thread>(Endpoints.lab.threads(), {
                title: newThreadTitle,
                object_type,
                object_uuid,
            });
            postNewChatToThread({ thread: newThread, content });
            setPlutoAIError('');
            return newThread;
        } catch (error) {
            logger.error(error);
            setPlutoAIError('Failed to start new conversation with PlutoAI');
        } finally {
            setThreadsLoading(false);
        }
    };

    const postNewChatToThread = async ({ thread, content }: { thread: Thread; content: string }) => {
        try {
            await api.post<Thread>(Endpoints.lab.threadQuestions(thread.uuid), {
                content,
                is_assistant: false,
            });
            await fetchThreads();
            fetchAIResponse(thread.uuid);
        } catch (error) {
            return error;
        }
    };

    const updateThread = async ({ thread_uuid, title }: { thread_uuid: string; title: string }) => {
        setThreadsLoading(true);
        try {
            await api.put<Thread>(Endpoints.lab.thread(thread_uuid), { title });
            await fetchThreads();
            setPlutoAIError('');
        } catch (error) {
            logger.error(error);
            setPlutoAIError('Failed to update conversation with PlutoAI');
        } finally {
            setThreadsLoading(false);
        }
    };

    const deleteThread = async ({ thread_uuid }: { thread_uuid: string }) => {
        setThreadsLoading(true);
        try {
            await api.doDelete<Thread>(Endpoints.lab.thread(thread_uuid));
            await fetchThreads();
            setPlutoAIError('');
        } catch (error) {
            logger.error(error);
            setPlutoAIError('Failed to delete conversation with PlutoAI');
        } finally {
            setThreadsLoading(false);
        }
    };

    const clearPlutoAIErrors = () => setPlutoAIError('');

    return {
        description,
        descriptionError,
        generateExperimentDescription,
        threads,
        fetchThreads,
        postNewThread,
        postNewChatToThread,
        updateThread,
        threadsLoading,
        threadAwaitingResponse,
        deleteThread,
        plutoAIError,
        clearPlutoAIErrors,
        setClusterNumber,
        descriptionLoading,
    };
};

export default usePlutoAI;
