import Link from 'next/link';
import { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import Experiment from '@models/Experiment';
import { hasPermission } from '@util/PermissionUtil';
import { PermissionName } from '@models/Permission';
import SharingDropdownButtonV2 from '@components/SharingDropdownButtonV2';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import Endpoints from '@services/Endpoints';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { PaginationResponse } from '@services/EndpointUtil';
import { SharingMember } from '@models/User';
import { Project } from '@models/Project';
import ProjectExperimentsDropdownMenu from '@components/projects/ProjectExperimentsDropdownMenu';
import ExperimentActionsDropdownMenu from '@components/experiments/ExperimentActionsDropdownMenu';
import { Tooltip } from '@mui/material';
import { MapIcon } from '@heroicons/react/outline';
import { ViewGridIcon, ChartBarIcon, DatabaseIcon, UserGroupIcon, CogIcon } from '@heroicons/react/outline';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import CommentsIcon from './icons/custom/CommentsIcon';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';

const ICON_SIZE_CLASSES = 'h-4 w-4';

type NavItemProps = PropsWithChildren<{
    className?: string;
    href: string;
    name: string;
    disabled?: boolean;
    isBeta?: boolean;
}>;
const NavItem = ({
    href,
    className,
    icon,
    name,
    disabled = false,
    isBeta = false,
}: NavItemProps & { icon: ReactNode }) => {
    const router = useRouter();
    const currentPathStripped = router.asPath.split('?organization')[0];
    const isCurrentPath =
        currentPathStripped === href || (href.includes('analysis') && router.asPath.includes('analysis')) || false;
    return (
        <Link href={href} passHref legacyBehavior>
            <div
                data-cy={`nav-item-${name.toLowerCase()}`}
                data-active={isCurrentPath ? 'yes' : undefined}
                aria-disabled={disabled}
                className={cn(
                    className,
                    'relative flex h-full cursor-pointer items-center justify-center px-3 font-semibold no-underline transition-opacity hover:text-white hover:no-underline',
                    {
                        'text-white/70': !isCurrentPath,
                        'text-white': isCurrentPath,
                    },
                    {
                        'pointer-events-none text-white/40': disabled,
                    },
                )}
            >
                {isBeta ? (
                    <div className="pluto-gradient2 absolute right-[5px] top-[9px] h-[9px] w-[9px] rounded-full" />
                ) : null}
                <span className="hidden lg:block">{name}</span>
                <Tooltip arrow title={name} placement="bottom">
                    <span className="block lg:hidden">{icon}</span>
                </Tooltip>
            </div>
        </Link>
    );
};

export const ExperimentNavigationSpacer = ({
    children,
    horizontal,
    vertical,
    className,
}: {
    children?: ReactNode;
    horizontal?: boolean;
    vertical?: boolean;
    className?: string;
}) => {
    return (
        <div
            className={cn(className, {
                'md:ml-20': horizontal,
                'mb-24 md:mb-0': vertical,
            })}
        >
            {children}
        </div>
    );
};

const Divider = () => (
    <div className="mt-[11px] h-[27px] border-r border-seperator max-[375px]:mt-0 max-[375px]:h-full" />
);

const SubPageLinks = ({ experiment }: { experiment?: Experiment }) => {
    const experimentPath = `/experiments/${experiment?.pluto_id?.toUpperCase()}`;
    const { features } = useOrganizationPermissions();
    return (
        <div className="flex flex-row text-xs">
            <NavItem
                name="Overview"
                icon={<ViewGridIcon className={ICON_SIZE_CLASSES} />}
                href={experimentPath}
                disabled={!experiment}
            ></NavItem>
            <Divider />
            <NavItem
                name="Analysis"
                icon={<ChartBarIcon className={ICON_SIZE_CLASSES} />}
                href={`${experimentPath}/analysis`}
                disabled={!experiment}
            ></NavItem>
            <Divider />
            {features?.experiment_features.canvas_enabled ? (
                <>
                    <NavItem
                        name="Canvas"
                        icon={<MapIcon className={ICON_SIZE_CLASSES} />}
                        href={`${experimentPath}/canvas`}
                        disabled={!experiment}
                    ></NavItem>
                    <Divider />
                </>
            ) : null}
            <NavItem
                name="Downloads"
                icon={<DatabaseIcon className={ICON_SIZE_CLASSES} />}
                href={`${experimentPath}/data`}
                disabled={!experiment}
            ></NavItem>
            <Divider />
            <NavItem
                name="Collaboration"
                icon={<UserGroupIcon className={ICON_SIZE_CLASSES} />}
                href={`${experimentPath}/collaboration`}
                disabled={!experiment}
            ></NavItem>
            <Divider />
            <NavItem
                name="Workflow"
                icon={<CogIcon className={ICON_SIZE_CLASSES} />}
                href={`${experimentPath}/workflow`}
                disabled={!experiment}
            ></NavItem>
        </div>
    );
};

type Props = {
    className?: string;
    experiment: Experiment;
    project: Project;
    projectExperiments: Experiment[];
};
const ExperimentNavigation = ({ experiment, project, projectExperiments }: Props) => {
    const router = useRouter();
    const projectId = project?.uuid;
    const isOnProjectPage = router.pathname.includes('project') ?? false;
    const { updateProjectSharingModal, updateExperimentSharingModal } = useLabSpaceContext();
    const { setExperimentCommentsOpen, experimentCommentsOpen } = useExperimentDetailViewContext();
    const permissions = useExperimentPermissions(experiment);
    const { features } = useOrganizationPermissions();
    const canInvite = hasPermission(experiment, { requires: [PermissionName.invite_experiment_users] });

    const { data: experimentMembersData, error: experimentMembersError } = useSWR<PaginationResponse<SharingMember>>(
        () =>
            !permissions.members.canView || !experiment
                ? null
                : Endpoints.lab.experiment.members({ experimentId: experiment.uuid }),
    );
    const experimentMembersLoading = !experimentMembersData && !experimentMembersError;
    const experimentMembers = experimentMembersData?.items ?? [];
    const totalExperimentMembers = experimentMembersData?.count ?? 0;

    const { data: projectMembersData, error: projectMembersError } = useSWR<PaginationResponse<SharingMember>>(
        projectId && Endpoints.lab.project.members({ projectId }),
    );
    const projectMembersLoading = !projectMembersData && !projectMembersError;
    const projectMembers = projectMembersData?.items ?? [];
    const totalProjectMembers = projectMembersData?.count ?? 0;

    const membersLoading = isOnProjectPage ? projectMembersLoading : experimentMembersLoading;
    const members = isOnProjectPage ? projectMembers : experimentMembers;
    const totalMembers = isOnProjectPage ? totalProjectMembers : totalExperimentMembers;
    const membersError = isOnProjectPage ? projectMembersError : experimentMembersError;

    const handleInviteUser = () => {
        if (isOnProjectPage) {
            updateProjectSharingModal({ item: project, open: true, error: null });
        } else {
            updateExperimentSharingModal({ item: experiment, open: true, error: null });
        }
    };
    return (
        <div id="experiment-nav" className="sticky top-0 z-50 flex h-12 flex-row bg-gray-dark md:pl-[0px]">
            {experiment && (
                <div className="flex w-full flex-grow flex-wrap justify-between" data-cy="experiment-nav">
                    <div className="order-1 flex h-12 flex-grow">
                        <SubPageLinks experiment={experiment} />
                    </div>
                    <div
                        data-name="center-nav"
                        className="hidden flex-grow justify-center border-t border-seperator md:order-2 md:flex md:border-none lg:ml-2"
                    >
                        <ProjectExperimentsDropdownMenu
                            projectExperiments={projectExperiments}
                            experiment={experiment}
                            project={project}
                        />
                        <ExperimentActionsDropdownMenu experiment={experiment} />
                    </div>
                    <div className="order-2 flex flex-grow flex-row items-center justify-end border-seperator pr-2 max-[375px]:justify-start max-[375px]:border-t">
                        {!!features?.experiment_features.comments_enabled ? (
                            <div
                                className="ml-2 mr-4 flex cursor-pointer items-center text-white/70 hover:text-white"
                                onClick={() => setExperimentCommentsOpen(!experimentCommentsOpen)}
                            >
                                <CommentsIcon height={18} width={18} className="mr-2 text-inherit" />
                                <p className="hidden text-xs md:block">Comments</p>
                            </div>
                        ) : null}
                        {canInvite && (
                            <div className="mr-3">
                                <SharingDropdownButtonV2
                                    error={membersError?.message}
                                    loading={membersLoading}
                                    onManage={handleInviteUser}
                                    totalMembers={totalMembers}
                                    users={members}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
export default ExperimentNavigation;
