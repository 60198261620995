import IconButton, { LoadableButtonProps } from '@components/Button';
import { MouseEvent, useState } from 'react';
import { CircularProgress, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { DownloadIcon } from '@heroicons/react/solid';
import useApi from '@hooks/useApi';
import { datadogLogs } from '@datadog/browser-logs';
import useInlineFileDownload from '@hooks/useInlineFileDownload';
import Logger from '@util/Logger';

const logger = Logger.make('DownloadDataButton');
const styles = {
    downloadBtn: {
        border: '1px solid rgb(224, 231, 255)',
        minWidth: 0,
        padding: 12,
    },
    paper: {
        borderRadius: '8px',
        border: '1px solid #CED4FB',
        boxShadow: '0 0 16px 0 rgba(0,0,0,.08)',
    },
    menuItem: {
        color: '#616ECF',
    },
    selected: {},
    hover: {},
};

type DownloadType = 'default' | 'summary';
type Props = {
    endpoint?: (filename: string) => string;
    summaryEndpoint?: (filename: string) => string;
    downloadUrl?: string;
    /**
     * The filename without extension. The extension will be added based on the file format selected
     */
    baseFilename: string;
    formats?: string[];
    summaryFormats?: string[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    Icon?: JSX.Element;
} & LoadableButtonProps;
const DownloadDataButton = ({
    baseFilename,
    downloadUrl,
    endpoint,
    summaryEndpoint,
    formats = ['.csv'],
    summaryFormats = ['.csv'],
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
    Icon,
    ...buttonProps
}: Props) => {
    const { triggerFileDownload } = useInlineFileDownload();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState(false);
    const { fetcher } = useApi();
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const startDownload = async ({ extension, downloadType }: { extension: string; downloadType: DownloadType }) => {
        const summaryDownloadType = downloadType === 'summary';
        const filename = `${summaryDownloadType ? `${baseFilename}_summary` : baseFilename}${extension}`;
        logger.log(`[DownloadButton.tsx] starting download for ${filename}`);
        setLoading(true);
        try {
            if (downloadUrl) {
                logger.log('download url', downloadUrl);
                triggerFileDownload({ url: downloadUrl, filename });
            } else if (summaryDownloadType && summaryEndpoint) {
                const downloadEndpoint = summaryEndpoint(filename);
                logger.log('download summary endpoint', downloadEndpoint);
                const response = await fetcher<{ url: string }>(downloadEndpoint, { method: 'GET' });
                logger.log(response);
                if (response.url) {
                    triggerFileDownload({ url: response.url, filename });
                }
            } else if (endpoint) {
                const downloadEndpoint = endpoint(filename);
                logger.log('download endpoint', downloadEndpoint);
                const response = await fetcher<{ url: string }>(downloadEndpoint, { method: 'GET' });
                logger.log(response);
                if (response.url) {
                    triggerFileDownload({ url: response.url, filename });
                }
            }
        } catch (error) {
            logger.error(error);
            datadogLogs.logger.error('Download data error', {}, error);
        } finally {
            setLoading(false);
        }
    };

    const handleFormatClicked = (extension: string, downloadType: DownloadType) => {
        startDownload({ extension, downloadType });
        handleClose();
    };

    return (
        <div>
            {Icon && loading && (
                <div className="flex h-8 w-8 items-center justify-center">
                    <CircularProgress size={20} />
                </div>
            )}
            {Icon && !loading && (
                <button onClick={handleClick} aria-controls="download-menu">
                    {Icon}
                </button>
            )}
            {!Icon && (
                <IconButton
                    style={{ padding: 12 }}
                    sx={{ ...styles.downloadBtn, '& .MuiIconButton-label': { lineHeight: 1 } }}
                    color="secondary"
                    {...buttonProps}
                    onClick={handleClick}
                    aria-controls="download-menu"
                    loading={loading}
                    size="large"
                >
                    <DownloadIcon color="secondary" className="h-4 w-4" />
                </IconButton>
            )}
            <Menu
                id="download-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                keepMounted
                onClose={handleClose}
                sx={{ paper: styles.paper }}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {formats.map((format) => (
                    <MenuItem
                        onClick={() => handleFormatClicked(format, 'default')}
                        key={format}
                        sx={{ selected: styles.selected, root: styles.menuItem }}
                    >
                        <DownloadIcon color="secondary" className="mr-1 h-4 w-4" />
                        {`Download ${format}`}
                    </MenuItem>
                ))}
                {summaryEndpoint
                    ? summaryFormats.map((format) => (
                          <MenuItem
                              key={`${format}_summary`}
                              onClick={() => handleFormatClicked(format, 'summary')}
                              sx={{ selected: styles.selected, root: styles.menuItem }}
                          >
                              <DownloadIcon color="secondary" className="mr-1 h-4 w-4" />
                              {`Download summary ${format}`}
                          </MenuItem>
                      ))
                    : null}
            </Menu>
        </div>
    );
};

export default DownloadDataButton;
